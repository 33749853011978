import { Dispatch, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import AdminService from "../services/AdminService";
import { RootState } from "../store";

import { IAdminStates } from "../Interfaces/IAdmin";
import { TProfileUpdatedByAdmin } from "../Interfaces/IProfileStates";
import { withAuth } from "../utils/withAuth";
import ProfileService from "../services/ProfileService";
import { UpdatedByAdminPayload } from "../Interfaces/IRegisterStates";

const initialState: IAdminStates = {
  adminError: false,
  adminSuccess: false,
  adminLoading: false,
  adminMessage: "",
  childUsers: [],
  childUser: null,
  isEditingUserRequested: false,
  isEditingUserSucceeded: false,
  isEditingUserFailed: false,
  isEditingUserError: "",
};

export const getChildrenUsers = createAsyncThunk("admin/getChildrenUsers", async (_, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await AdminService.getChildrenUsers(token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const profileChild = createAsyncThunk("admin/profileChild", async (doc: { id: string }, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await AdminService.profileChild(doc, token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const resendConfirmationEmail = createAsyncThunk(
  "admin/resendConfirmationEmail",
  async (doc: { id: string }, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await AdminService.resendConfirmationEmail(doc, token);
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  }
);

export const requestEditUser = (user: UpdatedByAdminPayload | TProfileUpdatedByAdmin) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(editUserRequested());

      const userResponse = await withAuth(ProfileService.userUpdatedByAdmin, user);
      if (userResponse) return dispatch(editUserSucceeded(userResponse));

      return dispatch(editUserFailed());
    } catch (err) {
      return dispatch(editUserFailed());
    }
  };
};

export const requestResetEditUser = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(resetEditUser());
    } catch (err) {
      return dispatch(editUserFailed());
    }
  };
};

export const AdminSlice = createSlice({
  name: "configstation",
  initialState,
  reducers: {
    adminReset: (state) => {
      state.adminError = false;
      state.adminLoading = false;
      state.adminSuccess = false;
    },
    editUserRequested: (state) => {
      state.isEditingUserRequested = true;
      state.isEditingUserSucceeded = false;
      state.isEditingUserFailed = false;
    },
    editUserSucceeded: (state, action) => {
      state.isEditingUserRequested = false;
      state.isEditingUserSucceeded = true;
      state.isEditingUserFailed = false;
    },
    editUserFailed: (state) => {
      state.isEditingUserRequested = false;
      state.isEditingUserSucceeded = false;
      state.isEditingUserFailed = true;
    },
    resetEditUser: (state) => {
      state.isEditingUserRequested = false;
      state.isEditingUserSucceeded = false;
      state.isEditingUserFailed = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChildrenUsers.fulfilled, (state, action) => {
        state.adminError = false;
        state.adminLoading = false;
        action.payload.childUsers ? (state.childUsers = action.payload.childUsers) : (state.childUsers = []);
      })
      .addCase(getChildrenUsers.pending, (state) => {
        state.adminError = false;
        state.adminLoading = true;
      })
      .addCase(getChildrenUsers.rejected, (state, action) => {
        state.adminError = true;
        state.adminLoading = false;
        state.adminSuccess = false;
        state.adminMessage = typeof action.payload === "string" ? action.payload : "";
      })
      .addCase(profileChild.fulfilled, (state, action) => {
        state.adminError = false;
        state.adminLoading = false;
        state.adminSuccess = true;
        action.payload.childUser ? (state.childUser = action.payload.childUser) : (state.childUser = null);
      })
      .addCase(profileChild.pending, (state) => {
        state.adminError = false;
        state.adminLoading = true;
      })
      .addCase(profileChild.rejected, (state, action) => {
        state.adminError = true;
        state.adminLoading = false;
        state.adminSuccess = false;
        state.adminMessage = typeof action.payload === "string" ? action.payload : "";
      })
      .addCase(resendConfirmationEmail.fulfilled, (state, action) => {
        state.adminError = false;
        state.adminLoading = false;
        state.adminSuccess = true;
        state.adminMessage = action.payload.message || "";
      })
      .addCase(resendConfirmationEmail.pending, (state) => {
        state.adminError = false;
        state.adminLoading = true;
      })
      .addCase(resendConfirmationEmail.rejected, (state, action) => {
        state.adminError = true;
        state.adminLoading = false;
        state.adminSuccess = false;
        state.adminMessage = typeof action.payload === "string" ? action.payload : "";
      });
  },
});

export const { adminReset, editUserRequested, editUserSucceeded, editUserFailed, resetEditUser } = AdminSlice.actions;
export const adminSelector = (state: RootState) => state.AdminReducer;
export default AdminSlice.reducer;
