import { useEffect, Fragment, useState, ChangeEvent } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

import { licensingSelector, getUsersMailing, setUserToMailing } from "../../slices/LicensingSlice";

type Props = {
  protocolProcessId: string;
};

type ModalOpenState = {
  [key: string]: boolean;
};

const LicensingUserMailModal = ({ protocolProcessId }: Props) => {
  const dispatch = useAppDispatch();
  const { usersEnabledToMailing, usersToMailing } = useAppSelector(licensingSelector);

  const [modalOpenStates, setModalOpenStates] = useState<ModalOpenState>({});
  useEffect(() => {
    // Inicializa o estado para o protocolProcessId específico
    setModalOpenStates((prev) => ({ ...prev, [protocolProcessId]: false }));
  }, [protocolProcessId]);

  const openModal = () => {
    setModalOpenStates((prev) => ({ ...prev, [protocolProcessId]: true }));
  };

  const closeModal = () => {
    setModalOpenStates((prev) => ({ ...prev, [protocolProcessId]: false }));
  };

  const isOpen: boolean = modalOpenStates[protocolProcessId];

  useEffect(() => {
    dispatch(getUsersMailing(protocolProcessId));
  }, [dispatch, protocolProcessId]);

  const setEmailStatus = (
    e: ChangeEvent<HTMLInputElement>, // Tipo apropriado para o evento
    id: string, // Supondo que id é uma string
    protocolProcessId: string // Supondo que protocolProcessId também é uma string
  ) => {
    e.preventDefault();
    dispatch(
      setUserToMailing({
        userId: id,
        enabledToMailing: e.target.checked,
        protocolProcessId,
      })
    );
  };

  return (
    <>
      <div>
        {usersEnabledToMailing[protocolProcessId] && usersEnabledToMailing[protocolProcessId].length > 0 ? (
          <div>
            {usersEnabledToMailing[protocolProcessId].map((user) => (
              <div key={user._id}>
                <button onClick={() => openModal()}>
                  <label
                    className="underline cursor-pointer"
                    title="habilitar ou desabilitar usuário a receber notificações sobre licenciamento"
                    htmlFor={`habilitar ou desabilitar ${user.name} a receber notificações sobre licenciamento`}
                  >
                    {user.name} ({user.email})
                  </label>
                </button>
              </div>
            ))}
          </div>
        ) : (
          <button onClick={() => openModal()}>
            <label
              className="underline cursor-pointer"
              title="habilitar ou desabilitar usuário a receber notificações sobre licenciamento"
              htmlFor={`habilitar ou desabilitar a receber notificações sobre licenciamento`}
            >
              Selecionar usuários
            </label>
          </button>
        )}
      </div>
      <Transition.Root show={isOpen || false} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-start sm:p-0 mt-32">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:h-auto sm:max-w-3xl  px-4 pb-4 pt-5 sm:p-6">
                  <div className="flex flex-col justify-start">
                    <div className="mt-3 text-sm font-semibold leading-6 text-gray-900 sm:mt-5">
                      Selecione os usuários habilitados para receberem emails sobre o protocolo de licenciamento
                    </div>
                    <div className="mt-3 text-sm font-normal leading-6 text-gray-900 sm:mt-5">
                      {usersToMailing[protocolProcessId] && usersToMailing[protocolProcessId].length > 0 && (
                        <div className="flex-col items-start justify-center">
                          {usersToMailing[protocolProcessId].map((user) => (
                            <div className="flex items-center" key={user._id}>
                              <input
                                type="checkbox"
                                checked={user.enabledToMailing}
                                name={user.name}
                                value={user._id}
                                className="mr-2 customInputCheckbox"
                                id={user.name}
                                onChange={(e) => setEmailStatus(e, user._id, protocolProcessId)}
                              />
                              <div>
                                {user.name} ({user.email})
                              </div>
                              <div className="ml-4">
                                {user.enabledToMailing ? (
                                  <label className="inline text-green-700">habilitado</label>
                                ) : (
                                  <label className="inline text-red-700">dasabilitado</label>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-2 justify-end mt-5 sm:mt-6">
                    <button type="button" className="customButton" onClick={() => closeModal()}>
                      Sair
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default LicensingUserMailModal;
