import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import RegisterService from "../services/RegisterService";
import {
  ErrorResponse,
  IRegisterStates,
  TRegister,
  Greeting,
  RegisterPayload,
  RegisterFinishRegisterPayload,
} from "../Interfaces/IRegisterStates";
import { RootState } from "../store";

const initialState: IRegisterStates = {
  registerUser: null,
  registerError: false,
  registerSuccess: false,
  registerFinishSuccess: false,
  registerLoading: false,
  registerMessage: { errors: [] },
  registerGreeting: null,
  registerFinishId: null,
  registerFinishEmail: null,
};

export const register = createAsyncThunk(
  "register/register",
  async ({ userRegister, roleRegister }: RegisterPayload, thunkAPI) => {
    const res = await RegisterService.register({ userRegister, roleRegister });
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res);
    }
    return res;
  }
);

//finishRegister
export const finishRegister = createAsyncThunk(
  "register/finishRegister",
  async ({ userRegister, roleRegister }: RegisterFinishRegisterPayload, thunkAPI) => {
    const res = await RegisterService.finishRegister({ userRegister, roleRegister });

    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res);
    }
    return res;
  }
);

export const confirmationToken = createAsyncThunk(
  "register/confirmationToken",
  async (tokenConfirm: string, thunkAPI) => {
    const res = await RegisterService.confirmationToken(tokenConfirm);
    //check for errors

    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }

    return res;
  }
);

export const finishRegisterToken = createAsyncThunk(
  "register/finishRegisterToken",
  async (tokenFinish: string, thunkAPI) => {
    const res = await RegisterService.finishRegisterToken(tokenFinish);
    //check for errors

    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }

    return res;
  }
);

//

export const RegisterSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    registerReset: (state) => {
      state.registerError = false;
      state.registerLoading = false;
      state.registerSuccess = false;
      state.registerMessage = { errors: [] };
      state.registerGreeting = null;
      state.registerFinishId = null;
      state.registerFinishEmail = null;
    },
    registerFinishReset: (state) => {
      state.registerError = false;
      state.registerLoading = false;
      state.registerSuccess = false;
      state.registerMessage = { errors: [] };
      state.registerGreeting = null;
      state.registerFinishId = null;
      state.registerFinishEmail = null;
      state.registerFinishSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.registerLoading = true;
        state.registerError = false;
      })
      .addCase(register.fulfilled, (state, action: PayloadAction<TRegister>) => {
        state.registerError = false;
        state.registerLoading = false;
        state.registerSuccess = true;
        state.registerUser = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.registerError = true;
        state.registerLoading = false;
        if (action.payload && typeof action.payload === "object" && "errors" in action.payload) {
          state.registerMessage = action.payload as ErrorResponse;
        } else {
          state.registerMessage = { errors: [] };
        }
      })
      .addCase(confirmationToken.pending, (state) => {
        state.registerLoading = true;
        state.registerError = false;
        state.registerSuccess = false;
      })
      .addCase(confirmationToken.fulfilled, (state, action: PayloadAction<Greeting>) => {
        state.registerError = false;
        state.registerLoading = false;
        state.registerSuccess = true;
        if (action.payload && typeof action.payload === "object") state.registerGreeting = action.payload;
      })
      .addCase(confirmationToken.rejected, (state, action) => {
        state.registerError = true;
        state.registerLoading = false;
        state.registerSuccess = false;
      })
      .addCase(finishRegisterToken.pending, (state) => {
        state.registerLoading = true;
        state.registerError = false;
        state.registerSuccess = false;
      })
      .addCase(finishRegisterToken.fulfilled, (state, action: PayloadAction<{ userId: string; userEmail: string }>) => {
        state.registerError = false;
        state.registerLoading = false;
        state.registerSuccess = true;
        state.registerFinishEmail = action.payload.userEmail;
        state.registerFinishId = action.payload.userId;
      })
      .addCase(finishRegisterToken.rejected, (state, action) => {
        state.registerError = true;
        state.registerLoading = false;
        state.registerSuccess = false;
      })
      .addCase(finishRegister.pending, (state) => {
        state.registerLoading = true;
        state.registerError = false;
        state.registerSuccess = false;
      })
      .addCase(finishRegister.fulfilled, (state, action: PayloadAction<{ userId: string; userEmail: string }>) => {
        state.registerError = false;
        state.registerLoading = false;
        state.registerSuccess = true;
        state.registerFinishSuccess = true;
      })
      .addCase(finishRegister.rejected, (state, action) => {
        state.registerError = true;
        state.registerLoading = false;
        state.registerSuccess = false;
        if (action.payload && typeof action.payload === "object" && "errors" in action.payload) {
          state.registerMessage = action.payload as ErrorResponse;
        } else {
          state.registerMessage = { errors: [] };
        }
      });
  },
});

export const { registerReset, registerFinishReset } = RegisterSlice.actions;
export const registerSelector = (state: RootState) => state.RegisterReducer;
export default RegisterSlice.reducer;
