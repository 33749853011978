import { useState, FormEvent } from "react";
import * as Yup from "yup";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import ConfirmationSend from "../../components/forgot-password/ConfirmationSend";
import { urlapp } from "../../utils/config";
import { forgotPasswordSelector, requestForgotPassword } from "../../slices/ForgotPasswordSlice";
import ImageOffLine from "../../presentational/image-main/ImageMain";

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const { hasForgotPasswordRequested, isForgotPasswordSucceeded } = useAppSelector(forgotPasswordSelector);

  const validateEmail = () => {
    const schema = Yup.object().shape({
      email: Yup.string().email("Por favor, insira um email válido.").required("O email é obrigatório"),
    });

    try {
      schema.validateSync({ email });
      setEmailError("");
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setEmailError(err.message);
      } else {
        setEmailError("Ocorreu um erro ao validar o email.");
      }
      return false;
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateEmail()) {
      return;
    }

    dispatch(requestForgotPassword(email));
  };

  return (
    <>
      {isForgotPasswordSucceeded ? (
        <div className="flex w-full h-full">
          <div className="flex min-h-full flex-1 flex-col justify-center md:justify-start px-6 py-2 md:px-2">
            <img
              className="mx-auto h-16 w-auto"
              src={`${urlapp}/img/logo-antena-top-digital-agi.png`}
              alt="Your Company"
            />
            <ConfirmationSend email={email} />
          </div>
          <div className="hidden py-2 lg:flex min-h-full mt-28 w-1/2 items-start justify-center">
            <img src="/img/img-top-1536x1536.png" alt="Um produto Top Digital" className="h-auto" />
          </div>
        </div>
      ) : (
        <div className="flex w-full">
          <div className="flex min-h-full flex-1 flex-col justify-center md:justify-start px-6 py-2 md:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="mx-auto h-16 w-auto"
                src={`${urlapp}/img/logo-antena-top-digital-agi.png`}
                alt="Your Company"
              />
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-top-digital-content-color">
                Recuperação de senha
              </h2>
              <p className="text-left text-smp mt-4 text-top-digital-content-color">
                Por favor, digite o endereço de e-mail associado à sua conta. Enviaremos um link para você redefinir sua
                senha.
              </p>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email" className="block text-smp font-medium leading-6 top-digital-content-color">
                    E-mail
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      autoComplete="email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={validateEmail}
                      value={email || ""}
                      className="customInputText w-2/3"
                    />
                  </div>
                  {emailError && emailError !== "" && <div className="text-red-600 text-smp">{emailError}</div>}
                </div>

                <div>
                  <button
                    type="submit"
                    className={`flex  justify-center rounded-md bg-top-digital px-3 py-1.5 text-smp font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                      hasForgotPasswordRequested ? "w-full disabled:opacity-75" : "w-1/2"
                    }`}
                  >
                    {hasForgotPasswordRequested ? "Aguarde..." : "Enviar"}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <ImageOffLine />
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
