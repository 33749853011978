import { api, requestConfig } from "../utils/config";

//post
const checkCNPJ = async (data: any): Promise<any> => {
  const config = requestConfig("POST", data);

  try {
    const res = await fetch(api + "/cnpj", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    return error;
  }
};

//get
const getUserCNPJ = async (token: string | undefined) => {
  const config = requestConfig("GET", null, token);

  try {
    const res = await fetch(api + "/cnpj/usercnpj", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.error(error);
  }
};

//post
const addCNPJ = async (data: any, token: string | undefined): Promise<any> => {
  const config = requestConfig("POST", data, token);

  try {
    const res = await fetch(api + "/cnpj/addcnpj", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    return error;
  }
};

//post
const hiddenCNPJ = async (data: any, token: string | undefined): Promise<any> => {
  const config = requestConfig("POST", data, token);

  try {
    const res = await fetch(api + "/cnpj/hidecnpj", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    return error;
  }
};

const CNPJService = {
  checkCNPJ,
  getUserCNPJ,
  addCNPJ,
  hiddenCNPJ,
};

export default CNPJService;
