import Login from "../../components/Login/Login";
import TrialBanner from "../../components/Sales/TrialBanner";
import ImageSecondary from "../../presentational/image-secondary/ImageSecondary";

const LoginPage = () => (
  <div className="flex flex-col h-auto">
    <div className="hidden lg:flex lg:items-center lg:justify-center lg:lg:w-full lg:mb-6">
      <ImageSecondary />
    </div>

    <div className="flex flex-col lg:flex-row lg:items-start lg:justify-center">
      <div className="mt-2 lg:mt-2 lg:w-1/2 lg:h-[340px] lg:pl-44 lg:pr-6">
        <TrialBanner />
      </div>
      <div className="hidden  lg:block lg:w-1 lg:h-[340px] lg:bg-top-digital-gray-400 lg:self-center lg:rounded-lg" />
      <div className="mt-16 lg:mt-2 lg:flex lg:items-start lg:justify-center lg:w-1/2 lg:h-full lg:pl-6 lg:pr-44">
        <Login />
      </div>
    </div>
  </div>
);

export default LoginPage;
