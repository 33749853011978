import { BellRing } from "lucide-react";
import Notification from "../Notification/Notification";
import { useEffect, useState } from "react";

import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

import { getUserNotifications, setNotificationsRead, notificationSelector } from "../../slices/NotificationSlice";
import { TNotificationData } from "../../Interfaces/INotificationData";

const NavNotification = () => {
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notificationUserDataNotRead, setNotificationUserDataNotRead] = useState([] as TNotificationData[]);

  const dispatch = useAppDispatch();
  const { notificationUserData } = useAppSelector(notificationSelector);

  useEffect(() => {
    const notRead: TNotificationData[] = notificationUserData.filter((notification) => !notification.read);
    setNotificationUserDataNotRead(notRead);
  }, [notificationUserData]);

  useEffect(() => {
    dispatch(getUserNotifications());
  }, [dispatch]);

  function handleNotifications() {
    setNotificationsOpen(!notificationsOpen);
    if (!notificationsOpen) {
      dispatch(setNotificationsRead(notificationUserDataNotRead));
    }
  }

  return (
    <>
      <button className="cursor-pointer" onClick={handleNotifications}>
        <div className="relative inline-flex">
          <div className="mb-1 w-7 h-7 ml-2 rounded-full bg-top-digital-op-40  flex items-center justify-center overflow-hidden">
            <BellRing className="w-4 h-4" />
          </div>
          {notificationUserDataNotRead && notificationUserDataNotRead.length > 0 && (
            <span className="absolute rounded-full py-[3px] px-[3px] text-smp font-normal leading-none top-[4%] right-[4%] translate-x-1/4 -translate-y-1/4 bg-red-500 text-white border-[1px] border-white min-w-[18px] min-h-[18px]">
              {notificationUserDataNotRead.length}
            </span>
          )}
        </div>
      </button>
      {notificationsOpen && <Notification setNotificationsOpen={setNotificationsOpen} />}
    </>
  );
};

export default NavNotification;
