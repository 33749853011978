import { useEffect, useMemo } from "react";
import type { ColumnDef } from "@tanstack/react-table";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import convertMongoDateToBRFormat from "../../utils/dateBR";
import formatCNPJ from "../../utils/formatCNPJ";
import formatNumbers from "../../utils/formatNumber";
import checkBarCode from "../../utils/checkBarCode";

import { getAllBoletos, boletoSelector } from "../../slices/BoletoSlice";

import { TBoletoData } from "../../Interfaces/IBoletoData";
import { Table } from "./Table";
import DatePaymentSelect from "./DatePaymentSelect";
import BoletoStatus from "./BoletoStatus";

const Boleto = () => {
  const dispatch = useAppDispatch();
  const { boletoData, lastDateCheckBoletos } = useAppSelector(boletoSelector);

  useEffect(() => {
    dispatch(getAllBoletos());
  }, [dispatch]);

  // useEffect(() => {
  //   const updateData = () => {
  //     dispatch(getAllBoletos());
  //   };
  //   console.log(intervalRef.current);

  //   if (boletoProcessing) {
  //     intervalRef.current = window.setInterval(updateData, 3000);
  //   } else {
  //     if (intervalRef.current !== null) {
  //       clearInterval(intervalRef.current);
  //       intervalRef.current = null;
  //     }
  //   }

  //   return () => {
  //     if (intervalRef.current !== null) {
  //       clearInterval(intervalRef.current);
  //     }
  //   };
  // }, [boletoProcessing, dispatch]);

  const situacao: string = "situacao";
  const linhaDigitavel: string = "linhaDigitavel";

  const cols = useMemo<ColumnDef<TBoletoData>[]>(
    () => [
      {
        header: "Link",
        cell: (row) => <DatePaymentSelect nossoNumero={row.renderValue()} situacao={row.row.getValue(situacao)} />,
        accessorKey: "nossoNumero",
      },
      {
        header: "Receita",
        cell: (row) => row.renderValue(),
        accessorKey: "receita",
      },
      {
        header: "NN",
        cell: (row) => row.renderValue(),
        accessorKey: "nossoNumero",
      },
      {
        header: "CNPJ",
        cell: (row) => formatCNPJ(row.renderValue()),
        accessorKey: "CNPJ",
      },
      {
        header: "Número Fistel",
        cell: (row) => row.renderValue(),
        accessorKey: "NumFistel",
      },
      {
        header: "Serviço",
        cell: (row) => row.renderValue(),
        accessorKey: "servico",
      },
      {
        header: "Data de Vencimento",
        cell: (row) => convertMongoDateToBRFormat(row.renderValue()),
        accessorKey: "dataVencimento",
      },
      {
        header: "Data de Pagamento",
        cell: (row) => row.renderValue(),
        accessorKey: "dataPagamento",
      },
      {
        header: "Situação",
        cell: (row) => <BoletoStatus situacao={row.renderValue()} key={row.row.getValue(linhaDigitavel)} />,
        accessorKey: "situacao",
      },
      {
        header: "Valor Total",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorTotal",
      },
      {
        header: "Débito",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorPrincipal",
      },
      {
        header: "Mora/Multa/Juros",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorMoraMultaJuros",
      },
      {
        header: "Outros Acréscimos",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorOutrosAcrescimos",
      },
      {
        header: "Descontos/Abatimentos",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorDescontosAbatimentos",
      },
      {
        header: "Outras Deduções",
        cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
        accessorKey: "valorOutrasDeducoes",
      },
      // {
      //   header: "Juros Encargos",
      //   cell: (row) => formatNumbers.formatNumberToBRL(row.renderValue()),
      //   accessorKey: "valorJurosEncargos",
      // },
      {
        header: "Linha Digitável",
        cell: (row) => checkBarCode(row.renderValue()),
        accessorKey: "linhaDigitavel",
      },
      // {
      //   header: "Ano Referência",
      //   cell: (row) => checkBarCode(row.renderValue()),
      //   accessorKey: "anoReferencia",
      // },
      {
        header: "Razão Social",
        cell: (row) => row.renderValue(),
        accessorKey: "Nome_Entidade_Prestadora_Servico",
      },
      {
        header: "Nome Fantasia",
        cell: (row) => row.renderValue(),
        accessorKey: "Nome_Fantasia",
      },
      {
        header: "Município",
        cell: (row) => row.renderValue(),
        accessorKey: "Nome_Municipio_Endereco_Sede",
      },
      {
        header: "UF",
        cell: (row) => row.renderValue(),
        accessorKey: "UF_Endereco_Sede",
      },
    ],
    []
  );

  const data: TBoletoData[] = boletoData || [];

  return (
    <div className="mt-2 flex flex-col items-center">
      <div className="mt-2 flex flex-col items-start w-full">
        <div className="flex-col items-start">
          <div className="font-semibold font-top-digital-content text-xl md:text-lg text-top-digital-content-color mt-2 md:leading-none">
            <h2>Boletos</h2>
          </div>
          <div className="font-top-digital-content text-smp md:text-lg text-top-digital-content-color mb-0 md:leading-none">
            <span className="text-smp">Consultas de Débitos de Fistel</span>
          </div>
          <div className="font-top-digital-content text-smp md:text-lg text-top-digital-content-color mb-0 md:leading-none">
            {lastDateCheckBoletos && <span className="text-smp">Data da última consulta: {lastDateCheckBoletos} </span>}
          </div>
        </div>
        <div className="relative w-full" style={{ minHeight: "35px" }}>
          <div className="absolute inset-0 flex justify-center items-center">
            {/* {boletoProcessing ? <BoletoProcessing /> : null} */}
            {/* {downloadBoletoLoading ? <BoletoDownload /> : null} */}
          </div>
        </div>
      </div>

      <Table data={data} columns={cols} />
    </div>
  );
};

export default Boleto;
