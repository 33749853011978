import FacebookIcon from "../../presentational/social-networks/Facebook";
import GithubIcon from "../../presentational/social-networks/Github";
import InstagramIcon from "../../presentational/social-networks/Instagram";
import LinkedInIcon from "../../presentational/social-networks/LinkedIn";
//import TwitterIcon from "../../presentational/social-networks/Twitter";

const Footer = () => (
  <footer className="mt-2">
    <div className="text-center lg:text-left">
      <div className="flex items-center justify-center p-6 dark:border-neutral-500 lg:justify-between bg-top-dgital-footer-1">
        <div className="mr-12 hidden lg:block font-top-digital-content text-sm text-white ">
          <span>Conecte-se em nossas redes sociais:</span>
        </div>

        <div className="flex justify-center text-white">
          <FacebookIcon />
          {/* <TwitterIcon /> */}
          <InstagramIcon />
          <GithubIcon />
          <LinkedInIcon />
        </div>
      </div>

      <div className="p-2 lg:p-6 text-center font-top-digital-content bg-top-dgital-footer-2 text-white text-smp lg:text-sm border-t border-t-1 border-white flex  flex-col lg:flex-row justify-center items-center gap-1">
        <div>
          <a
            target="_blank"
            href="https://docs.google.com/document/d/1i_ORar6q4_WWSqThR3Z1G9CiHtvoGOcRr7htbvkpfW8/edit?usp=sharing"
            rel="noreferrer"
            className="text-white"
          >
            Privacidade
          </a>
        </div>
        <div className="hidden lg:block">|</div>
        <div>
          <a
            target="_blank"
            href="https://docs.google.com/document/d/157y9BhhjLiKrkfJGKcWmIaF1rP3Q_HDdfMakrILZJRU/edit?usp=sharing"
            rel="noreferrer"
            className="text-white"
          >
            Termos de uso
          </a>
        </div>
        <div className="hidden lg:block">|</div>
        <div className="flex justify-center items-center gap-1">
          <span>&copy; 2024</span>
          <a target="_blank" href="http://topdigital.com.br/" rel="noreferrer" className="text-white">
            Top Digital. Todos os diretos reservados.
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
