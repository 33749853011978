import { createContext, ReactNode, useReducer } from "react";
import { ActionGlobalState, GlobalContextType, GlobalState } from "../Interfaces/IGlobalState";

const globalStateReducer = (state: GlobalState, action: ActionGlobalState): GlobalState => {
  switch (action.type) {
    case "SET_GLOBAL_PAGE_PARAM": {
      return { ...state, [action.globalPageParam]: action.globalPageValue };
    }
    /*Station area*/

    case "CLOSE_LABEL_STATION_FORM": {
      return { ...state, openedLabelStationForm: false };
    }
    case "SET_LABEL_STATION": {
      return { ...state, labelStation: action.labelStation };
    }
    case "SET_STATION_PAGE": {
      return { ...state, stationPage: action.stationPage };
    }
    /*System area*/
    case "CLOSE_LABEL_SYSTEM_FORM": {
      return { ...state, openedLabelSystemForm: false };
    }
    case "CLOSE_SYSTEM_DETAILS_AND_LABEL_SYSTEM_FORM": {
      return { ...state, openedSystemDetails: false, openedLabelSystemForm: false, idSystem: "" };
    }
    case "SET_LABEL_SYSTEM": {
      return { ...state, labelSystem: action.labelSystem };
    }
    case "SET_SYSTEM_PAGE": {
      return { ...state, systemPage: action.systemPage };
    }
    case "SET_STATION_ID": {
      return { ...state, idStation: action.idStation };
    }
    /*Map Area*/
    case "SET_INFOWINDOW_COORD": {
      return {
        ...state,
        latInfoWindow: action.lat,
        lngInfoWindow: action.lng,
        classeEstacaoInfoWindow: action.classesEstacao ? action.classesEstacao : [],
        idStation: action.idStation || "",
      };
    }
    case "CLEAN_INFOWINDOW_COORD": {
      return {
        ...state,
        latInfoWindow: null,
        lngInfoWindow: null,
        classeEstacaoInfoWindow: [],
        idStation: "",
        stationPage: 1,
      };
    }
    case "SET_VISIBLE_LOCATIONS": {
      return { ...state, visibleLocations: [...action.visibleLocations], loadingStationList: false };
    }
    case "DEL_VISIBLE_LOCATIONS": {
      return { ...state, visibleLocations: [] };
    }
    case "SET_RESET_MAP": {
      return { ...state, resetMap: !state.resetMap };
    }
    /*Document area */
    case "CLOSE_SYSTEM_LINK_FORM": {
      return { ...state, openSystemLinkForm: false, idDocument: action.idDocument };
    }
    case "CLOSE_UPLOAD_DOC_FORM": {
      return { ...state, openedUploadForm: false };
    }

    case "EXIT_FULLSCREEN_DOCUMENTS": {
      return { ...state, openFullScreenDocuments: false };
    }

    case "RESET_ALL": {
      return {
        ...state,
        openedLabelStationForm: false,
        openedLabelSystemForm: false,
        openedSystemDetails: false,
        openSystemLinkForm: false,
        openedUploadForm: false,
        idDocument: "",
        idSystem: "",
        labelSystem: "",
      };
    }

    /*Open/Close area */
    case "OPEN_FORM": {
      return {
        ...state,
        openedLabelStationForm: action.currentForm === "openedLabelStationForm" ? true : false,
        openedLabelSystemForm: action.currentForm === "openedLabelSystemForm" ? true : false,
        openedSystemDetails: action.currentForm === "openedSystemDetails" ? true : false,
        openSystemLinkForm: action.currentForm === "openSystemLinkForm" ? true : false,
        openedUploadForm: action.currentForm === "openedUploadForm" ? true : false,
        openFullScreenDocuments: action.currentForm === "openFullScreenDocuments" ? true : false,
        idDocument:
          action.currentForm === "openSystemLinkForm" ? (action.idDocument !== undefined ? action.idDocument : "") : "",
        idSystem:
          action.currentForm === "openedLabelSystemForm" || action.currentForm === "openedSystemDetails"
            ? action.idSystem !== undefined
              ? action.idSystem
              : ""
            : "",
        labelSystem:
          action.currentForm === "openedLabelSystemForm"
            ? action.labelSystem !== undefined
              ? action.labelSystem
              : ""
            : "",
      };
    }
    //filters home
    case "SET_FILTERS": {
      return { ...state, filters: action.filters };
    }
    case "OPEN_LANG_FILTER": {
      return { ...state, showLatLangFilter: true };
    }
    case "CLOSE_LANG_FILTER": {
      return { ...state, showLatLangFilter: false };
    }
    case "LOADING_STATION_LIST_START": {
      return { ...state, loadingStationList: true };
    }
    case "LOADING_STATION_LIST_END": {
      return { ...state, loadingStationList: false };
    }
    case "SET_LABEL_NEW_VALUE": {
      return { ...state, labelNewValue: action.labelNewValue };
    }
  }
  return state;
};

export const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);
export const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
  const pageStoraged: string = localStorage.getItem("lsStationPage") || "1";

  let startPage: number;

  if (!isNaN(Number(pageStoraged)) && pageStoraged) {
    startPage = parseInt(pageStoraged);
  } else {
    startPage = 1;
  }

  const initialState: GlobalState = {
    //
    globalPageValue: 0,
    globalPageParam: "",
    //Station States
    openedLabelStationForm: false,
    labelStation: "",
    stationPage: startPage,
    idStation: "",
    //System States
    openedLabelSystemForm: false,
    openedSystemDetails: false,
    labelSystem: "",
    systemPage: 1,
    idSystem: "",
    //map states
    latInfoWindow: 0,
    lngInfoWindow: 0,
    classeEstacaoInfoWindow: [],
    visibleLocations: [],
    resetMap: false,
    //documents states
    openFullScreenDocuments: false,
    openSystemLinkForm: false,
    openedUploadForm: false,
    idDocument: "",
    //filter home
    showLatLangFilter: false,
    filters: {},
    //loadings
    loadingStationList: false,
    //labels
    labelNewValue: false,
    //modalDocuments
  };

  const [globalState, dispatchGlobalState] = useReducer(globalStateReducer, initialState);

  return <GlobalContext.Provider value={{ globalState, dispatchGlobalState }}>{children}</GlobalContext.Provider>;
};
