import { useEffect, useMemo } from "react";
import type { ColumnDef } from "@tanstack/react-table";

import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

import convertMongoDateToBRFormat from "../../utils/dateBR";

import { getAllNotifications, notificationSelector } from "../../slices/NotificationSlice";

import { TNotificationData } from "../../Interfaces/INotificationData";
import { Table } from "./Table";

import extractDetailsToObject from "../../utils/extractDetailsToObject";
import JSONTreeField from "./JSONTreeField";
import Field from "./Field";
import { useLocation } from "react-router-dom";

const Notification = () => {
  const dispatch = useAppDispatch();
  const { notificationData } = useAppSelector(notificationSelector);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id") || "";

  useEffect(() => {
    let filterObj = {};
    if (id !== "") {
      filterObj = { ...filterObj, id: id };
    }

    dispatch(getAllNotifications(filterObj));
  }, [dispatch, id]);

  const cols = useMemo<ColumnDef<TNotificationData>[]>(
    () => [
      {
        header: "Operação",
        cell: (row) => {
          return <Field data={row.row.getValue("name")} />;
        },
        accessorKey: "name",
      },
      {
        header: "Descrição",
        cell: (row) => {
          return <Field data={row.row.getValue("description")} />;
        },
        accessorKey: "description",
      },
      {
        header: "Nome do Usuário Atual",
        cell: (row) => (row.row.original.currentUserId ? row.row.original.currentUserId.name : "Não Informado"),
        accessorFn: (row) => (row.currentUserId ? row.currentUserId.name : "Não Informado"),
      },

      {
        header: "Detalhes adicionais",
        cell: (row) => {
          return <JSONTreeField data={extractDetailsToObject(row.row.getValue("details"))} widthValue="200" />;
        },
        accessorKey: "details",
      },
      {
        header: "Objeto da Operação",
        cell: (row) => {
          return <JSONTreeField data={row.row.getValue("document")} widthValue="200" />;
        },
        accessorKey: "document",
      },

      {
        header: "Data da Operação",
        cell: (row) => convertMongoDateToBRFormat(row.renderValue()),
        accessorKey: "timestamp",
      },
    ],
    []
  );

  const data: TNotificationData[] = notificationData || [];

  return (
    <div className="mt-2 flex flex-col items-center">
      <div className="mt-2 flex flex-col items-center w-full">
        <div className="text-xl text-top-digital-content-color font-semibold mb-2 font-top-digital-content">
          Ações Realizadas no Agiliza
        </div>
      </div>
      <Table data={data} columns={cols} />
    </div>
  );
};

export default Notification;
