import { Link } from "react-router-dom";

type Props = {
  data: string;
  read?: boolean;
  id?: string;
  setNotificationsOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
};

const Field = ({ data, read, id, setNotificationsOpen }: Props) => {
  return (
    <>
      {id ? (
        <Link
          to={`/notification/?id=${id}`}
          onClick={() => {
            setNotificationsOpen && setNotificationsOpen(false);
          }}
          className={`hover:text-top-digital-link-hover ${read ? "font-normal" : "font-semibold"}`}
        >
          {data && data}
        </Link>
      ) : (
        <span className="font-normal">{data && data}</span>
      )}
    </>
  );
};

export default Field;
