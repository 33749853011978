import { RegisterFinishRegisterPayload, RegisterPayload } from "../Interfaces/IRegisterStates";
import { api, requestConfig } from "../utils/config";

const register = async ({ userRegister, roleRegister }: RegisterPayload): Promise<any> => {
  const requestData = { userRegister, roleRegister };

  const config = requestConfig("POST", requestData);
  try {
    const res = await fetch(api + "/users/register", config);
    if (!res.ok) {
      const responseJson = await res.json();
      // Retornar os erros no formato esperado
      return { errors: responseJson.errors };
    }
    return res.json();
  } catch (error) {
    // Log do erro e retornar um objeto de erro genérico
    return { errors: [{ field: "unknown", message: "Erro desconhecido no registro" }] };
  }
};

const finishRegister = async ({ userRegister, roleRegister }: RegisterFinishRegisterPayload): Promise<any> => {
  const requestData = { userRegister, roleRegister };
  const config = requestConfig("POST", requestData);
  try {
    const res = await fetch(api + "/users/register/finishregister", config);
    if (!res.ok) {
      const responseJson = await res.json();
      // Retornar os erros no formato esperado
      return { errors: responseJson.errors };
    }
    return res.json();
  } catch (error) {
    // Log do erro e retornar um objeto de erro genérico
    return { errors: [{ field: "unknown", message: "Erro desconhecido no registro" }] };
  }
};

const confirmationToken = async (tokenConfirm: string): Promise<any> => {
  const config = requestConfig("GET", null);
  try {
    const res = await fetch(api + "/users/register/confirm/" + tokenConfirm, config);
    if (!res.ok) {
      const responseJson = await res.json();
      // Retornar os erros no formato esperado
      return { errors: responseJson.errors };
    }
    return res.json();
  } catch (error) {
    // Log do erro e retornar um objeto de erro genérico
    //console.log(error);
    return { errors: [{ field: "unknown", message: "Erro desconhecido no registro" }] };
  }
};

const finishRegisterToken = async (tokenFinish: string): Promise<any> => {
  const config = requestConfig("GET", null);
  try {
    const res = await fetch(api + "/users/register/finishchild/" + tokenFinish, config);
    if (!res.ok) {
      const responseJson = await res.json();
      // Retornar os erros no formato esperado
      return { errors: responseJson.errors };
    }
    return res.json();
  } catch (error) {
    // Log do erro e retornar um objeto de erro genérico
    //console.log(error);
    return { errors: [{ field: "unknown", message: "Erro desconhecido no registro" }] };
  }
};

const RegisterService = {
  register,
  finishRegister,
  confirmationToken,
  finishRegisterToken,
};

export default RegisterService;
