import React, { ChangeEvent, useState, useEffect, MouseEvent } from "react";
import io from "socket.io-client";
import { Link } from "react-router-dom";
import { urlapp, urlappbackend } from "../../utils/config";

//Hooks Redux
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
//Slice
import { stationSelector, uploadStations, stationReset } from "../../slices/StationSlice";

import { TUpload } from "../../Interfaces/IStation";

const socket = io(urlappbackend, { path: "/socket", transports: ["websocket"] });

// Inside some function that is called when the relevant route is accessed
function onUploadRouteAccessed() {
  //if (mode !== null && mode === "prod" && socket !== null) {
  socket.connect();
  //}
}

// Inside some function that is called when the relevant route is left
function onUploadRouteLeft() {
  //if (mode !== null && mode === "prod" && socket !== null) {
  socket.disconnect();
  //}
}

const Upload = () => {
  //Io Client
  onUploadRouteAccessed();
  const [progress, setProgress] = useState<TUpload | null>(null);
  const [uploadError, setUploadError] = useState<string>("");

  //Redux
  const dispatch = useAppDispatch();
  const { stationLoading, stationSuccess, stationError, stationMessage, uploadProgress } =
    useAppSelector(stationSelector);

  const [csvFile, setCsvFile] = useState<File>();

  useEffect(() => {
    dispatch(stationReset());
    socket.on("uploadProgress", (progress) => {
      setProgress(progress);
    });

    //Terminates socket connection when component is unmounted
    return () => {
      onUploadRouteLeft();
    };
  }, [dispatch]);

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    //image preview
    const csv = e?.target?.files?.[0];
    if (!csv) return;
    setCsvFile(csv);
  };

  const submitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //build form data
    if (csvFile) {
      setUploadError("");
      const formData = new FormData();
      formData.append("csvfile", csvFile, csvFile.name);
      // Remove coords and page from localstorage
      localStorage.removeItem("lsStationPage");
      const coordsValue = localStorage.getItem("coords");

      if (coordsValue) {
        localStorage.setItem("coordstmp", coordsValue);
      }

      localStorage.removeItem("coords");
      //progress of upload

      dispatch(uploadStations(formData));
    } else {
      setUploadError("Favor selecionar um arquivo");
    }
  };

  const handleNewUpload = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(stationReset());
  };

  return (
    <div className="flex w-full mt-4">
      <div className="w-full flex items-center justify-center">
        {!stationLoading && !stationSuccess ? (
          <div className="flex flex-1 flex-col justify-start px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="mx-auto h-14 w-auto"
                src={`${urlapp}/img/logo-antena-top-digital-agi.png`}
                alt="Your Company"
              />
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight top-digital-content-color">
                Upload do arquivo CSV Anatel
              </h2>
              {stationError && <div className="mt-2 text-red-600 text-smp">{stationMessage}</div>}
              {uploadError && <div className="mt-2 text-red-600 text-smp">{uploadError}</div>}
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form encType="multipart/form-data" className="space-y-6" onSubmit={submitHandle}>
                <div>
                  <label
                    htmlFor="csvfile"
                    className="mb-3 block text-smp font-medium top-digital-content-color-content-color font-top-digital-content"
                  >
                    Selecione o arquivo CSV para upload
                  </label>
                  <input
                    type="file"
                    onChange={handleFile}
                    name="csvfile"
                    id="csvfile"
                    placeholder="5"
                    min="0"
                    className="customInputFile"
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-smp font-semibold leading-6 text-white shadow-sm hover:top-digital-content-color-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-top-digital-hover"
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="flex flex-col max-w-[650px]">
            <div className="mx-auto w-full mb-2 flex flex-col justify-center">
              <div className="top-digital-content-color text-xl font-semibold">Etapa 1: envio do arquivo CSV</div>
              <div className="flex items-center">
                <div className="h-2 bg-blue-500 rounded" style={{ width: `${uploadProgress}%` }} />
                <div className="top-digital-content-color text-sm">{uploadProgress}%</div>
              </div>
            </div>
            {progress?.firstStep.status && (
              <div className="mx-auto w-full mb-2 flex flex-col items-start">
                <div className="top-digital-content-color text-xl font-semibold">Etapa 2: checkagem do arquivo</div>
                <div className="flex">
                  <div className="top-digital-content-color text-sm">
                    {progress?.secondStep.totalLines} registros encontrados
                  </div>
                </div>
              </div>
            )}
            <>
              {progress?.secondStep.status && (
                <div className="mx-auto w-full mb-2 flex flex-col items-start">
                  <div className="top-digital-content-color text-xl font-semibold">
                    Etapa 3: checkagem dos registros
                  </div>
                  <div className="flex flex-col">
                    <div className="top-digital-content-color text-sm">
                      {progress?.secondStep.currentLine} registros analisados
                    </div>
                    <div className="top-digital-content-color text-sm">
                      {progress?.secondStep.currentEnableLines} registros aptos
                    </div>
                    <div className="top-digital-content-color text-sm">
                      {progress?.secondStep.currentProblemLines} registros com problema
                    </div>
                  </div>
                </div>
              )}

              {progress?.thirdStep.status && (
                <div className="mx-auto w-full mb-2 flex flex-col items-start">
                  <div className="top-digital-content-color text-xl font-semibold">Etapa 4: Registros auxiliares</div>
                  <div className="flex flex-col">
                    <div className="top-digital-content-color text-sm">
                      {progress?.thirdStep.insertingStationSystem ? (
                        <p>Aguarde alguns instantes....</p>
                      ) : (
                        <p>Registros auxilires inseridos: {progress?.thirdStep.totalInserts}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {progress?.fourthStep.status && (
                <div className="mx-auto w-full mb-2 flex flex-col items-start">
                  <div className="top-digital-content-color text-xl font-semibold">Etapa 5: Estações e Sistemas</div>
                  <div className="flex flex-col">
                    <div className="top-digital-content-color text-sm">
                      Total estações: {progress?.fourthStep.totalStations}
                    </div>
                    <div className="top-digital-content-color text-sm">
                      Total novas estações: {progress?.fourthStep.totalNewStations}
                    </div>
                    <div className="top-digital-content-color text-sm">
                      Total Sistemas: {progress?.fourthStep.totalSystems}
                    </div>
                    <div className="top-digital-content-color text-sm">
                      Total novos sistemas: {progress?.fourthStep.totalNewSystems}
                    </div>
                    <div className="top-digital-content-color text-sm">
                      Total de sistemas atualizados: {progress?.fourthStep.totalUpdatedSystems}
                    </div>
                    <div className="top-digital-content-color text-sm">
                      Total de sistemas sem atualização: {progress?.fourthStep.totalNoChangedSystems}
                    </div>
                  </div>
                </div>
              )}
              <div>
                {stationSuccess && (
                  <div className="flex flex-col items-center justify-center font-medium pt-4 pb-2 top-digital-content-color-hover text-lg">
                    <div className="mx-auto w-full h-32 p-4 rounded-md bg-top-digital-op-25 top-digital-content-color-content-color font-top-digital-content">
                      <h2 className="text-base font-semibold">Upload feito com sucesso!</h2>
                      <p className="text-sm">
                        Acesse a{" "}
                        <Link to="/" className="underline">
                          home
                        </Link>{" "}
                        para utilizar o Agiliza.
                      </p>
                      <button className="text-sm" onClick={handleNewUpload}>
                        Ou faça um novo{" "}
                        <Link to="/upload" className="underline">
                          upload
                        </Link>
                        .
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default Upload;
