import React from "react";

const NotAuthorized = () => {
  return (
    <div>
      <h1>Não autorizado!</h1>
      <p>Você não tem permissão para acessar essa página.</p>
    </div>
  );
};

export default NotAuthorized;
