import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { networkFilesSelector, deleteFile, getFiles, resetSuccess } from "../../slices/NetworkFilesSlice";
import { NetworkFile } from "../../Interfaces/INetworkFile";

type Props = {
  doc: NetworkFile;
};

const DeleteDocument: React.FC<Props> = ({ doc }) => {
  const dispatch = useAppDispatch();
  const { success } = useAppSelector(networkFilesSelector);
  const [deleteInitiated, setDeleteInitiated] = useState(false);
  const network = doc.network;

  const handleDelete = () => {
    if (doc._id) {
      dispatch(deleteFile(doc._id));
      setDeleteInitiated(true);
    }
  };

  useEffect(() => {
    if (success && deleteInitiated) {
      dispatch(getFiles(network));
      dispatch(resetSuccess());
      setDeleteInitiated(false);
    }
  }, [success, dispatch, deleteInitiated, network]);

  return <FaTrash title="Deletar o arquivo" className="cursor-pointer" onClick={handleDelete} />;
};

export default DeleteDocument;
