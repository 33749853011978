import { useEffect } from "react";

//context
import { useGlobalContext } from "../../hooks/useGlobalContext";

//icons
import { Maximize2, Minimize2 } from "lucide-react";

//redux
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { reset, getStationDocs, documentSelector } from "../../slices/DocumentSlice";
import { linkSystemDocSelector } from "../../slices/LinkSystemDocSlice";

//components
import DoumentUpload from "./DocumentUpload";
import DocumentList from "./DocumentList";
import { useAuth } from "../../hooks/useAuth";

import { roleRegister } from "../../Interfaces/IRegisterStates";
import { useParams } from "react-router-dom";

const Documents = () => {
  const stationId = useParams<{ id: string }>().id;
  const dispatch = useAppDispatch();

  const { documents, insertSuccess, deleteSuccess, loading: loadingDocument } = useAppSelector(documentSelector);
  const { successLinkSystem } = useAppSelector(linkSystemDocSelector);

  //context
  const { globalState, dispatchGlobalState } = useGlobalContext();
  const { role } = useAuth().userData;

  /*Inicio Formulário de Documentos*/

  useEffect(() => {
    if (deleteSuccess) {
      // Disparando o reset do slice de documentos após 3 segundos
      const timer = setTimeout(() => {
        dispatch(reset());
      }, 3000);

      // Limpando o timer se o componente for desmontado antes de 3 segundos
      return () => clearTimeout(timer);
    }
  }, [deleteSuccess, dispatch]);

  useEffect(() => {
    if (insertSuccess) {
      dispatchGlobalState({ type: "CLOSE_UPLOAD_DOC_FORM" });
      // Disparando o reset do slice de documentos após 3 segundos
      const timer = setTimeout(() => {
        dispatch(reset());
      }, 3000);

      // Limpando o timer se o componente for desmontado antes de 3 segundos
      return () => clearTimeout(timer);
    }
  }, [insertSuccess, dispatch, dispatchGlobalState]);

  useEffect(() => {
    const objStation = {
      stationId,
      systemId: "",
      docTypeId: "",
      uploadDocumentModal: "",
      lastNameVisible: roleRegister.lastname.isVisible,
    };
    dispatch(getStationDocs(objStation));
  }, [stationId, dispatch, dispatchGlobalState, insertSuccess, deleteSuccess, successLinkSystem]);

  return (
    <>
      {!globalState.openedUploadForm && (
        <div
          className={`${
            globalState.openFullScreenDocuments ? "flex flex-col" : "absolute top-0 left-0"
          } bg-white flex flex-col`}
        >
          {deleteSuccess && <div className="text-blue-600 text-smp">Documento removido com sucesso</div>}
          {insertSuccess && <div className="text-blue-600 text-smp">Documento inserido com sucesso</div>}
          {loadingDocument ? (
            <h2 className="md:text-base text-smp text-top-digital-content-color font-semibold font-top-digital-title">
              {" "}
              Documentos. Aguarde...
            </h2>
          ) : (
            <h1 className="flex items-center gap-1 md:text-base text-smp text-top-digital-content-color font-semibold font-top-digital-title">
              Documentos{" "}
              {!globalState.openFullScreenDocuments ? (
                <button
                  className="cursor-pointer"
                  onClick={() => {
                    dispatchGlobalState({ type: "OPEN_FORM", currentForm: "openFullScreenDocuments" });
                  }}
                >
                  <Maximize2 className="h-4 w-4" />
                </button>
              ) : (
                <button
                  className="cursor-pointer"
                  onClick={() => {
                    dispatchGlobalState({ type: "EXIT_FULLSCREEN_DOCUMENTS" });
                  }}
                >
                  <Minimize2 className="h-4 w-4" />
                </button>
              )}
            </h1>
          )}

          {documents && documents.length === 0 && (
            <p className="text-top-digital-content-color text-sm">
              Você ainda não possui documentos cadastrados para estação e seus respectivos sistemas.
            </p>
          )}
          <div>
            <button
              className={`mb-2 mt-1 customButton w-1/2 lg:w-56 ${role !== "visualizador" ? "" : "hidden"}`}
              onClick={() => {
                dispatchGlobalState({ type: "OPEN_FORM", currentForm: "openedUploadForm" });
              }}
            >
              Cadastrar <span className="hidden md:inline">Documento</span>
            </button>
          </div>

          <DocumentList />
        </div>
      )}
      <DoumentUpload stationId={stationId} />
    </>
  );
};

export default Documents;
