import { UpdatePayload, UpdatedByAdminPayload, UpdatedByNotAdminPayload } from "../Interfaces/IRegisterStates";
import { api, requestConfig } from "../utils/config";

//get user detalis
const profile = async (token: string | undefined) => {
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(api + "/users/profile", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

const getDaysUntilTrialEnds = async (token: string | undefined) => {
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(api + "/users/daysuntiltrialends", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

//update user details
const update = async (token: string | undefined, { userUpdate, roleUpdate }: UpdatePayload): Promise<any> => {
  const requestData = { userUpdate, roleUpdate };
  const config = requestConfig("PUT", requestData, token);
  try {
    const res = await fetch(api + "/users/update", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.log(error);
  }
};

//update user details
const userUpdatedByAdmin = async (
  token: string | undefined,
  { userUpdate, roleUpdate }: UpdatedByAdminPayload
): Promise<any> => {
  const requestData = { userUpdate, roleUpdate };
  const config = requestConfig("PUT", requestData, token);
  try {
    const res = await fetch(api + "/users/updatebyadmin", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.log(error);
  }
};

//update user details
const userUpdatedByNotAdmin = async (
  token: string | undefined,
  { userUpdate, roleUpdate }: UpdatedByNotAdminPayload
): Promise<any> => {
  const requestData = { userUpdate, roleUpdate };
  const config = requestConfig("PUT", requestData, token);
  try {
    const res = await fetch(api + "/users/updatebynotadmin", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.log(error);
  }
};

//userUpdatedByNotAdmin

const ProfileService = {
  update,
  getDaysUntilTrialEnds,
  userUpdatedByAdmin,
  userUpdatedByNotAdmin,
  profile,
};

export default ProfileService;
