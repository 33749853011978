const extractDetailsToObject = (details: string) => {
  const regex = /Operação realizada em (.+) via (.+) no (.+), dispositivo (.+), com IP (.+) e domínio (.+)/;
  const match = details.match(regex);

  if (match) {
    const [, timestamp, browserName, osInfo, deviceInfo, clientIp, host] = match;
    // Formatando a data conforme o formato desejado (DD/MM/YYYY HH:MM:SS GMT-ZZZZ)
    const formattedDate = new Date(timestamp).toLocaleString("pt-BR", {
      timeZone: "America/Sao_Paulo",
    });

    return {
      Data: formattedDate,
      Navegador: browserName,
      SO: osInfo,
      Dispositivo: deviceInfo,
      IP: clientIp,
      Dominio: host,
    };
  } else {
    return null; // ou um objeto vazio {}, dependendo da sua preferência
  }
};

export default extractDetailsToObject;
