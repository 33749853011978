import { Link } from "react-router-dom";

const TrialBanner = () => {
  return (
    <div className="flex flex-col justify-between items-stretch lg:relative">
      <div>
        <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Pronto para Agilizar?</h2>
        <h3 className="text-lg font-bold tracking-tight text-gray-900 sm:text-lg justify-start">
          <span>Inicie seu teste grátis</span>
          <span className="hidden lg:inline"> agora</span>
        </h3>
        <div className="text-top-digital-content-color">
          <p className="font-semibold lg:text-base text-base">20 dias grátis para conhecer o Agiliza.</p>
          <p className="lg:text-sm font-medium text-sm lg:mt-2 text-justify h-4">
            Descubra como o Agiliza pode transformar o seu negócio. Sem cartão de crédito, sem complicações.
          </p>
        </div>
      </div>
      <div>
        <div className="mt-8 lg:mt-0 lg:absolute lg:top-[234px] lg:left-0 lg:mb-10">
          <Link to={"/register"} className="customButton w-[150px] font-semibold text-sm">
            Comece já
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrialBanner;
