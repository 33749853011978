//componnents
import { Link, useParams } from "react-router-dom";

//icons
import { Plus } from "lucide-react";

//Hooks
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { cpf as validaCPF, cnpj as validaCNPJ } from "cpf-cnpj-validator";
import { isValidNumber } from "libphonenumber-js";
import * as Yup from "yup";
import { generate } from "gerador-validador-cpf";
//import { format as formatCNPJ, generate as genCNPJ } from "cnpj";
import { faker } from "@faker-js/faker";
import formatBRPhone from "../../utils/formatBRPhone";
import { roleRegister } from "../../Interfaces/IRegisterStates";

//types
import { Gender, Role, TFinishRegisterFields, TRegisterFields, TipoCadastro } from "../../Interfaces/IRegisterStates";
import { TProfile, TProfileEmailUpdatedByAdmin, TProfileUpdatedByNotAdmin } from "../../Interfaces/IProfileStates";
import { update, profileSelector, userUpdatedByAdmin, userUpdatedByNotAdmin } from "../../slices/ProfileSlice";

import { loginSelector } from "../../slices/LoginSlice";

//redux
import {
  register,
  finishRegisterToken,
  registerReset,
  registerSelector,
  finishRegister,
} from "../../slices/RegisterSlice";
import { consultaCEP, cepSelector, resetCep } from "../../slices/CepSlice";
import { urlapp } from "../../utils/config";
import isValidObjectId from "../../utils/isValidObjectId";
import Confirmation from "./Confirmation";
import ImageOffLine from "../../presentational/image-main/ImageMain";
import ConfirmationFinishChild from "./ConfirmationFinishChild";
import jwtDecode from "jwt-decode";
import { TLogin } from "../../Interfaces/ILoginStates";
import { validatePassword } from "../../utils/validatePassword";
import FistelSelection from "./FistelSelection";
import CNPJSelection from "./CNPJSelection";

export function getRandomGender(): Gender {
  const genders = [Gender.Male, Gender.Female, Gender.Other];
  const randomIndex = Math.floor(Math.random() * genders.length);
  return genders[randomIndex];
}

function getRandomNumberString(digits: number): string {
  const min = Math.pow(10, digits - 1); // Define o mínimo com base na quantidade de dígitos
  const max = Math.pow(10, digits) - 1; // Define o máximo com base na quantidade de dígitos

  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber.toString();
}

const isFakeTest: boolean =
  process.env.REACT_APP_FAKE_REGISTER !== undefined && process.env.REACT_APP_FAKE_REGISTER === "true";

type Props = {
  userData: TProfile | null;
  setUpdateProfile: React.Dispatch<React.SetStateAction<boolean>> | null;
  setRegisterChildUser: React.Dispatch<React.SetStateAction<boolean>> | null;
  isFinishChild: boolean;
  setEditChildUserByAdmin?: React.Dispatch<React.SetStateAction<boolean>> | null;
  editChildUserByNotAdmin?: boolean | null;
};

const Register = React.memo(
  ({
    userData,
    setUpdateProfile,
    setRegisterChildUser,
    isFinishChild,
    setEditChildUserByAdmin,
    editChildUserByNotAdmin,
  }: Props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { tokenConfirm } = useParams();
    //const { fistelList } = useAppSelector(fistelSelector);
    //const { cnpjList } = useAppSelector(cnpjSelector);
    const [fistelList] = useState<string[]>([]);
    const [cnpjList, setCnpjList] = useState<string[]>([]);

    const {
      registerLoading,
      registerError,
      registerSuccess,
      registerMessage,
      registerFinishId,
      registerFinishEmail,
      registerFinishSuccess,
    } = useAppSelector(registerSelector);
    const { cepData, ceperror, cepsuccess, cepmessage } = useAppSelector(cepSelector);
    const { profileFormSuccess } = useAppSelector(profileSelector);
    const { user } = useAppSelector(loginSelector);
    let decodedToken: TLogin;
    if (user) decodedToken = jwtDecode(user);
    else decodedToken = { id: "", role: Role.EP, name: "", parentID: "" };

    const [isUpdate] = useState<boolean>(userData !== null ? true : false);
    const [isChildRegister] = useState<boolean>(
      isFinishChild
        ? false
        : userData === null &&
          setUpdateProfile === null &&
          user !== null &&
          (decodedToken.role === "superadmin" || decodedToken.role === "admin")
        ? true
        : false
    );
    const fakeFirstName = faker.person.firstName();
    const [tipoCadastro, setTipoCadastro] = useState<TipoCadastro>(
      isUpdate ? userData!.tipoCadastro : isChildRegister ? TipoCadastro.PF : TipoCadastro.PJ
    );
    const [name, setName] = useState<string>(isUpdate ? userData!.name : isFakeTest ? fakeFirstName : "");
    const [lastname, setLastName] = useState<string>(
      isUpdate ? userData!.lastname : isFakeTest ? faker.person.lastName() : ""
    );
    const [gender, setGender] = useState<Gender>(
      isUpdate ? userData!.gender : isFakeTest ? getRandomGender() : Gender.Empty
    );
    const [email, setEmail] = useState<string>(
      isUpdate
        ? userData!.email
        : isFinishChild && registerFinishEmail !== null
        ? registerFinishEmail
        : isFakeTest
        ? `${fakeFirstName.toLowerCase()}${getRandomNumberString(2)}@ceviu.com.br`
        : ""
    );
    const [isUpdateChild] = useState<boolean>(setEditChildUserByAdmin || editChildUserByNotAdmin ? true : false);
    const [tradename, setTradeName] = useState<string>(
      isUpdate ? userData!.tradename : isChildRegister ? "" : isFakeTest ? "" : ""
    );

    const [businessname, setBusinessname] = useState<string>(
      isUpdate ? userData!.businessname : isChildRegister ? "" : isFakeTest ? "" : ""
    );
    const [cnpj, setCnpj] = useState<string>(
      //isUpdate ? userData!.cnpj : isChildRegister ? "" : isFakeTest ? formatCNPJ(genCNPJ()) : ""
      isUpdate ? userData!.cnpj : isChildRegister ? "" : isFakeTest ? "" : ""
    );

    const [cpf, setCpf] = useState<string>(
      isUpdate ? userData!.cpf : isChildRegister ? "" : isFakeTest ? generate({ format: true }) : ""
    );

    const [cep, setCep] = useState<string>(isUpdate ? userData!.cep : isChildRegister ? "" : isFakeTest ? "" : "");
    const [street, setStreet] = useState<string>(
      isUpdate ? userData!.street : isChildRegister ? "" : isFakeTest ? "" : ""
    );
    const [number, setNumber] = useState<string>(
      isUpdate ? userData!.number : isChildRegister ? "" : isFakeTest ? "" : ""
    );
    const [complement, setComplement] = useState<string>(
      isUpdate ? userData!.complement : isChildRegister ? "" : isFakeTest ? "" : ""
    );
    const [district, setDistrict] = useState<string>(
      isUpdate ? userData!.district : isChildRegister ? "" : isFakeTest ? "" : ""
    );
    const [city, setCity] = useState<string>(isUpdate ? userData!.city : isChildRegister ? "" : isFakeTest ? "" : "");
    const [state, setState] = useState<string>(
      isUpdate ? userData!.state : isChildRegister ? "" : isFakeTest ? "" : ""
    );
    const [businessPhone, setBusinessPhone] = useState<string>(
      isUpdate ? formatBRPhone(userData!.businessPhone) : isChildRegister ? "" : isFakeTest ? "" : ""
    );
    const [cellPhone, setCellPhone] = useState<string>(
      isUpdate ? formatBRPhone(userData!.cellPhone) : isChildRegister ? "" : isFakeTest ? "" : ""
    );

    const [receiveNews, setReceiveNews] = useState<boolean>(isUpdate ? userData!.receiveNews : false);
    const [receiveLicensingNews, setReceiveLicensingNews] = useState<boolean>(
      isUpdate ? userData!.receiveLicensingNews : false
    );
    const [acceptAlertEmails, setAcceptAlertEmails] = useState<boolean>(isUpdate ? userData!.acceptAlertEmails : false);
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [acceptTermsDetails] = useState<string>("Termos a serem aceitos pelo usuário");
    const [password, setPassword] = useState<string>(isFakeTest ? "Naosei69" : "");
    const [confirmPassword, setConfirmPassword] = useState<string>(isFakeTest ? "Naosei69" : "");
    const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
    const [role, setRole] = useState<Role>(
      isChildRegister ? Role.EP : !isFinishChild && userData?.role ? userData?.role : Role.SA
    );
    //error states
    const [nameError, setNameError] = useState<string>("");
    const [lastNameError, setLastNameError] = useState<string>("");
    const [genderError, setGenderError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [tradeNameError, setTradeNameError] = useState<string>("");
    const [fistelError, setFistelError] = useState<string>("");
    const [cnpjError, setCnpjError] = useState<string>("");
    const [cpfError, setCpfError] = useState<string>("");
    const [cepError, setCepError] = useState<string>("");
    const [numberError, setNumberError] = useState<string>("");
    const [cellPhoneError, setCellPhoneError] = useState<string>("");
    const [businessPhoneError, setBusinessPhoneError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [cepErrorMessage, setCepErrorMessage] = useState<string>("");
    const [termsError, setTermsError] = useState<string>("");
    const [errorCount, setErrorCount] = useState<number>(0);
    const [registrationCompleted, setRegistrationCompleted] = useState<boolean>(false);
    const [roleError, setRoleError] = useState<string>("");

    //criando refs para posicionar a tela no pŕimeiro erro
    const nameRef = useRef<HTMLDivElement>(null);
    const lastNameRef = useRef<HTMLDivElement>(null);
    const genderRef = useRef<HTMLDivElement>(null);
    const emailRef = useRef<HTMLDivElement>(null);
    const tradeNameRef = useRef<HTMLDivElement>(null);
    const fistelRef = useRef<HTMLDivElement>(null);
    const cnpjRef = useRef<HTMLDivElement>(null);
    const cpfRef = useRef<HTMLDivElement>(null);
    const cepRef = useRef<HTMLDivElement>(null);
    const numberRef = useRef<HTMLDivElement>(null);
    const cellPhoneRef = useRef<HTMLDivElement>(null);
    const businessPhoneRef = useRef<HTMLDivElement>(null);
    const passwordRef = useRef<HTMLDivElement>(null);
    const termsRef = useRef<HTMLDivElement>(null);
    const registrationCompletedRef = useRef<HTMLDivElement>(null);
    const roleRef = useRef<HTMLDivElement>(null);

    //validation
    let parentID = "";
    const validateRole = () => {
      if (isChildRegister) {
        if (role === "") {
          setRoleError("Selecione o tipo de usuário.");
          return false;
        }

        if (
          decodedToken &&
          decodedToken.role !== "superadmin" &&
          decodedToken.role !== "admin" &&
          [Role.AD, Role.RE, Role.VI].includes(role)
        ) {
          setRoleError("Erro!");
          return false;
        } else {
          if (
            user !== null &&
            decodedToken.id !== "" &&
            (decodedToken.role === "superadmin" || decodedToken.role === "admin") &&
            isValidObjectId(decodedToken.id)
          ) {
            parentID = decodedToken.parentID;
          } else {
            setRoleError("Erro!");
            return false;
          }
        }
      }
      setRoleError("");
      return true;
    };

    const validateName = () => {
      if (name.length < 3) {
        setNameError("O nome deve ter pelo menos 3 caracteres.");
        return false;
      }
      setNameError("");
      return true;
    };

    const validateLastName = () => {
      if (lastname.length < 3) {
        setLastNameError("O sobrenome deve ter pelo menos 3 caracteres.");
        return false;
      }
      setLastNameError("");
      return true;
    };

    const validateGender = () => {
      if (gender === "") {
        setGenderError("Selecione o gênero do usuário.");
        return false;
      }
      setGenderError("");
      return true;
    };

    const validateEmail = () => {
      const schema = Yup.object().shape({
        email: Yup.string().email("Por favor, insira um email válido.").required("O email é obrigatório"),
      });

      try {
        schema.validateSync({ email });
        setEmailError("");
        return true;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setEmailError(err.message);
        } else {
          // Algum outro erro inesperado aconteceu
          setEmailError("Ocorreu um erro ao validar o email.");
        }
        return false;
      }
    };

    const validateTradeName = () => {
      if (tradename.length < 3) {
        setTradeNameError("A razão social deve ter pelo menos 3 caracteres.");
        return false;
      }
      setTradeNameError("");
      return true;
    };

    const validateFistel = () => {
      if (fistelList.length === 0) {
        setFistelError("Por favor, insira pelo menos um código de Fistel.");
        return false;
      }
      setFistelError("");
      return true;
    };

    const validateCpf = (cpf: string): boolean => {
      if (!validaCPF.isValid(cpf)) {
        setCpfError("Por favor, insira um CPF válido.");
        return false;
      }
      setCpfError("");
      return true;
    };

    const validateCnpj = (cnpj: string): boolean => {
      if (roleRegister.cnpj.isComponent) {
        if (cnpjList.length === 0 && !isUpdate) {
          setCnpjError("Por favor, insira pelo menos um CNPJ.");
          return false;
        }
        setCnpjError("");
        return true;
      } else {
        if (!validaCNPJ.isValid(cnpj) && !roleRegister.cnpj.isComponent) {
          setCnpjError("Por favor, insira um CNPJ válido.");
          return false;
        }
        setCnpjError("");
        return true;
      }
    };

    const validateCep = (cep: string): boolean => {
      // Removendo caracteres não numéricos e verificando o formato
      const sanitizedCep = cep.replace(/\D/g, "");
      const cepPattern = /^[0-9]{5}-?[0-9]{3}$/;

      if (!cepPattern.test(sanitizedCep)) {
        setCepError("Por favor, insira um CEP válido.");
        return false;
      }

      setCepError("");
      return true;
    };

    const validateNumber = (number: string): boolean => {
      if (number.length < 1 || isNaN(Number(number))) {
        setNumberError("O número da rua deve ser um número e deve ser informado.");
        return false;
      }
      setNumberError("");
      return true;
    };

    const validatePhone = (businessPhone: string, cellPhone: string): boolean => {
      const fullBusinessPhone = businessPhone ? `+55${businessPhone}` : businessPhone;
      const fullCellPhone = `+55${cellPhone}`;

      if (!cellPhone || !isValidNumber(fullCellPhone)) {
        setCellPhoneError("Por favor, insira um número de celular válido.");
        return false;
      }

      if (businessPhone && !isValidNumber(fullBusinessPhone)) {
        setBusinessPhoneError("Por favor, insira um telefone comercial válido.");
        return false;
      }

      setBusinessPhoneError("");
      setCellPhoneError("");

      return true;
    };

    const validateCellPhone = (cellPhone: string): boolean => {
      const fullCellPhone = `+55${cellPhone}`;

      if (!cellPhone || !isValidNumber(fullCellPhone)) {
        setCellPhoneError("Por favor, insira um número de celular válido.");
        return false;
      }

      setCellPhoneError("");
      return true;
    };

    const validateTerms = (termsAccepted: boolean): boolean => {
      if (!termsAccepted && !isChildRegister) {
        setTermsError("Você precisa aceitar os termos de uso.");
        return false;
      }

      setTermsError("");
      return true;
    };

    const handleReceiveNewsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setReceiveNews(e.target.checked);
    };

    const handleReceiveLicensingNews = (e: React.ChangeEvent<HTMLInputElement>) => {
      setReceiveLicensingNews(e.target.checked);
    };

    const handleAcceptTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setAcceptTerms(e.target.checked);
    };

    const handleAcceptAlertEmailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setAcceptAlertEmails(e.target.checked);
    };

    const handleTermsModal = (e: React.MouseEvent) => {
      e.preventDefault();
      setShowTermsModal(true);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      let errorCount = 0;
      if (!isFinishChild) {
        if (!validateRole()) {
          console.log("1. Campo obrigatório: Role");
          if (errorCount === 0 && roleRef.current !== null) roleRef.current.scrollIntoView({ behavior: "smooth" });
          errorCount++;
          setErrorCount(errorCount);
        }

        if (roleRegister.name.required && !validateName()) {
          console.log("2. Campo obrigatório: Name");
          if (errorCount === 0 && nameRef.current !== null) nameRef.current.scrollIntoView({ behavior: "smooth" });
          errorCount++;
          setErrorCount(errorCount);
        }

        if (roleRegister.lastname.required && !validateLastName()) {
          console.log("3. Campo obrigatório: LastName");
          if (errorCount === 0 && lastNameRef.current !== null)
            lastNameRef.current.scrollIntoView({ behavior: "smooth" });
          errorCount++;
          setErrorCount(errorCount);
        }

        if (roleRegister.gender.required && !validateGender()) {
          console.log("4. Campo obrigatório: Gender");
          if (errorCount === 0 && genderRef.current !== null) genderRef.current.scrollIntoView({ behavior: "smooth" });
          errorCount++;
          setErrorCount(errorCount);
        }

        if (roleRegister.email.required && !validateEmail()) {
          console.log("5. Campo obrigatório: Email");
          if (errorCount === 0 && emailRef.current !== null) emailRef.current.scrollIntoView({ behavior: "smooth" });
          errorCount++;
          setErrorCount(errorCount);
        }
      }

      /**
       * Apenas o superadmin pode selecionar fisteis
       */

      if (!isChildRegister && !isFinishChild && !isUpdateChild) {
        if (roleRegister.fistelList.required && !validateFistel()) {
          console.log("Campo obrigatório: Fistel");
          if (errorCount === 0 && fistelRef.current !== null) fistelRef.current.scrollIntoView({ behavior: "smooth" });
          errorCount++;
          setErrorCount(errorCount);
        }
      }

      if (!isChildRegister && !isFinishChild && !isUpdateChild) {
        if (tipoCadastro === "PJ") {
          if (roleRegister.tradename.required && !validateTradeName()) {
            console.log("6. Campo obrigatório: TradeName");
            if (errorCount === 0 && tradeNameRef.current !== null)
              tradeNameRef.current.scrollIntoView({ behavior: "smooth" });
            errorCount++;
            setErrorCount(errorCount);
          }
        }

        if (tipoCadastro === "PF") {
          if (roleRegister.cpf.required && !validateCpf(cpf)) {
            console.log("7. Campo obrigatório: CPF");
            if (errorCount === 0 && cpfRef.current !== null) cpfRef.current.scrollIntoView({ behavior: "smooth" });
            errorCount++;
            setErrorCount(errorCount);
          }
        }

        if (tipoCadastro === "PJ") {
          if (roleRegister.cnpj.required && !roleRegister.cnpj.isComponent && !validateCnpj(cnpj)) {
            console.log("8. Campo obrigatório: CNPJ");
            if (errorCount === 0 && cnpjRef.current !== null) cnpjRef.current.scrollIntoView({ behavior: "smooth" });
            errorCount++;
            console.log(roleRegister.cnpj.isComponent);
            setErrorCount(errorCount);
          } else if (roleRegister.cnpj.isComponent && !validateCnpj(cnpj)) {
            console.log("8. Campo obrigatório: CNPJ");
            if (errorCount === 0 && cnpjRef.current !== null) cnpjRef.current.scrollIntoView({ behavior: "smooth" });
            errorCount++;
            setErrorCount(errorCount);
          }
        }

        if (roleRegister.cep.required && !validateCep(cep)) {
          console.log("9. Campo obrigatório: CEP");
          if (errorCount === 0 && cepRef.current !== null) cepRef.current.scrollIntoView({ behavior: "smooth" });
          errorCount++;
          setErrorCount(errorCount);
        }

        if (roleRegister.number.required && !validateNumber(number)) {
          console.log("10. Campo obrigatório: Number");
          if (errorCount === 0 && numberRef.current !== null) numberRef.current.scrollIntoView({ behavior: "smooth" });
          errorCount++;
          setErrorCount(errorCount);
        }

        if (roleRegister.cellPhone.required && !validatePhone(businessPhone, cellPhone)) {
          console.log("11. Campo obrigatório: Phone");
          if (errorCount === 0 && cellPhoneRef.current !== null)
            cellPhoneRef.current.scrollIntoView({ behavior: "smooth" });
          errorCount++;
          setErrorCount(errorCount);
        }
      }

      if (!isChildRegister && isFinishChild) {
        if (roleRegister.cellPhone.required && !validateCellPhone(cellPhone)) {
          console.log("12. Campo obrigatório: CellPhone");
          if (errorCount === 0 && cellPhoneRef.current !== null)
            cellPhoneRef.current.scrollIntoView({ behavior: "smooth" });
          errorCount++;
          setErrorCount(errorCount);
        }
      }

      const [passwordSuccess, passwordMessage] = validatePassword(password, confirmPassword);
      if (roleRegister.password.required && !passwordSuccess && !isUpdate && !isUpdateChild && !isChildRegister) {
        console.log("13. Campo obrigatório: Password");
        if (errorCount === 0 && passwordRef.current !== null)
          passwordRef.current.scrollIntoView({ behavior: "smooth" });
        errorCount++;
        setErrorCount(errorCount);
        setPasswordError(passwordMessage);
      }

      if (roleRegister.acceptTerms && !validateTerms(acceptTerms) && !isUpdate && !isUpdateChild) {
        console.log("14. Campo obrigatório: Terms");
        if (errorCount === 0 && termsRef.current !== null) termsRef.current.scrollIntoView({ behavior: "smooth" });
        errorCount++;
        setErrorCount(errorCount);
      }

      if (errorCount > 0) {
        return;
      }

      setErrorCount(0);

      if (errorCount === 0) {
        if (!isUpdate && !isFinishChild) {
          const userRegister: TRegisterFields = {
            tipoCadastro,
            name,
            lastname,
            gender,
            email,
            tradename,
            businessname,
            cnpj,
            cpf,
            cep,
            street,
            number,
            complement,
            district,
            city,
            state,
            businessPhone,
            cellPhone,
            receiveNews,
            receiveLicensingNews,
            acceptAlertEmails,
            acceptTerms,
            acceptTermsDetails,
            password,
            confirmPassword,
            fistelList,
            cnpjList,
            role,
            parentID,
            isChildRegister,
            isFinishChild,
          };
          const objRegister = {
            userRegister,
            roleRegister,
          };
          dispatch(register(objRegister));
        } else if (isUpdate && !isFinishChild && !isUpdateChild) {
          const userUpdate: TProfile = {
            tipoCadastro,
            name,
            lastname,
            gender,
            email,
            tradename,
            businessname,
            cnpj,
            cpf,
            cep,
            street,
            number,
            complement,
            district,
            city,
            state,
            businessPhone,
            cellPhone,
            receiveNews,
            receiveLicensingNews,
            acceptAlertEmails,
          };
          const objUpdate = {
            userUpdate,
            roleUpdate: roleRegister,
          };
          dispatch(update(objUpdate));
        } else if (!isUpdate && isFinishChild && registerFinishId != null && isValidObjectId(registerFinishId)) {
          const userRegister: TFinishRegisterFields = {
            id: registerFinishId,
            cellPhone,
            receiveNews,
            acceptAlertEmails,
            acceptTerms,
            acceptTermsDetails,
            password,
            confirmPassword,
          };
          const objFinishRegister = {
            userRegister,
            roleRegister,
          };
          dispatch(finishRegister(objFinishRegister));
        } else if (isUpdate && isUpdateChild && !editChildUserByNotAdmin) {
          if (isValidObjectId(userData!._id || "")) {
            const dataUpdatedByAdmin: TProfileEmailUpdatedByAdmin = {
              id: userData!._id || "",
              role,
              name,
              lastname,
              gender,
              receiveLicensingNews,
              receiveNews,
              acceptAlertEmails,
            };
            const objUpdateByAdmin = {
              userUpdate: dataUpdatedByAdmin,
              roleUpdate: roleRegister,
            };
            dispatch(userUpdatedByAdmin(objUpdateByAdmin));
          }
        } else if (editChildUserByNotAdmin) {
          if (isValidObjectId(userData!._id || "")) {
            const dataUpdatedByNotAdmin: TProfileUpdatedByNotAdmin = {
              id: userData!._id || "",
              name,
              lastname,
              gender,
              receiveLicensingNews,
              receiveNews,
              acceptAlertEmails,
            };
            const objUpdateByNotAdmin = {
              userUpdate: dataUpdatedByNotAdmin,
              roleUpdate: roleRegister,
            };
            dispatch(userUpdatedByNotAdmin(objUpdateByNotAdmin));
          }
        }
      }
    };

    const handlePhoneChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      setPhoneFunction: React.Dispatch<React.SetStateAction<string>>
    ) => {
      let value = e.target.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
      value = value.replace(/(\d)(\d{4})$/, "$1-$2");
      setPhoneFunction(value);
    };

    const cepMask = (value: string) => {
      if (!value) return "";
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{5})(\d)/, "$1-$2");
      return value;
    };

    const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let cepValue = e.target.value;
      cepValue = cepMask(cepValue); // Aplicar a máscara no CEP
      setCep(cepValue);

      if (cepValue.length === 9) {
        const cepValido: string = cepValue.replace("-", "");
        setCepErrorMessage("");
        dispatch(consultaCEP(cepValido));
      }
    };

    const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>, isCnpj: boolean) => {
      let value = e.target.value.replace(/\D/g, "");

      if (isCnpj) {
        value = value.replace(/^(\d{2})(\d)/, "$1.$2");
        value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
        value = value.replace(/(\d{4})(\d)/, "$1-$2");
        setCnpj(value);
      } else {
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        setCpf(value);
      }
    };

    //success to update profile
    useEffect(() => {
      if (profileFormSuccess) {
        if (setUpdateProfile) setUpdateProfile(false);
        if (setEditChildUserByAdmin) setEditChildUserByAdmin(false);
      }
    }, [profileFormSuccess, setUpdateProfile, dispatch, setEditChildUserByAdmin]);

    //show error message

    useEffect(() => {
      if (registerError && registerMessage.errors !== undefined && registerMessage.errors.length > 0) {
        const setError = (field: string, message: string) => {
          switch (field) {
            case "name":
              setNameError(message);
              if (errorCount === 0 && nameRef.current !== null) nameRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "lastName":
              setLastNameError(message);
              if (errorCount === 0 && lastNameRef.current !== null)
                lastNameRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "gender":
              setGenderError(message);
              if (errorCount === 0 && genderRef.current !== null)
                genderRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "email":
              setEmailError(message);
              if (errorCount === 0 && emailRef.current !== null)
                emailRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "tradename":
              setTradeNameError(message);
              if (errorCount === 0 && tradeNameRef.current !== null)
                tradeNameRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "cnpj":
              setCnpjError(message);
              if (errorCount === 0 && cnpjRef.current !== null) cnpjRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "cpf":
              setCpfError(message);
              if (errorCount === 0 && cpfRef.current !== null) cpfRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "cep":
              setCepError(message);
              if (errorCount === 0 && cepRef.current !== null) cepRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "number":
              setNumberError(message);
              if (errorCount === 0 && numberRef.current !== null)
                numberRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "cellPhone":
              setCellPhoneError(message);
              if (errorCount === 0 && cellPhoneRef.current !== null)
                cellPhoneRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "businessPhone":
              setBusinessPhoneError(message);
              break;
            case "password":
              setPasswordError(message);
              if (errorCount === 0 && passwordRef.current !== null)
                passwordRef.current.scrollIntoView({ behavior: "smooth" });
              break;
            case "terms":
              setTermsError(message);
              if (errorCount === 0 && termsRef.current !== null)
                termsRef.current.scrollIntoView({ behavior: "smooth" });
              break;
          }
        };

        const clearAllErrors = () => {
          setNameError("");
          setLastNameError("");
          setGenderError("");
          setEmailError("");
          setTradeNameError("");
          setCnpjError("");
          setCpfError("");
          setCepError("");
          setNumberError("");
          setCellPhoneError("");
          setBusinessPhoneError("");
          setPasswordError("");
          setCepErrorMessage("");
          setTermsError("");
          setErrorCount(0);
        };

        clearAllErrors();
        registerMessage.errors.forEach((error) => {
          setErrorCount((err) => err + 1);
          setError(error.field, error.message);
        });
      }
    }, [registerError, registerMessage, dispatch, errorCount]);

    useEffect(() => {
      if (setUpdateProfile) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, [setUpdateProfile]);

    useEffect(() => {
      if (cepsuccess && cepData) {
        setStreet(cepData.logradouro);
        setDistrict(cepData.bairro);
        setCity(cepData.localidade);
        setState(cepData.uf);
        dispatch(resetCep());
      }
      if (ceperror) {
        setCep(""); // Limpar o CEP
        setCepErrorMessage(cepmessage);
        dispatch(resetCep());
      }
    }, [dispatch, cepsuccess, ceperror, cepData, cepmessage]);

    useEffect(() => {
      if (isFinishChild && tokenConfirm) {
        dispatch(finishRegisterToken(tokenConfirm));
      }
    }, [isFinishChild, tokenConfirm, dispatch]);

    //show success message and clean all auth states
    useEffect(() => {
      if (
        registerSuccess &&
        !(
          registerFinishEmail !== "" &&
          registerFinishId !== "" &&
          registerFinishEmail !== null &&
          registerFinishId !== null
        )
      ) {
        setRegistrationCompleted(true);
        if (registrationCompletedRef.current !== null)
          registrationCompletedRef.current.scrollIntoView({ behavior: "smooth" });
        dispatch(registerReset());
      } else if (registerFinishEmail) {
        setEmail(registerFinishEmail);
      }
      return;
    }, [dispatch, registerSuccess, navigate, errorCount, registerFinishId, registerFinishEmail]);

    if (registerLoading) return <div>Carregando...</div>;

    return (
      <>
        <div className="flex w-full h-full" ref={registrationCompletedRef}>
          {registrationCompleted || registerFinishSuccess ? (
            <>
              {registrationCompleted && (
                <>
                  <div className="flex min-h-full flex-1 flex-col justify-center md:justify-start px-6 py-2 md:px-2">
                    <img
                      className="mx-auto h-16 w-auto"
                      src={`${urlapp}/img/logo-antena-top-digital-agi.png`}
                      alt="Top Digital"
                    />
                    <Confirmation
                      email={email}
                      gender={gender}
                      name={name}
                      isChildRegister={isChildRegister}
                      setRegisterChildUser={setRegisterChildUser}
                    />
                  </div>
                  <div className="hidden py-2 lg:flex min-h-full mt-28 w-1/2 items-start justify-center">
                    <img src="/img/img-top-cadastro-1536x1536.png" alt="Um produto Top Digital" className="h-auto" />
                  </div>
                </>
              )}
              {registerFinishSuccess && (
                <>
                  <div className="flex min-h-full flex-1 flex-col justify-center md:justify-start px-6 py-2 md:px-2">
                    <img
                      className="mx-auto h-16 w-auto"
                      src={`${urlapp}/img/logo-antena-top-digital-agi.png`}
                      alt="Top Digital"
                    />
                    <ConfirmationFinishChild />
                  </div>
                  <div className="hidden py-2 lg:flex min-h-full mt-28 w-1/2 items-start justify-center">
                    <img src="/img/img-top-cadastro-1536x1536.png" alt="Um produto Top Digital" className="h-auto" />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="flex flex-col min-h-full px-4 py-2 md:px-6">
                <div className="md:hidden flex justify-center items-center w-full">
                  <div className=" w-2/3">
                    <div className="mb-2 bg-top-digital-warning-light rounded-xl pt-4 pb-8 px-4">
                      <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-xl">
                        Pronto para Agilizar?
                      </h2>
                      <h3 className="text-base font-bold tracking-tight text-gray-900 sm:text-lg justify-start">
                        <span>Inicie seu teste grátis</span>
                        <span className="hidden md:inline"> agora</span>
                      </h3>
                      <div className="text-top-digital-content-color">
                        <p className="font-semibold md:text-sm text-base">20 dias grátis para conhecer o Agiliza.</p>
                        <p className="md:text-smp font-medium text-sm md:mt-2 text-justify h-4">
                          Descubra como o Agiliza pode transformar o seu negócio. Sem cartão de crédito, sem
                          complicações.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                  <h2 className="mt-4 text-center text-xl font-bold leading-9 tracking-tight text-top-digital-content-color md:text-left">
                    {(isUpdate && !isUpdateChild) || editChildUserByNotAdmin
                      ? "Meu Perfil"
                      : isUpdateChild
                      ? "Editar do Usuário Adicional"
                      : isChildRegister
                      ? "Cadastro de Usuário Adicional"
                      : isFinishChild
                      ? "Finalize seu Cadastro"
                      : "Registre-se grátis no Agiliza"}
                  </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                  <form className="space-y-6" onSubmit={handleSubmit}>
                    {errorCount > 0 && (
                      <div className="text-red-600 text-sm">
                        Existe {errorCount} pendência{errorCount > 1 ? "s" : ""} para serem corrigidas.
                      </div>
                    )}
                    {!isChildRegister && !isFinishChild && !isUpdateChild && (
                      <>
                        {!roleRegister.cnpj.isComponent && roleRegister.tipoCadastro.isVisible && (
                          <div className="select-type">
                            <label className="text-sm font-semibold leading-6 top-digital-content-color mr-1">
                              Tipo de Cadastro:
                            </label>
                            {isUpdate ? (
                              <label className="text-sm leading-6 top-digital-content-color">
                                {tipoCadastro === "PJ" ? "Pessoa Jurídica" : "Pessoa Física"}
                              </label>
                            ) : (
                              <>
                                <label className="block text-sm font-normal leading-6 top-digital-content-color">
                                  <input
                                    type="radio"
                                    value="juridica"
                                    checked={tipoCadastro === "PJ"}
                                    onChange={() => setTipoCadastro(TipoCadastro.PJ)}
                                    className="customInputRadio"
                                  />{" "}
                                  Pessoa Jurídica
                                </label>
                                <label className="block text-sm font-normal leading-6 top-digital-content-color">
                                  <input
                                    type="radio"
                                    value="fisica"
                                    checked={tipoCadastro === "PF"}
                                    onChange={() => setTipoCadastro(TipoCadastro.PF)}
                                    className="customInputRadio"
                                  />{" "}
                                  Pessoa Física
                                </label>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {(isChildRegister || (decodedToken.role === "superadmin" && decodedToken.id !== userData?._id)) && (
                      <div ref={roleRef}>
                        <label
                          htmlFor="role"
                          className="block text-sm font-semibold leading-6 top-digital-content-color"
                        >
                          Nível de cadastro *
                        </label>
                        <select
                          value={role}
                          id="role"
                          onChange={(e) => setRole(e.target.value as Role)}
                          className="customSelect h-8"
                        >
                          <option value="">Selecionar</option>
                          <option value="admin">Administrador</option>
                          <option value="regular">Regular</option>
                          <option value="visualizador">Visualizador</option>
                        </select>
                        {roleError && roleError !== "" && <div className="text-red-600 text-sm">{roleError}</div>}
                      </div>
                    )}
                    {!isFinishChild && (
                      <>
                        {roleRegister.name.isVisible && (
                          <div ref={nameRef}>
                            <label
                              htmlFor="name"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Nome {roleRegister.name.required ? "*" : ""}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="Seu nome"
                                name="name"
                                value={name || ""}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                                onBlur={validateName}
                                id="name"
                                autoComplete="name"
                                className="customInputTextRegister h-8 w-2/3"
                              />
                            </div>
                            {nameError && nameError !== "" && <div className="text-red-600 text-sm">{nameError}</div>}
                          </div>
                        )}
                        {roleRegister.lastname.isVisible && (
                          <div ref={lastNameRef}>
                            <label
                              htmlFor="lastname"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Sobrenome {roleRegister.lastname.required ? "*" : ""}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="Seu sobrenome"
                                name="lastname"
                                value={lastname || ""}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                }}
                                onBlur={validateLastName}
                                id="lastname"
                                autoComplete="lastname"
                                className="customInputTextRegister h-8 w-2/3"
                              />
                            </div>
                            {lastNameError && lastNameError !== "" && (
                              <div className="text-red-600 text-sm">{lastNameError}</div>
                            )}
                          </div>
                        )}
                        {roleRegister.gender.isVisible && (
                          <div ref={genderRef}>
                            <label
                              htmlFor="gender"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Gênero {roleRegister.gender.required ? "*" : ""}
                            </label>
                            <select
                              value={gender}
                              id="gender"
                              onChange={(e) => setGender(e.target.value as Gender)}
                              className="customSelect h-8"
                            >
                              <option value="">Selecionar</option>
                              <option value="male">Masculino</option>
                              <option value="female">Feminino</option>
                              <option value="other">Outro</option>
                            </select>
                            {genderError && genderError !== "" && (
                              <div className="text-red-600 text-sm">{genderError}</div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {roleRegister.email.isVisible && (
                      <div ref={emailRef}>
                        {isUpdate || isFinishChild ? (
                          <>
                            <label className="inline text-sm font-semibold leading-6 top-digital-content-color mr-1">
                              E-mail:
                            </label>
                            <label className="inline text-sm leading-6 top-digital-content-color">{email}</label>
                          </>
                        ) : (
                          <>
                            <label
                              htmlFor="email"
                              className="inline text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              E-mail {roleRegister.email.required ? "*" : ""}
                            </label>
                            <label
                              htmlFor="email"
                              className="ml-1 inline text-sm font-normal leading-6 top-digital-content-color"
                            >
                              Insira um e-mail válido para a checagem e confirmação do cadastro
                            </label>
                            <div className="mt-2">
                              <input
                                type="email"
                                name="email"
                                value={email || ""}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                                onBlur={validateEmail}
                                placeholder="Seu e-mail"
                                id="email"
                                autoComplete="email"
                                className="customInputTextRegister h-8 w-2/3"
                              />
                            </div>
                            {emailError && emailError !== "" && (
                              <div className="text-red-600 text-sm">{emailError}</div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    {!isChildRegister && !isFinishChild && !isUpdateChild && (
                      <>
                        {tipoCadastro === "PJ" ? (
                          <>
                            {roleRegister.tradename.isVisible && (
                              <div ref={tradeNameRef}>
                                <label
                                  htmlFor="tradename"
                                  className="block text-sm font-semibold leading-6 top-digital-content-color"
                                >
                                  Razão Social {roleRegister.tradename.required ? "*" : ""}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    name="tradename"
                                    value={tradename || ""}
                                    onChange={(e) => {
                                      setTradeName(e.target.value);
                                    }}
                                    onBlur={validateTradeName}
                                    placeholder="Razão Social da Empresa"
                                    id="tradename"
                                    autoComplete="tradename"
                                    className="customInputTextRegister h-8 w-2/3"
                                  />
                                </div>
                                {tradeNameError && tradeNameError !== "" && (
                                  <div className="text-red-600 text-sm">{tradeNameError}</div>
                                )}
                              </div>
                            )}
                            {roleRegister.businessname.isVisible && (
                              <div>
                                <label
                                  htmlFor="businessname"
                                  className="block text-sm font-semibold leading-6 top-digital-content-color"
                                >
                                  Nome de Fantasia {roleRegister.businessname.required ? "*" : ""}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    name="businessname"
                                    value={businessname || ""}
                                    onChange={(e) => setBusinessname(e.target.value)}
                                    placeholder="Nome de Fantasia da Empresa"
                                    id="businessname"
                                    autoComplete="businessname"
                                    className="customInputTextRegister h-8 w-2/3"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <input type="hidden" name="tradename" value="" />
                            <input type="hidden" name="businessname" value="" />
                          </>
                        )}
                        {tipoCadastro === "PJ" ? (
                          <>
                            {roleRegister.cnpj.isVisible && (
                              <div ref={cnpjRef}>
                                {isUpdate ? (
                                  <>
                                    {roleRegister.cnpj.isVisible && !roleRegister.cnpj.isComponent && (
                                      <>
                                        <label className="inline text-sm font-semibold leading-6 top-digital-content-color mr-1">
                                          CNPJ:
                                        </label>
                                        <label className="inline text-sm leading-6 top-digital-content-color">
                                          {cnpj}
                                        </label>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <label
                                      htmlFor="cnpj"
                                      className="block text-sm font-semibold leading-6 top-digital-content-color"
                                    >
                                      CNPJ {roleRegister.cnpj.required ? "*" : ""}{" "}
                                      {roleRegister.cnpj.isComponent && (
                                        <span className="font-normal">
                                          adicione CNPJs clicando em <Plus className="inline w-4 h-4" />.
                                        </span>
                                      )}
                                    </label>
                                    {roleRegister.cnpj.isComponent ? (
                                      <CNPJSelection
                                        cnpjList={cnpjList}
                                        setCnpjList={setCnpjList}
                                        setCnpjError={setCnpjError}
                                      />
                                    ) : (
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="cnpj"
                                          value={cnpj || ""}
                                          onChange={(e) => {
                                            handleDocumentChange(e, true);
                                          }}
                                          onBlur={() => validateCnpj(cnpj)}
                                          placeholder="Seu CNPJ"
                                          id="cnpj"
                                          autoComplete="cnpj"
                                          className="customInputTextRegister h-8 w-1/2"
                                        />
                                      </div>
                                    )}
                                    {cnpjError && cnpjError !== "" && (
                                      <div className="text-red-600 text-sm">{cnpjError}</div>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {roleRegister.cpf.isVisible && (
                              <>
                                {isUpdate ? (
                                  <>
                                    <label className="inline text-sm font-semibold leading-6 top-digital-content-color mr-1">
                                      CPF:
                                    </label>
                                    <label className="inline text-sm leading-6 top-digital-content-color">{cnpj}</label>
                                  </>
                                ) : (
                                  <div ref={cpfRef}>
                                    <label
                                      htmlFor="cpf"
                                      className="block text-sm font-semibold leading-6 top-digital-content-color"
                                    >
                                      CPF {roleRegister.cpf.required ? "*" : ""}
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="cpf"
                                        value={cpf || ""}
                                        onChange={(e) => handleDocumentChange(e, false)}
                                        onBlur={() => validateCpf(cpf)}
                                        placeholder="Seu CPF"
                                        id="cpf"
                                        autoComplete="cpf"
                                        className="customInputTextRegister h-8 w-1/3"
                                      />
                                    </div>
                                    {cpfError && cpfError !== "" && (
                                      <div className="text-red-600 text-sm">{cpfError}</div>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {roleRegister.fistelList.isVisible && (
                          <div ref={fistelRef}>
                            <FistelSelection cnpj={cnpj} />
                            {fistelError && fistelError !== "" && (
                              <div className="text-red-600 text-sm">{fistelError}</div>
                            )}
                          </div>
                        )}
                        {roleRegister.cep.isVisible && (
                          <div ref={cepRef}>
                            <label
                              htmlFor="cep"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              CEP {roleRegister.cep.required ? "*" : ""}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="Digite o CEP"
                                name="cep"
                                value={cep || ""}
                                onChange={(e) => {
                                  handleCepChange(e);
                                }}
                                onBlur={() => validateCep(cep)}
                                id="cep"
                                className="customInputTextRegister h-8 w-1/4"
                              />
                              {cepErrorMessage && cepErrorMessage !== "" && (
                                <div className="text-red-600 text-sm">{cepErrorMessage}</div>
                              )}
                              {cepError && cepError !== "" && <div className="text-red-600 text-sm">{cepError}</div>}
                            </div>
                          </div>
                        )}
                        {roleRegister.street.isVisible && (
                          <div>
                            <label
                              htmlFor="street"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Rua {roleRegister.street.required ? "*" : ""}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="Rua"
                                name="street"
                                value={street || ""}
                                id="street"
                                readOnly
                                className="customInputTextRegister h-8 w-2/3"
                              />
                            </div>
                          </div>
                        )}
                        {roleRegister.number.isVisible && (
                          <div ref={numberRef}>
                            <label
                              htmlFor="number"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Número {roleRegister.number.required ? "*" : ""}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="Número"
                                name="number"
                                value={number || ""}
                                onChange={(e) => setNumber(e.target.value)}
                                onBlur={() => validateNumber(number)}
                                id="number"
                                className="customInputTextRegister h-8 w-1/4"
                              />
                            </div>
                            {numberError && numberError !== "" && (
                              <div className="text-red-600 text-sm">{numberError}</div>
                            )}
                          </div>
                        )}
                        {roleRegister.complement.isVisible && (
                          <div>
                            <label
                              htmlFor="complement"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Complemento
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="Complemento"
                                name="complement"
                                value={complement || ""}
                                onChange={(e) => setComplement(e.target.value)}
                                id="complement"
                                className="customInputTextRegister h-8 w-1/2"
                              />
                            </div>
                          </div>
                        )}
                        {roleRegister.district.isVisible && (
                          <div>
                            <label
                              htmlFor="district"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Bairro
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="Bairro"
                                name="district"
                                value={district || ""}
                                id="district"
                                readOnly
                                className="customInputTextRegister h-8 w-2/3"
                              />
                            </div>
                          </div>
                        )}
                        {roleRegister.city.isVisible && (
                          <div>
                            <label
                              htmlFor="city"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Cidade {roleRegister.city.required ? "*" : ""}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="Cidade"
                                name="city"
                                value={city || ""}
                                id="city"
                                readOnly
                                className="customInputTextRegister h-8 w-2/3"
                              />
                            </div>
                          </div>
                        )}
                        {roleRegister.state.isVisible && (
                          <div>
                            <label
                              htmlFor="state"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Estado {roleRegister.state.required ? "*" : ""}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="Estado"
                                name="state"
                                value={state || ""}
                                id="state"
                                readOnly
                                className="customInputTextRegister h-8 w-1/3"
                              />
                            </div>
                          </div>
                        )}
                        {roleRegister.businessPhone.isVisible && (
                          <div ref={businessPhoneRef}>
                            <label
                              htmlFor="businessPhone"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Telefone Comercial
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="(00) 0000-0000"
                                name="businessPhone"
                                value={businessPhone || ""}
                                onChange={(e) => handlePhoneChange(e, setBusinessPhone)}
                                onBlur={() => validatePhone(businessPhone, cellPhone)}
                                id="businessPhone"
                                className="customInputTextRegister h-8 w-2/3"
                              />
                            </div>
                            {businessPhoneError && businessPhoneError !== "" && (
                              <div className="text-red-600 text-sm">{businessPhoneError}</div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {!isChildRegister && !isUpdateChild && (
                      <>
                        {roleRegister.cellPhone.isVisible && (
                          <div ref={cellPhoneRef}>
                            <label
                              htmlFor="cellPhone"
                              className="block text-sm font-semibold leading-6 top-digital-content-color"
                            >
                              Telefone Celular {roleRegister.cellPhone.required ? "*" : ""}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="(00) 00000-0000"
                                name="cellPhone"
                                value={cellPhone || ""}
                                onChange={(e) => handlePhoneChange(e, setCellPhone)}
                                onBlur={() => validatePhone(businessPhone, cellPhone)}
                                id="cellPhone"
                                className="customInputTextRegister h-8 w-2/3"
                              />
                            </div>
                            {cellPhoneError && cellPhoneError !== "" && (
                              <div className="text-red-600 text-sm">{cellPhoneError}</div>
                            )}
                          </div>
                        )}
                      </>
                    )}

                    {!isUpdate && !isChildRegister && (
                      <>
                        <div ref={passwordRef}>
                          <label
                            htmlFor="password"
                            className="inline text-sm font-semibold leading-6 top-digital-content-color"
                          >
                            Senha {roleRegister.password.required ? "*" : ""}
                          </label>
                          <label
                            htmlFor="password"
                            className="ml-1 inline text-sm font-normal leading-6 top-digital-content-color"
                          >
                            A senha precisa conter ao menos 8 caractéres com pelo menos uma letra maiúscula e um número
                          </label>
                          <div className="mt-2">
                            <input
                              id="password"
                              name="password"
                              autoComplete="current-password"
                              type="password"
                              placeholder="Sua Senha"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password || ""}
                              className="customInputTextRegister h-8 w-1/2"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="confirmPassword"
                            className="block text-sm font-semibold leading-6 top-digital-content-color"
                          >
                            Confirme a Senha *
                          </label>
                          <div className="mt-2">
                            <input
                              type="password"
                              placeholder="Confirme a Senha"
                              name="confirmPassword"
                              value={confirmPassword || ""}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              id="confirmPassword"
                              autoComplete="current-password"
                              className="customInputTextRegister h-8 w-1/2"
                            />
                          </div>
                          {passwordError && passwordError !== "" && (
                            <div className="text-red-600 text-sm">{passwordError}</div>
                          )}
                        </div>
                      </>
                    )}
                    {(isChildRegister || isUpdate) && (
                      <>
                        {roleRegister.receiveLicensingNews.isVisible && (
                          <div>
                            <label
                              htmlFor="receiveLicensingNews"
                              className="block text-sm font-normal leading-6 top-digital-content-color"
                            >
                              <input
                                type="checkbox"
                                checked={receiveLicensingNews}
                                onChange={handleReceiveLicensingNews}
                                className="customInputCheckbox mr-2"
                                disabled={editChildUserByNotAdmin || false}
                              />
                              Aceito receber informações sobre os processos de licenciamento Anatel ligados a empresa
                            </label>
                          </div>
                        )}
                      </>
                    )}
                    {(isChildRegister || isUpdate) && (
                      <>
                        {roleRegister.receiveNews.isVisible && (
                          <div>
                            <label
                              htmlFor="receiveNews"
                              className="block text-sm font-normal leading-6 top-digital-content-color"
                            >
                              <input
                                type="checkbox"
                                checked={receiveNews}
                                onChange={handleReceiveNewsChange}
                                className="customInputCheckbox mr-2"
                              />
                              Aceito receber novidades sobre o Agiliza e fatos relevantes da Anatel
                            </label>
                          </div>
                        )}
                        {roleRegister.acceptAlertEmails.isVisible && (
                          <div>
                            <label
                              htmlFor="acceptAlertEmails"
                              className="block text-sm font-normal leading-6 top-digital-content-color"
                            >
                              <input
                                type="checkbox"
                                checked={acceptAlertEmails}
                                onChange={handleAcceptAlertEmailsChange}
                                className="customInputCheckbox mr-2"
                              />
                              Aceito receber emails informando sobre novos boletos Anatel e o status dos atuais
                            </label>
                          </div>
                        )}
                      </>
                    )}
                    {!isUpdate && !isChildRegister && (
                      <>
                        {roleRegister.acceptTerms.isVisible && (
                          <div ref={termsRef}>
                            <label
                              htmlFor="acceptTerms"
                              className="block text-sm font-normal leading-6 top-digital-content-color"
                            >
                              <input
                                type="checkbox"
                                checked={acceptTerms}
                                onChange={handleAcceptTermsChange}
                                className="customInputCheckbox mr-2"
                              />
                              Aceito os{" "}
                              <button
                                onClick={handleTermsModal}
                                className="cursor-pointer hover:text-top-digital-buttom-hover font-semibold"
                              >
                                termos de uso
                              </button>{" "}
                              para utilização do Agiliza
                            </label>
                            {termsError && termsError !== "" && (
                              <div className="text-red-600 text-sm">{termsError}</div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    <div className={`${isUpdate ? "pb-8" : "pb-0"}`}>
                      {!registerLoading && (
                        <>
                          {isUpdate ? (
                            <div className="flex gap-2">
                              <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                              >
                                Atualizar Perfil
                              </button>
                              {setUpdateProfile && (
                                <button
                                  onClick={() => setUpdateProfile(false)}
                                  className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                >
                                  Cancelar
                                </button>
                              )}
                              {setEditChildUserByAdmin && (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEditChildUserByAdmin(false);
                                  }}
                                  className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                >
                                  Cancelar
                                </button>
                              )}
                            </div>
                          ) : (
                            <>
                              {isChildRegister ? (
                                <div className="flex gap-2">
                                  <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                  >
                                    Enviar Solicitação
                                  </button>
                                  <button
                                    onClick={() => setRegisterChildUser && setRegisterChildUser(false)}
                                    className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                  >
                                    Cancelar
                                  </button>
                                </div>
                              ) : (
                                <button
                                  type="submit"
                                  className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                >
                                  Cadastrar
                                </button>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {registerLoading && (
                        <button
                          type="submit"
                          className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-75"
                        >
                          Aguarde....
                        </button>
                      )}
                    </div>
                    {showTermsModal && !isUpdate && !isChildRegister && (
                      <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center p-20">
                        <div className="modal-content bg-white rounded-lg shadow-xl overflow-auto max-h-full w-full m-auto p-6">
                          <span
                            className="close absolute top-0 right-0 m-4 text-xl cursor-pointer text-gray-900"
                            onClick={() => setShowTermsModal(false)}
                          >
                            &times;
                          </span>

                          <div className="flex justify-center">
                            <iframe
                              className="w-full h-96 md:h-[32rem] border-0"
                              src="https://docs.google.com/document/d/157y9BhhjLiKrkfJGKcWmIaF1rP3Q_HDdfMakrILZJRU/edit?usp=sharing"
                              allowFullScreen
                              title="Document Preview"
                            ></iframe>
                          </div>

                          <button
                            className="mt-4 px-4 py-2 bg-blue-500 text-white text-sm font-bold rounded hover:bg-blue-700 focus:outline-none"
                            onClick={() => setShowTermsModal(false)}
                          >
                            Fechar
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                  {!isUpdate && !isChildRegister && (
                    <p className="mt-10 text-center text-sm text-gray-500">
                      Já tem conta?{" "}
                      <Link
                        to="/login"
                        className="font-semibold leading-6 text-top-digital-content-color hover:text-top-digital-link-hover"
                      >
                        Acesse aqui
                      </Link>
                    </p>
                  )}
                </div>
              </div>
              <ImageOffLine register={true} />
            </>
          )}
        </div>
      </>
    );
  }
);

export default Register;
