//componnents
import { useNavigate } from "react-router-dom";

//Hooks
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

import { registerSelector, registerFinishReset } from "../../slices/RegisterSlice";
import { useEffect } from "react";

const ConfirmationFinishChild = () => {
  const { registerLoading, registerFinishSuccess } = useAppSelector(registerSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (registerFinishSuccess) {
      timerId = setTimeout(() => {
        navigate("/login");
        dispatch(registerFinishReset());
      }, 5000);
      // Retorna uma função de limpeza que será chamada quando o componente for desmontado
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [dispatch, registerFinishSuccess, navigate]);

  if (registerLoading) {
    return <p>Carregando...</p>;
  }

  return (
    <div className="flex w-full">
      {registerFinishSuccess && (
        <div className="flex justify-center items-start h-screen mt-10">
          <div className="w-4/5">
            <p className="text-sm text-justify">
              E-mail confirmado com sucesso. Você já pode acessar o sistema. Você será redirecionado para a página de
              login em 5 segundos.....
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmationFinishChild;
