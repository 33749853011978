const formatNumberToBRL = (number: any): string => {
  // Verifica se o número não é um NaN ou undefined
  if (isNaN(number) || typeof number === "undefined" || number === null) {
    number = 0;
  }

  // Formata o número para o formato de moeda brasileira (BRL)
  const formattedNumber = number.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });

  return formattedNumber;
};

const formatToNumber = (value: any): number => {
  // Se o valor é nulo, indefinido ou uma string vazia, retorna 0
  if (value === null || value === undefined) {
    return 0;
  }

  // Se o valor é do tipo número, retorna o valor
  if (typeof value === "number") {
    return value;
  }

  // Se for uma string, remove espaços em branco e substitui vírgulas por pontos para lidar com decimais
  if (typeof value === "string") {
    const numericString = value.trim();

    if (numericString === "") {
      return 0;
    }

    // Tenta converter a string limpa para número
    const number = parseFloat(numericString);

    // Retorna o número se for um número válido, senão retorna 0
    return isNaN(number) ? 0 : number;
  }

  // Para qualquer outro tipo de dado que não seja string ou número, retorna 0
  return 0;
};

const formatNumbers = {
  formatNumberToBRL,
  formatToNumber,
};

export default formatNumbers;
