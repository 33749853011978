import { JSONTree } from "react-json-tree";

const theme = {
  scheme: "monokai",
  base00: "rgba(219, 231, 232, 0)",
  base01: "#383830",
  base02: "#49483e",
  base03: "#75715e",
  base04: "#a59f85",
  base05: "#f8f8f2",
  base06: "#212B36",
  base07: "#212B36",
  base08: "#212B36",
  base09: "#212B36",
  base0A: "#212B36",
  base0B: "#212B36",
  base0C: "#212B36",
  base0D: "#212B36",
  base0E: "#212B36",
  base0F: "#212B36",
};

type Props = {
  data: Object | null;
  widthValue: string;
};

const JSONTreeField = ({ data, widthValue }: Props) => {
  return (
    <div className={`overflow-auto relative w-[${widthValue}px] max-h-36 h-32 notification -ml-4 -mt-2`}>
      <div className="absolute top-0 left-0 w-full h-full">
        <JSONTree
          data={data}
          shouldExpandNodeInitially={(keyPath, data, level) => false}
          theme={theme}
          invertTheme={false}
          hideRoot={true}
        />
      </div>
    </div>
  );
};

export default JSONTreeField;
