import { api, requestConfig } from "../utils/config";

const getUserNotifications = async (token: string | undefined) => {
  const config = requestConfig("GET", null, token);

  try {
    const res = await fetch(api + "/notification/user", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.error(error);
  }
};

const getAllNotifications = async (filterObj: any, token: string | undefined) => {
  const config = requestConfig("GET", null, token);
  let queryParams;
  if (filterObj.hasOwnProperty("id")) {
    // 'filterObj' contém a chave 'id'
    queryParams = new URLSearchParams(filterObj).toString();
    // Adicione aqui sua lógica para quando 'filterObj' contém 'id'
  } else {
    // 'filterObj' não contém a chave 'id'
    queryParams = null;
    // Adicione aqui sua lógica para quando 'filterObj' não contém 'id'
  }

  try {
    const res = await fetch(`${api}/notification${queryParams ? "/?" + queryParams : ""}`, config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.error(error);
  }
};

const setNotificationsRead = async (data: any, token: string | undefined) => {
  const config = requestConfig("PUT", data, token);

  try {
    const res = await fetch(api + "/notification", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

// const getReportDocument = async (objFilter: any, token: string | undefined) => {
//   const config = requestConfig("GET", null, token);
//   const queryParams = new URLSearchParams(objFilter).toString();
//   try {
//     //const res = await fetch(api + "/stations/" + id, config)
//     const url = `${api}/documents/reportdocuments/?${queryParams}`;

//     const res = await fetch(url, config)
//       .then((res) => res.json())
//       .catch((err) => err);

//     return res;
//   } catch (error) {
//     console.error(error);
//   }
// };

const NotificationService = {
  getAllNotifications,
  getUserNotifications,
  setNotificationsRead,
};

export default NotificationService;
