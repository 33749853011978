type Props = {
  power: string; // Frequência em MHz
};

const PowerFormatter = ({ power }: Props) => {
  if (power === "-") {
    return <>-</>;
  }

  // Converte a string para um número
  const powerNum = parseFloat(power);

  // Verifica se a conversão foi bem-sucedida antes de prosseguir
  if (isNaN(powerNum)) {
    return <span>Valor inválido</span>;
  }

  const formattedPower =
    powerNum.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " W";
  return <span>{formattedPower}</span>;
};

export default PowerFormatter;
