const RecoveryError = () => {
  return (
    <div className="flex justify-center items-start h-screen mt-10">
      <div className="w-4/5">
        <h1 className="text-center text-lg font-semibold mb-4">Recuperação de Senha</h1>
        <h2 className="text-center text-md mb-4 font-semibold">Token Expirado ou Inválido</h2>
        <p className="text-sm text-justify">
          <br />
          Parece que o token para recuperação de senha expirou ou é inválido. Por favor, tente novamente clicando{" "}
          <a href="/forgot" className="font-semibold hover:text-top-digital-link-hover hover:underline">
            aqui
          </a>{" "}
          para criar uma nova senha. Você terá 24 horas para concluir o processo.
          <br />É sempre bom verificar a caixa de spam caso o email não tenha chegado. Se ainda tiver problemas, entre
          em contato com nosso suporte através do email{" "}
          <label>
            <a
              href="mailto:suporte@seudominio.com.br"
              className="font-semibold hover:text-top-digital-link-hover hover:underline"
            >
              suporte@seudominio.com.br
            </a>
          </label>
          , informando no assunto o email utilizado na recuperação e no corpo do email apenas informe que o email de
          recuperação não chegou. Estaremos à disposição para ajudá-lo a recuperar sua senha.
        </p>
      </div>
    </div>
  );
};

export default RecoveryError;
