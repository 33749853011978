import React from "react";
import { IFieldConfig, TStation } from "../../Interfaces/IStation";
import { TConfigStation } from "../../Interfaces/IConfigStation";
import formatCoordinates from "../../utils/formatCoordinates";
import formatCNPJ from "../../utils/formatCNPJ";
import { Link } from "react-router-dom";
import { File } from "lucide-react";

//context
import { useGlobalContext } from "../../hooks/useGlobalContext";

type Props = {
  station: TStation;
  config: TConfigStation | IFieldConfig;
  numEstacaoAsLink?: boolean;
};

const StationPrintFiled = ({ station, config, numEstacaoAsLink = false }: Props) => {
  const { globalState } = useGlobalContext();
  const type = typeof station[config.campo as keyof TStation];
  const nameField = config.campo;
  const value = station[config.campo as keyof TStation];
  const typeFiled = config.typefield ? config.typefield : null;

  let valueToPrint;
  if (config.campo !== "FaixasFrequencia") {
    if (type !== "object") {
      if (type === "number") {
        if (value !== -1) {
          valueToPrint = value;
        } else {
          valueToPrint = "";
        }
      } else {
        if (["Latitude", "Longitude"].includes(nameField)) {
          valueToPrint = formatCoordinates(value as number, nameField);
        } else if (nameField === "CNPJ") {
          valueToPrint = formatCNPJ(value as string);
        } else if (numEstacaoAsLink && nameField === "NumEstacao") {
          valueToPrint = (
            <Link key={`lnk${station._id}`} to={`/stationpage/${station._id}`}>
              <div
                className={`             ${
                  station._id === globalState.idStation
                    ? "bg-top-digital w-24 h-6 rounded-md flex items-center justify-center -ml-4 text-white"
                    : "underline"
                } `}
              >
                {value}
              </div>
            </Link>
          );
        } else if (nameField === "situacaoDocumentos") {
          valueToPrint = (
            <div className="flex gap-2">
              <Link className="underline" key={`doc${station._id}`} to={`/reportdocuments/?stationid=${station._id}`}>
                <File className="w-4 h-4" />
              </Link>
              {station.TotalDocuments}
            </div>
          );
        } else {
          valueToPrint = value;
        }
      }
    } else {
      if (typeFiled === "array" && Array.isArray(value)) {
        valueToPrint = value.map((item, index) => <p key={index}>{item}</p>);
      } else if (Array.isArray(value)) {
        valueToPrint = value.length;
      }
    }
  }

  return (
    <div className={`flex flex-col ${config.align === "center" ? "items-center" : "items-start"}`}>{valueToPrint}</div>
  );
};

export default StationPrintFiled;
