import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { setNotificationsRead, notificationSelector } from "../../slices/NotificationSlice";

import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import type { ColumnDef, FilterFn } from "@tanstack/react-table";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import { filterFns } from "../../utils/reactTableFn";
import { useEffect, useState } from "react";

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  showFooter?: boolean;
  showNavigation?: boolean;
  showGlobalFilter?: boolean;
  filterFn?: FilterFn<T>;
}

export const Table = <T extends object>({
  data,
  columns,
  showFooter = false,
  showNavigation = true,
  showGlobalFilter = true,
  filterFn = filterFns.contains,
}: ReactTableProps<T>) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const dispatch = useAppDispatch();
  const { notificationPage, notificationSuccess } = useAppSelector(notificationSelector);

  const [success, setSuccess] = useState(notificationSuccess);

  useEffect(() => {
    setSuccess(notificationSuccess);
  }, [notificationSuccess]);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: filterFn,
  });

  const resultsSize = table.getFilteredRowModel().rows.length;

  useEffect(() => {
    if (!success) {
      const page = table.getState().pagination.pageIndex;
      const rows = table.getRowModel().rows;
      const objIds: string[] = [];
      for (const row of rows) {
        const read = row.original["read" as keyof T];
        const id = row.original["_id" as keyof T];
        if (id && !read) {
          objIds.push(id as string);
        }
      }
      if (objIds.length > 0) dispatch(setNotificationsRead({ objIds, page }));
    }
  }, [dispatch, table, notificationPage, success]);

  useEffect(() => {
    if (success) {
      table.setPageIndex(notificationPage);
    }
  }, [dispatch, notificationPage, table, success]);

  return (
    <div className="flex flex-col w-full">
      {showNavigation ? (
        <div className="flex flex-col items-start md:flex md:flex-row md:items-center md:justify-between w-full">
          <div className="flex items-center justify-between bg-white px-0 py-3 text-smp">
            <div className="ml-0">
              {resultsSize > 0 && (
                <nav
                  className="items-center justify-between isolate text-smp inline-flex -space-x-px rounded-none"
                  aria-label="Pagination"
                >
                  <div className="flex">
                    <button
                      className="relative inline-flex items-center px-2 py-2 h-5 w-7 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={() => {
                        table.previousPage();
                        setSuccess(false);
                      }}
                      disabled={!table.getCanPreviousPage()}
                    >
                      <span className="sr-only">Anterior</span>
                      <ChevronLeftIcon className="h-2 w-3" aria-hidden="true" />
                    </button>
                    <button
                      className="relative inline-flex items-center px-2 h-5 w-7 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={() => {
                        table.nextPage();
                        setSuccess(false);
                      }}
                      disabled={!table.getCanNextPage()}
                    >
                      <span className="sr-only">Próxima</span>
                      <ChevronRightIcon className="h-2 w-4" aria-hidden="true" />
                    </button>
                  </div>
                  {table.getFilteredRowModel().rows.length > 0 && (
                    <>
                      <div className="pl-2 flex flex-1 items-center justify-between">
                        <p className="text-smp text-gray-700">
                          <span className="font-medium">Página {table.getState().pagination.pageIndex + 1} </span> de{" "}
                          {table.getPageCount()}
                        </p>
                      </div>
                    </>
                  )}

                  <div className="h-4" />
                </nav>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div className="mt-2 overflow-y-auto ">
        <table className="text-left font-light w-full">
          <thead className="border-b bg-top-digital-op-40 font-medium dark:border-neutral-500">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-2 py-2 whitespace-nowrap font-top-digital-content font-semibold text-smp"
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={row.id}
                className={`${!(index % 2) ? "bg-white" : "bg-top-digital-op-25"} text-top-digital-content-color p-1`}
              >
                {row.getVisibleCells().map((cell) => (
                  <td className="px-2 py-2 font-top-digital-content font-normal text-smp align-top" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
