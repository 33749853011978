import { api, requestConfig } from "../utils/config";

const checkToken = async (tokenConfirm?: string): Promise<any> => {
  const config = requestConfig("GET", null);
  try {
    const res = await fetch(api + "/email/unsubscribe/" + tokenConfirm, config);

    return res.json();
  } catch (error) {
    return error;
  }
};

const setUnsubscribe = async (options: any): Promise<any> => {
  const config = requestConfig("POST", options);

  try {
    const res = await fetch(api + "/email/unsubscribe/", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    return error;
  }
};

const UnsubscribeService = {
  checkToken,
  setUnsubscribe,
};

export default UnsubscribeService;
