import { NetworkFile } from "../Interfaces/INetworkFile";
import { api, requestConfig } from "../utils/config";

const getFiles = async (network: any, token: string | undefined) => {
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(api + `/network/${network}`, config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.error(error);
  }
};

const uploadFile = async (data: NetworkFile, token: string | undefined) => {
  const config = requestConfig("POST", data, token, true);
  try {
    const res = await fetch(api + "/network/uploadfile", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

//GET
const downloadFile = async (doc: any | undefined, token: string | undefined) => {
  const config = requestConfig("GET", null, token);

  try {
    const res = await fetch(api + "/network/download/" + doc._id, config)
      .then((res) =>
        res.blob().then((blob) => {
          // Creating new object of PDF/DOC file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          const fileName = doc.originalName;
          alink.download = fileName;
          alink.click();
          return { download: true };
        })
      )
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

const deleteFile = async (id: string, token: string | undefined) => {
  const config = requestConfig("DELETE", null, token);

  try {
    console.log(api + "/network/" + id);
    const res = await fetch(api + "/network/" + id, config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

const NetworkFilesService = {
  getFiles,
  uploadFile,
  downloadFile,
  deleteFile,
};

export default NetworkFilesService;
