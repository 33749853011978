export const links = [
  {
    name: "Meu Perfil",
    allowedRoles: ["superadmin", "admin", "regular", "visualizador"],
    sublinks: [
      {
        name: "Alterar Senha",
        link: "/password",
        navLink: true,
        help: "Para alterar sua senha basta acessar Meu Perfil no menu superir e selecionar alterar senha. Na tela de alteração basta entrar com a nova senha e confirmar ela no formulário abaixo. A senha deve conter pelo menos 8 caractéres, pelo menos uma letra maiúscula e um número. Apóis preencher os dois campos, basta clicar em alterar.",
      },
      { name: "Alterar Dados", link: "/profile", navLink: true },
    ],
  },
  {
    name: "Documentos",
    allowedRoles: ["superadmin", "admin", "regular", "visualizador"],
    sublinks: [
      {
        name: "Situação Documentos",
        link: "/reportdocuments/?cnpj=&fistel=&uf=&municipio=&user=",
        navLink: true,
        help: "Documentos que foram cadastrados no sistema.",
      },
      {
        name: "DashBoard Documentos",
        link: "/dashboarddocuments",
        navLink: true,
        help: "Documentos que foram cadastrados no sistema.",
      },
    ],
  },
  {
    name: "Dashboard",
    allowedRoles: ["superadmin", "admin", "regular", "visualizador"],
    sublinks: [
      {
        name: "Agiliza Dashboard",
        link: "/dashboard",
        navLink: true,
        help: "O Dashboard Agiliza é uma ferramenta de visualização de dados que permite a você visualizar os dados de forma gráfica e intuitiva. Para acessar o Dashboard basta clicar no menu superior e selecionar Dashboard. Na tela de Dashboard você pode selecionar o período que deseja visualizar os dados, selecionar o tipo de gráfico e selecionar o tipo de dados que deseja visualizar. Para visualizar os dados basta clicar no botão Visualizar.",
      },
    ],
  },
  {
    name: "Boletos",
    allowedRoles: ["superadmin", "admin", "regular"],
    sublinks: [
      {
        name: "Todos Boletos",
        link: "/boletos",
        navLink: true,
        help: "Na tela de Boletos você pode visualizar todos os boletos que foram gerados para sua empresa pla Anatel. Para visualizar os boletos basta clicar no menu superior e selecionar Boletos e clicar em todos boletos.",
      },
      { name: "Dashboard Boletos", link: "/dashboardboletos", LayoutDashboard: true },
    ],
  },
  {
    name: "Admin",
    allowedRoles: ["superadmin", "admin"],
    sublinks: [
      { name: "Gestão de usuários", link: "/configusers", navLink: true },
      { name: "Gestão de CNPJ's", link: "/configcnpj", navLink: true },
      { name: "Gestão de protocolos", link: "/licensing", navLink: true },
    ],
  },
  {
    name: "Configurações",
    allowedRoles: ["superadmin", "admin", "regular", "visualizador"],
    sublinks: [
      { name: "Upload CSV Mosaico", link: "/upload", navLink: false, notAllowedRoles: ["visualizador"] },
      { name: "Tipos de Documentos", link: "/doctype", navLink: true, notAllowedRoles: ["visualizador"] },
      { name: "Configurações Estações", link: "/configstation", navLink: true },
      { name: "Configurações Sistemas", link: "/configsystem", navLink: true },
    ],
  },
];
