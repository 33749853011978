import { TObjectBoleto } from "../Interfaces/IBoletoData";
import { api, requestConfig } from "../utils/config"; // Certifique-se de ajustar o caminho conforme necessário

const BoletoService = {
  getAllBoletos: async (token: string) => {
    const config = requestConfig("GET", null, token);
    try {
      const res = await fetch(`${api}/boleto`, config);
      const data = await res.json();
      return data;
    } catch (error) {
      console.error(error);
      return { errors: ["Failed to fetch boletos"] };
    }
  },

  downloadBoleto: async (objectBoleto: TObjectBoleto, token: string | undefined) => {
    const config = requestConfig("GET", null, token);

    try {
      const res = await fetch(
        `${api}/boleto/downloadboleto/${objectBoleto.nossoNumero}/${objectBoleto.paymentDate}`,
        config
      );

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.errors[0]);
      }

      const data = await res.json();
      const pdfBuffer = data.pdfBuffer;
      const fileName = data.fileName;

      const byteCharacters = atob(pdfBuffer);
      const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = fileName;
      alink.click();

      return { download: true, extractedValues: data.extractedValues };
    } catch (error) {
      console.error(error);
      return { errors: ["Failed to download boleto"] };
    }
  },
};

export default BoletoService;
