import { ChevronDownIcon } from "lucide-react";
import { NavLink } from "react-router-dom";
import { links } from "./Mylinks";
import Logout from "../../presentational/logout/Logout";
import { useAuth } from "../../hooks/useAuth";
import classNames from "../../utils/classNames";
import NavProfile from "./NavProfile";
import NavNotification from "./NavNotification";
import React from "react";

interface DesktopMenuProps {
  openSubMenu: string;
  setOpenSubMenu: (name: string) => void;
  handleLogout: (e: React.MouseEvent<HTMLElement>) => void;
}

const DesktopMenu = ({ openSubMenu, setOpenSubMenu, handleLogout }: DesktopMenuProps) => {
  const { userData } = useAuth();

  return (
    <nav className={classNames("px-2 pb-4 sm:p-0 lg:mt-4 hidden lg:flex flex-col items-center lg:flex-row z-10")}>
      <div className="flex font-sm px-2 rounded text-sm lg:ml-4 cursor-pointer hover:text-top-digital-link-hover -mr-1 ">
        <NavLink key={`desktop-home`} to="/" onClick={() => setOpenSubMenu("")}>
          Home
        </NavLink>
        <ChevronDownIcon className="-mr-1 h-5 w-5 opacity-0 text-gray-400" aria-hidden="true" />
      </div>
      {links.map((category, index) => {
        const { name, allowedRoles, sublinks } = category;
        return (
          userData &&
          allowedRoles.includes(userData.role) && (
            <React.Fragment key={`desktop-${name}-${index}`}>
              <div
                className="flex font-sm px-2 rounded text-sm lg:ml-4 cursor-pointer hover:text-top-digital-link-hover"
                onClick={() => {
                  if (openSubMenu === name) setOpenSubMenu("");
                  else setOpenSubMenu(name);
                }}
              >
                {name} <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                <div
                  className={classNames(
                    "bg-white rounded-xl ml-4 lg:ml-0 mt-8 outline outline-offset-0 absolute p-2",
                    openSubMenu === name ? "block" : "hidden"
                  )}
                >
                  {sublinks.map((sublink: any, index) => {
                    if (sublink.notAllowedRoles && sublink.notAllowedRoles.includes(userData.role)) return null;
                    return (
                      <NavLink
                        key={`desktop-${sublink.name}-${index}`}
                        to={sublink.link}
                        className="flex p-2 rounded text-sm"
                        onClick={() => setOpenSubMenu("")}
                      >
                        {sublink.name}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            </React.Fragment>
          )
        );
      })}
      <NavNotification />
      <NavProfile />
      <div>
        <Logout handleLogout={handleLogout} />
      </div>
    </nav>
  );
};

export default DesktopMenu;
