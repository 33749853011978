import { NavLink } from "react-router-dom";

interface ILogoProps {
  auth: boolean;
}

const Logo = ({ auth }: ILogoProps) => (
  <div className={`flex ${!auth ? "items-center justify-center" : "ml-4 lg:ml-5"}`}>
    <NavLink to="/" className="-m-1.5 p-1.5">
      <span className="sr-only">Top Digital - Agiliza</span>
      <img src="/img/logo-top-digital-agi.png" alt="Top Digital - Agiliza" className="md:cursor-pointer h-12 w-auto" />
    </NavLink>
  </div>
);

export default Logo;
