import React, { useEffect } from "react";

import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

//redux

//primeira parte do componente: config e station
import { documentSelector, getSystemDocs, downloadDoc } from "../../slices/DocumentSlice";
import { Download } from "lucide-react";
import DocIcon from "../../components/Utils/DocIcon";
import DateFormat from "../../components/Utils/DateFormat";

type Props = {
  systemId: string;
};

const DocumentsSystem = ({ systemId }: Props) => {
  const dispatch = useAppDispatch();
  const { docsSystemDetails: documents } = useAppSelector(documentSelector);

  useEffect(() => {
    const obj = {
      systemId,
    };
    dispatch(getSystemDocs(obj));
  }, [dispatch, systemId]);

  const submitDownload = (e: React.MouseEvent<HTMLDivElement>, doc: any) => {
    e.preventDefault();
    dispatch(downloadDoc(doc));
  };
  return (
    <div className="flex flex-col">
      {documents && documents.length !== 0 && (
        <>
          <h2 className="text-sm text-top-digital-content-color font-semibold font-top-digital-content text-center">
            Documentos
          </h2>
          <div className="bg-white flex-1 order-2 md:order-1 overflow-x-auto">
            <div className="grid grid-cols-6 text-top-digital-content-color">
              <div className="bg-top-digital-secondary-main font-semibold px-6 py-4 text-smp text-left">Criado por</div>
              <div className="bg-top-digital-secondary-main font-semibold px-6 py-4 text-smp text-left">Titulo</div>
              <div className="bg-top-digital-secondary-main font-semibold px-6 py-4 text-smp">Tipo</div>
              <div className="bg-top-digital-secondary-main font-semibold px-6 py-4 text-smp">Extensão</div>
              <div className="bg-top-digital-secondary-main font-semibold px-6 py-4 text-smp">Data Criação</div>
              <div className="bg-top-digital-secondary-main font-semibold px-4 py-4 text-smp">Download</div>
            </div>
            {documents &&
              documents.map((doc, index) => (
                <div key={`${index}`} className="grid grid-cols-6 text-smp">
                  <div
                    className={`${
                      !(index % 2) ? "bg-white" : "bg-top-digital-secondary-light"
                    } text-top-digital-content-color p-1`}
                  >
                    {doc.createdBy}
                  </div>
                  <div
                    className={`${
                      !(index % 2) ? "bg-white" : "bg-top-digital-secondary-light"
                    } text-top-digital-content-color p-1`}
                  >
                    {doc.title}
                  </div>
                  <div
                    className={`${
                      !(index % 2) ? "bg-white" : "bg-top-digital-secondary-light"
                    } text-top-digital-content-color p-1 flex`}
                  >
                    {doc.typeName || "-"}
                  </div>
                  <div
                    className={`${
                      !(index % 2) ? "bg-white" : "bg-top-digital-secondary-light"
                    } text-top-digital-content-color center flex justify-center items-center p-1`}
                  >
                    <DocIcon extension={doc.extension} />
                  </div>
                  <div
                    className={`${
                      !(index % 2) ? "bg-white" : "bg-top-digital-secondary-light"
                    } text-top-digital-content-color p-1`}
                  >
                    <DateFormat data={doc.createdAt} />
                  </div>
                  <div
                    className={`${
                      !(index % 2) ? "bg-white" : "bg-top-digital-secondary-light"
                    } text-top-digital-content-color flex justify-center items-center p-1 cursor-pointer`}
                    onClick={(e) => submitDownload(e, doc)}
                  >
                    <Download className="w-5 h-5" />
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
      {documents && documents.length === 0 && (
        <h2 className="text-sm text-top-digital-content-color font-normal font-top-digital-content text-center py-4">
          Esse sistema ainda não possui documentos vinculados
        </h2>
      )}
    </div>
  );
};

export default DocumentsSystem;
