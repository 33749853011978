const formatCNPJ = (cnpj: any): string => {
  // Remove todos os caracteres não numéricos
  const numericCNPJ = cnpj.replace(/\D/g, "");

  // Verifica se o CNPJ é vazio ou possui menos de 14 dígitos
  if (!numericCNPJ || numericCNPJ.length < 14) {
    return "-";
  }

  return numericCNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
};

export default formatCNPJ;
