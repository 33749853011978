import { api, requestConfig } from "../utils/config";

const getUserPreferences = async (token: string | undefined) => {
  const config = requestConfig("GET", null, token);

  try {
    const res = await fetch(api + "/userpreference", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    return error;
  }
};

const setUserPreference = async (data: { field: string; value: boolean }, token: string | undefined) => {
  const config = requestConfig("POST", data, token);
  try {
    const res = await fetch(api + "/userpreference", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    return error;
  }
};

const UserPreferenceService = {
  getUserPreferences,
  setUserPreference,
};

export default UserPreferenceService;
