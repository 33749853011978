import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import type { ColumnDef, FilterFn } from "@tanstack/react-table";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import { DebouncedInput } from "../Boleto/DebouncedInput";
import { filterFns } from "../../utils/reactTableFn";
import { useState } from "react";

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  showFooter?: boolean;
  showNavigation?: boolean;
  showGlobalFilter?: boolean;
  filterFn?: FilterFn<T>;
}
//const LicencindURL = ({ protocolProcessId, urlProcess }: Props) => {
const LicensingTable = <T extends object>({
  data,
  columns,
  showFooter = false,
  showNavigation = true,
  showGlobalFilter = true,
  filterFn = filterFns.contains,
}: ReactTableProps<T>) => {
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data,
    columns,
    //
    state: {
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: filterFn,
  });

  return (
    <>
      {showNavigation ? (
        <>
          <div className="h-2 mt-5" />
          <div className="flex items-center justify-between w-full">
            <div className="overflow-hidden p-2">
              {showGlobalFilter ? (
                <DebouncedInput
                  value={globalFilter ?? ""}
                  onChange={(value) => setGlobalFilter(String(value))}
                  className="font-lg border-block border p-2 shadow mb-2"
                  placeholder="Buscar em todas colunas..."
                />
              ) : null}
            </div>
            <div className="flex items-center justify-between bg-white px-4 py-3 text-smp">
              <div>
                <nav
                  className="items-center justify-between isolate text-smp inline-flex -space-x-px rounded-none"
                  aria-label="Pagination"
                >
                  <button
                    className="relative inline-flex items-center h-8 rounded-l-md px-2 py-0 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <span className="sr-only">Primeira</span>
                    <ChevronDoubleLeftIcon className="h-3 w-4" aria-hidden="true" />
                  </button>
                  <button
                    className="relative inline-flex items-center px-2 py-2 h-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <span className="sr-only">Anterior</span>
                    <ChevronLeftIcon className="h-3 w-4" aria-hidden="true" />
                  </button>
                  <button
                    className="relative inline-flex items-center  px-2 h-8 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    <span className="sr-only">Próxima</span>
                    <ChevronRightIcon className="h-3 w-4" aria-hidden="true" />
                  </button>
                  <button
                    className="relative inline-flex items-center rounded-r-md px-2 h-8 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    <span className="sr-only">Última</span>
                    <ChevronDoubleRightIcon className="h-3 w-4" aria-hidden="true" />
                  </button>
                  <div className="pl-2 flex flex-1 items-center justify-between">
                    <p className="text-smp text-gray-700">
                      <span className="font-medium">Página {table.getState().pagination.pageIndex + 1} </span> de{" "}
                      {table.getPageCount()}
                      {/* de{" "}
                      <span className="font-medium">
                        {globalFilter.trim() === "" ? table.getTotalSize() : table.getFilteredRowModel().rows.length}{" "}
                      </span>{" "}
                      resultados */}
                    </p>
                  </div>
                  <div className="flex justify-center items-center pl-2">
                    <select
                      className="customSelect mt-0"
                      value={table.getState().pagination.pageSize}
                      onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Mostrar {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="h-4" />
                </nav>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="mt-2 h-full w-full overflow-y-auto">
        <table className="text-left font-light w-full">
          <thead className="border-b bg-top-digital-op-40 font-medium dark:border-neutral-500">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-4 py-4 whitespace-nowrap font-top-digital-content font-semibold text-smp"
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={row.id}
                className={`${
                  !(index % 2) ? "bg-white" : "bg-top-digital-op-25"
                } text-top-digital-content-color p-1 mb-1`}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="px-4 py-4 whitespace-nowrap font-top-digital-content font-normal text-smp"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {showFooter ? (
            <tfoot className="border-t bg-gray-50">
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          ) : null}
        </table>
      </div>
    </>
  );
};

export default LicensingTable;
