import { TProfile, TProfileUpdatedByAdmin, TProfileUpdatedByNotAdmin } from "./IProfileStates";

export type TRegister = {
  _id: string;
};

export enum Gender {
  Male = "male",
  Female = "female",
  Other = "other",
  Empty = "",
}

export enum Role {
  SA = "superadmin",
  AD = "admin",
  RE = "regular",
  VI = "visualizador",
  EP = "",
}

export enum TipoCadastro {
  PF = "PF",
  PJ = "PJ",
}

export type TRegisterFields = TProfile & {
  password: string;
  acceptTerms: boolean;
  acceptTermsDetails: string;
  fistelList: string[];
  cnpjList: string[];
  confirmPassword: string;
  isChildRegister: boolean;
  isFinishChild: boolean;
};

export type TFinishRegisterFields = {
  id: string;
  cellPhone: string;
  receiveNews: boolean;
  acceptAlertEmails: boolean;
  acceptTerms: boolean;
  acceptTermsDetails: string;
  password: string;
  confirmPassword: string;
};

export interface ValidationError {
  field: string;
  message: string;
}

export type Greeting = {
  name: string;
  gender: Gender;
};

export interface ErrorResponse {
  errors: ValidationError[];
}

export interface IRegisterStates {
  registerUser: TRegister | null;
  registerError: boolean;
  registerMessage: ErrorResponse;
  registerGreeting: Greeting | null;
  registerSuccess: boolean;
  registerFinishSuccess: boolean;
  registerLoading: boolean;
  registerFinishId: string | null;
  registerFinishEmail: string | null;
}

export type FieldConfig = {
  required: boolean;
  isVisible: boolean;
  defaultValue: string | boolean | string[] | undefined;
  isComponent?: boolean;
};

export type TRoleRegister = {
  tipoCadastro: FieldConfig;
  name: FieldConfig;
  lastname: FieldConfig;
  gender: FieldConfig;
  email: FieldConfig;
  tradename: FieldConfig;
  businessname: FieldConfig;
  cnpj: FieldConfig;
  cpf: FieldConfig;
  cep: FieldConfig;
  street: FieldConfig;
  number: FieldConfig;
  complement: FieldConfig;
  district: FieldConfig;
  city: FieldConfig;
  state: FieldConfig;
  businessPhone: FieldConfig;
  cellPhone: FieldConfig;
  receiveNews: FieldConfig;
  receiveLicensingNews: FieldConfig;
  acceptAlertEmails: FieldConfig;
  acceptTerms: FieldConfig;
  acceptTermsDetails: FieldConfig;
  password: FieldConfig;
  confirmPassword: FieldConfig;
  fistelList: FieldConfig;
  cnpjList: FieldConfig;
  role: FieldConfig;
  parentID: FieldConfig;
  isChildRegister: FieldConfig;
  isFinishChild: FieldConfig;
};

export const roleRegister: TRoleRegister = {
  tipoCadastro: {
    required: true,
    isVisible: false,
    defaultValue: "PJ",
  },
  name: {
    required: true,
    isVisible: true,
    defaultValue: "",
  },
  lastname: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  gender: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  email: {
    required: true,
    isVisible: true,
    defaultValue: "",
  },
  tradename: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  businessname: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  cnpj: {
    required: true,
    isVisible: true,
    defaultValue: [],
    isComponent: true,
  },
  cpf: {
    required: true,
    isVisible: true,
    defaultValue: "",
  },
  cep: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  street: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  number: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  complement: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  district: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  city: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  state: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  businessPhone: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  cellPhone: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  receiveNews: {
    required: false,
    isVisible: true,
    defaultValue: false,
  },
  receiveLicensingNews: {
    required: false,
    isVisible: true,
    defaultValue: false,
  },
  acceptAlertEmails: {
    required: true,
    isVisible: true,
    defaultValue: false,
  },
  acceptTerms: {
    required: true,
    isVisible: true,
    defaultValue: false,
  },
  acceptTermsDetails: {
    required: true,
    isVisible: false,
    defaultValue: "",
  },
  password: {
    required: true,
    isVisible: true,
    defaultValue: "",
  },
  confirmPassword: {
    required: true,
    isVisible: true,
    defaultValue: "",
  },
  fistelList: {
    required: false,
    isVisible: false,
    defaultValue: [],
  },
  cnpjList: {
    required: true,
    isVisible: true,
    defaultValue: [],
  },
  role: {
    required: true,
    isVisible: false,
    defaultValue: "superadmin",
  },
  parentID: {
    required: false,
    isVisible: false,
    defaultValue: "",
  },
  isChildRegister: {
    required: false,
    isVisible: false,
    defaultValue: false,
  },
  isFinishChild: {
    required: false,
    isVisible: false,
    defaultValue: false,
  },
};

export interface RegisterPayload {
  userRegister: TRegisterFields;
  roleRegister: TRoleRegister;
}

export interface UpdatePayload {
  userUpdate: TProfile;
  roleUpdate: TRoleRegister;
}

export interface RegisterFinishRegisterPayload {
  userRegister: TFinishRegisterFields;
  roleRegister: TRoleRegister;
}

//TProfileUpdatedByAdmin
export type UpdatedByAdminPayload = {
  userUpdate: TProfileUpdatedByAdmin;
  roleUpdate: TRoleRegister | null;
};

export type UpdatedByNotAdminPayload = {
  userUpdate: TProfileUpdatedByNotAdmin;
  roleUpdate: TRoleRegister;
};
