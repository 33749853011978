//icons
import { Edit, BookOpen } from "lucide-react";

//components
import DataUnixTimeStamp from "../../components/Utils/DataUnixTimeStamp";

//hooks
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useAuth } from "../../hooks/useAuth";

type Props = {
  systems: any[];
  labels: any[];
};

const SystemTable = ({ systems, labels }: Props) => {
  const { globalState, dispatchGlobalState } = useGlobalContext();
  const { role } = useAuth().userData;

  const handleOpenLabelSystemForm = (e: React.MouseEvent<HTMLElement>, idSystem: string, label: string) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    dispatchGlobalState({
      type: "OPEN_FORM",
      currentForm: "openedLabelSystemForm",
      labelSystem: label,
      idSystem: idSystem,
    });
  };

  const handleOpenSystemDetails = (e: React.MouseEvent<HTMLElement>, idSystem: string) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    dispatchGlobalState({
      type: "OPEN_FORM",
      currentForm: "openedSystemDetails",
      idSystem: idSystem,
    });
  };
  return (
    <>
      {systems && systems.length !== 0 && (
        <div className="overflow-auto mb-2 system-scroll">
          <div className="border-b-[15px] border-top-digital-secondary-lighter">
            <table className="text-left rounded-tl-md rounded-bl-md overflow-hidden">
              <thead className="border-b bg-top-digital-secondary-main font-medium">
                <tr key="loc0">
                  <th
                    key="open"
                    scope="col"
                    className="px-6 py-4 whitespace-nowrap text-right text-black font-top-digital-content text-smp font-medium"
                  >
                    Abrir
                  </th>
                  {labels && labels.length !== 0 && (
                    <>
                      {labels.map((label, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-4 py-2 whitespace-nowrap text-left text-black font-top-digital-content text-smp font-medium"
                        >
                          {label[1]}
                        </th>
                      ))}
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {systems &&
                  systems.map((system, index) => (
                    <tr
                      key={system._id}
                      className={`text-smp font-top-digital-content text-center text-top-digital-secondary-darker font-normal ${
                        index % 2 ? "bg-top-digital-secondary-light" : "bg-white"
                      } ${
                        system._id === globalState.idSystem
                          ? "border-2 border-top-digital-hover text-top-digital-hover font-semibold"
                          : "font-normal"
                      }`}
                    >
                      <td key="open" className={`whitespace-nowrap px-4 py-2`}>
                        <button
                          className={`${
                            system._id === globalState.idSystem
                              ? "text-top-digital-link-hover"
                              : "hover:text-top-digital-link-hover"
                          }`}
                          title="Ver detalhes"
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpenSystemDetails(e, system._id);
                          }}
                        >
                          <BookOpen className="cursor-pointer h-4 w-4" />
                        </button>
                      </td>

                      {labels &&
                        labels.map((label) => (
                          <td key={`${label[0]}${system._id}`} className="whitespace-nowrap px-4 py-2 text-left">
                            {label[0] === "Label" && (
                              <div
                                className="w-full text-center flex justify-center gap-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenLabelSystemForm(e, system._id, system[label[0]]);
                                }}
                              >
                                {system[label[0]]}
                                <button
                                  className={`${
                                    system._id === globalState.idSystem
                                      ? "text-top-digital-link-hover"
                                      : "hover:text-top-digital-link-hover"
                                  } ${role !== "visualizador" ? "" : "hidden"}`}
                                  title="Coloque um apelido no sistema"
                                >
                                  <Edit className="h-4 w-4" />
                                </button>
                              </div>
                            )}
                            {label[0] === "DataValidade" ? (
                              <DataUnixTimeStamp timestamp={system[label[0]]} />
                            ) : (
                              label[0] !== "Label" && system[label[0]]
                            )}
                          </td>
                        ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default SystemTable;
