import React from "react";

type Props = {
  timestamp: string;
};

const DataUnixTimeStamp = ({ timestamp }: Props) => {
  const date = new Date(parseInt(timestamp));
  return <div className="text-top-digital-hover font-semibold text-smp">{date.toLocaleDateString()}</div>;
};

export default DataUnixTimeStamp;
