import React from "react";
import { Edit, Lock, Mail, RadioTower, Trash2, Unlock } from "lucide-react";
import FilterView from "../../components/Filter/FilterView";
import { ChildUserEntry } from "../../Interfaces/IAdmin";
import { TProfile } from "../../Interfaces/IProfileStates";
import { useAuth } from "../../hooks/useAuth";
import Modal from "../../presentational/modal/Modal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

type Props = {
  childUsers: ChildUserEntry[];
  handleEditChildUser: (id: string) => void;
  handleReSendConfirmationEmail: (id: string) => void;
  handleEditConfigChildUser: (id: string) => void;
  handleBlockUser: (user: TProfile) => void;
  handleRemoveUser: (user: TProfile) => void;
};

const ExtraUsers: React.FC<Props> = ({
  childUsers,
  handleEditChildUser,
  handleReSendConfirmationEmail,
  handleEditConfigChildUser,
  handleBlockUser,
  handleRemoveUser,
}) => {
  const { userData } = useAuth();
  const [removePerson, setRemovePerson] = React.useState<any>(null);
  const [openRemoveUser, setOpenRemoveUser] = React.useState(false);
  const [blockPerson, setBlockPerson] = React.useState<any>(null);
  const [openBlockUser, setOpenBlockUser] = React.useState(false);

  return (
    <>
      <table className="w-2/3 divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Nome
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Email
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Filtro de Estações
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Permissão
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {childUsers.map(([person, configs]) => {
            return (
              <tr key={person.email}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  {person.name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.email}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {configs && configs.length > 0
                    ? configs.map((cnf, index) => (
                        <div key={index}>
                          <div>{cnf.label}</div>
                          <FilterView config={cnf} />
                        </div>
                      ))
                    : "Todas"}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.role}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {person.confirmed ? (person.isBlocked ? "Bloqueado" : "Ativo") : "Pendente"}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <div className="flex gap-2">
                    {(userData.role === "superadmin" || person.role !== "admin") && (
                      <button
                        onClick={() => handleEditChildUser(person._id || "")}
                        title="Editar usuário"
                        className="text-top-digital hover:text-top-digital-buttom-hover"
                      >
                        <Edit size={20} />
                      </button>
                    )}
                    {!person.confirmed && (
                      <button
                        onClick={() => handleReSendConfirmationEmail(person._id || "")}
                        title="Reenviar email de confirmação"
                        className="text-top-digital hover:text-top-digital-buttom-hover"
                      >
                        <Mail size={20} />
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setBlockPerson(person);
                        setOpenBlockUser(true);
                      }}
                      title={`${person.isBlocked ? "Desbloquear" : "Bloquear"} accesso do usuário`}
                      className="text-top-digital hover:text-top-digital-buttom-hover"
                    >
                      {person.isBlocked ? <Lock size={20} /> : <Unlock size={20} />}
                    </button>
                    <button
                      onClick={() => handleEditConfigChildUser(person._id || "")}
                      title="Configurar estações do usuário"
                      className="text-top-digital hover:text-top-digital-buttom-hover"
                    >
                      <RadioTower size={20} />
                    </button>
                    <button
                      title="Remover usuário"
                      className="text-top-digital hover:text-top-digital-buttom-hover"
                      onClick={() => {
                        setRemovePerson(person);
                        setOpenRemoveUser(true);
                      }}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {openBlockUser && (
        <Modal
          title={`${blockPerson.isBlocked ? "Desbloquear" : "Bloquear"} usuário`}
          description={`Essa ação irá ${
            blockPerson.isBlocked ? "desbloquear" : "bloquear"
          } o usuário do sistema. Deseja continuar?`}
          labelButton={`${blockPerson.isBlocked ? "Desbloquear" : "Bloquear"}`}
          onClick={(data: any) => {
            handleBlockUser(data);
            setOpenBlockUser(false);
          }}
          onCancel={() => setOpenBlockUser(false)}
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
          isOpen={openBlockUser}
          setOpen={setOpenBlockUser}
          data={blockPerson}
        />
      )}
      {openRemoveUser && (
        <Modal
          title="Remover usuário"
          description="Essa ação irá remover  o usuário do sistema. Deseja continuar?"
          labelButton="Remover"
          onClick={(data: any) => {
            handleRemoveUser(data);
            setOpenRemoveUser(false);
          }}
          onCancel={() => setOpenRemoveUser(false)}
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
          isOpen={openRemoveUser}
          setOpen={setOpenRemoveUser}
          data={removePerson}
        />
      )}
    </>
  );
};

export default ExtraUsers;
