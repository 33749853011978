//react
import React, { useEffect } from "react";

//redux
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  editableFieldStationSelector,
  setEditableFieldStationValue,
  resetEditableField,
} from "../../slices/EditableFieldStationSlice";
import { stationSelector } from "../../slices/StationSlice";

//context
import { useGlobalContext } from "../../hooks/useGlobalContext";

const EditStationLabel = () => {
  //global context
  const { globalState, dispatchGlobalState } = useGlobalContext();
  //states
  const dispatch = useAppDispatch();
  const { editableFieldStationSuccess } = useAppSelector(editableFieldStationSelector);
  const { station } = useAppSelector(stationSelector);

  useEffect(() => {
    dispatchGlobalState({ type: "SET_LABEL_STATION", labelStation: station!.Label });
  }, [station, dispatchGlobalState]);

  useEffect(() => {
    if (editableFieldStationSuccess) {
      dispatchGlobalState({ type: "CLOSE_LABEL_STATION_FORM" });
    }
    dispatch(resetEditableField());
  }, [editableFieldStationSuccess, dispatch, dispatchGlobalState]);

  const submitHandleLabelStation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const label = globalState.labelStation;

    const data = {
      value: label,
      field: "Label",
      idStation: station!._id,
    };
    dispatch(setEditableFieldStationValue(data));
  };

  return (
    <div className="absolute top-0 right-0 bottom-0 left-0">
      <div className="overflow-x-auto rounded-lg bg-white h-full">
        <div className="p-6">
          <div className=" bg-white">
            <div className="flex flex-col p-2 text-top-digital-content-color">
              <div className="mx-auto w-full mb-6">
                <h2 className="font-top-digital-content font-normal text-top-digital-content-color">
                  Adicione/Edite um apelido para a estação
                  <span className="font-bold"> {station && station.EnderecoEstacao}</span>
                </h2>
              </div>
              <div className="mx-auto w-full">
                <form onSubmit={submitHandleLabelStation}>
                  <div className="mb-5">
                    <label
                      htmlFor="labelStation"
                      className="mb-2 block text-smp font-medium text-top-digital-content-color"
                    >
                      Apelido da Estação:
                    </label>
                    <input
                      type="text"
                      onChange={(e) => dispatchGlobalState({ type: "SET_LABEL_STATION", labelStation: e.target.value })}
                      name="labelStation"
                      value={globalState.labelStation}
                      id="labelStation"
                      className="customInputText w-2/3"
                    />
                  </div>
                  <div>
                    <div className="flex gap-1">
                      <button type="submit" className="customButton">
                        Enviar
                      </button>
                      <button
                        className="customButton"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatchGlobalState({ type: "CLOSE_LABEL_STATION_FORM" });
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStationLabel;
