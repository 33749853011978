import { useState, useEffect, FormEvent } from "react";
import { useParams } from "react-router-dom";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";

import { urlapp } from "../../../utils/config";

import { checkToken, setUnsubscribe, unsubscribeSelector } from "../../../slices/UnsubscribeSlice";

import ImageOffLine from "../../../presentational/image-main/ImageMain";

const Unsubscribe = () => {
  const { tokenUser } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkToken(tokenUser));
  }, [dispatch, tokenUser]);

  const {
    isCheckingToken,
    isSettingUnsubscribe,
    isUnsubscribeSet,
    hasError,
    errorMessage,
    messageReceivingSettings: s, //renomeei para s pois o nome é muito grande
  } = useAppSelector(unsubscribeSelector);

  const [receiveLicensingNews, setReceiveLicensingNews] = useState<boolean>(s.receiveLicensingNews);
  const [receiveNews, setReceiveNews] = useState<boolean>(s.receiveNews);
  const [acceptAlertEmails, setAcceptAlertEmails] = useState<boolean>(s.acceptAlertEmails);
  const [unsubscribeError, setUnsubscribeError] = useState<string>("");

  // Atualize os estados locais quando o estado do Redux mudar
  useEffect(() => {
    setReceiveLicensingNews(s.receiveLicensingNews);
    setReceiveNews(s.receiveNews);
    setAcceptAlertEmails(s.acceptAlertEmails);
  }, [s]);

  useEffect(() => {
    if (hasError && errorMessage) {
      setUnsubscribeError(errorMessage);
    }
  }, [hasError, errorMessage]);

  if (isCheckingToken) return <p>Validando seu token...</p>;
  if (isSettingUnsubscribe) return <p>Aguarde...</p>;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const objSettings = {
      receiveLicensingNews,
      receiveNews,
      acceptAlertEmails,
      tokenConfirm: tokenUser,
    };

    dispatch(setUnsubscribe(objSettings));
  };

  return (
    <div className="flex w-full gap-4">
      <div className="flex min-h-full flex-1 flex-col justify-center md:justify-start px-6 py-2 md:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-16 w-auto"
            src={`${urlapp}/img/logo-antena-top-digital-agi.png`}
            alt="Your Company"
          />
        </div>
        <>
          <h2 className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-top-digital-content-color">
            Configuração de recebimento de e-mails
          </h2>
          <div className="sm:mx-4 sm:w-full sm:max-w-md">
            {unsubscribeError ? <p className="text-red-500 text-sm">{unsubscribeError}</p> : <span>&nbsp;</span>}
            {isUnsubscribeSet ? (
              <p className="text-green-500 text-sm">Configurações atualizadas com sucesso</p>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
          <div className="mt-4 sm:mx-4 sm:w-full sm:max-w-md">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <div className="flex flex-col gap-3 mt-4">
                  <div>
                    <label
                      htmlFor="receiveLicensingNews"
                      className="block text-sm font-normal leading-6 top-digital-content-color"
                    >
                      <input
                        type="checkbox"
                        checked={receiveLicensingNews}
                        onChange={(e) => setReceiveLicensingNews(e.target.checked)}
                        className="customInputCheckbox mr-2"
                      />
                      Aceito receber informações sobre os processos de licenciamento Anatel ligados a empresa
                    </label>
                  </div>
                  <div>
                    <label
                      htmlFor="acceptAlertEmails"
                      className="block text-sm font-normal leading-6 top-digital-content-color"
                    >
                      <input
                        type="checkbox"
                        checked={acceptAlertEmails}
                        onChange={(e) => setAcceptAlertEmails(e.target.checked)}
                        className="customInputCheckbox mr-2"
                      />
                      Aceito receber emails informando sobre novos boletos Anatel e o status dos atuais
                    </label>
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="receiveNews"
                      className="block text-sm font-normal leading-6 top-digital-content-color"
                    >
                      <input
                        type="checkbox"
                        checked={receiveNews}
                        onChange={(e) => setReceiveNews(e.target.checked)}
                        className="customInputCheckbox mr-2"
                      />
                      Aceito receber novidades sobre o Agiliza e fatos relevantes da Anatel
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  className="flex w-1/2 justify-center rounded-md bg-top-digital px-3 py-1.5 text-smp font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Atualizar
                </button>
              </div>
            </form>
          </div>
        </>
      </div>
      <ImageOffLine />
    </div>
  );
};

export default Unsubscribe;
