import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { TFilterCoordinates } from "../../Interfaces/ILocation";
import { ChevronDown, ChevronUp, Filter } from "lucide-react";
import { IFieldConfig } from "../../Interfaces/IStation";
//context
import { useGlobalContext } from "../../hooks/useGlobalContext";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  stationSelector,
  checkFaixaFreqLatLanFilter,
  stationReset,
  stationResetFilters,
} from "../../slices/StationSlice";

type Props = {
  labels: IFieldConfig[];
  resetLocalStoragePage: () => void;
};

const FaixaFreqLatLanFilter = ({ labels, resetLocalStoragePage }: Props) => {
  const fieldFaixaFreqFilter = "FaixasFrequencia";
  const fieldLatituteFilter = "Latitude";
  const fieldLongitudeFilter = "Longitude";
  const fieldRaioFilter = "Raio";

  const { globalState, dispatchGlobalState } = useGlobalContext();
  const {
    faixaFreqLatLangError,
    faixaFreqLatLangSuccess,
    faixaFreqFilter,
    faixaLatitudeFilter,
    faixaLongitudeFilter,
    faixaRaioFilter,
    faixaFreqLatLangMessage,
    faixaIncludeFaixaFreq,
    faixaIncludeLatLon,
  } = useAppSelector(stationSelector);

  const [formCoords, setFormCoords] = useState<TFilterCoordinates>({
    [fieldLatituteFilter]: faixaLatitudeFilter ? faixaLatitudeFilter : null,
    [fieldLongitudeFilter]: faixaLongitudeFilter ? faixaLongitudeFilter : null,
    [fieldRaioFilter]: faixaRaioFilter ? faixaRaioFilter : null,
  });

  const [freqFilter, setFreqFilter] = useState<string>(faixaFreqFilter ? faixaFreqFilter : "");

  const dispatch = useAppDispatch();
  const [latLangErr, setLatLangErr] = React.useState("");

  const handleFreqLatLngFilter = useCallback(() => {
    if (faixaIncludeFaixaFreq && faixaIncludeLatLon) {
      dispatchGlobalState({
        type: "SET_FILTERS",
        filters: {
          ...globalState.filters,
          [fieldFaixaFreqFilter]: faixaFreqFilter,
          coordsFilters: {
            ...globalState.filters.coordsFilters,
            [fieldLatituteFilter]: formCoords.Latitude,
            [fieldLongitudeFilter]: formCoords.Longitude,
            [fieldRaioFilter]: formCoords.Raio,
          },
        },
      });
    } else if (!faixaIncludeFaixaFreq && faixaIncludeLatLon) {
      dispatchGlobalState({
        type: "SET_FILTERS",
        filters: {
          coordsFilters: {
            ...globalState.filters.coordsFilters,
            [fieldLatituteFilter]: formCoords.Latitude,
            [fieldLongitudeFilter]: formCoords.Longitude,
            [fieldRaioFilter]: formCoords.Raio,
          },
        },
      });
    } else if (faixaIncludeFaixaFreq && !faixaIncludeLatLon) {
      dispatchGlobalState({
        type: "SET_FILTERS",
        filters: {
          ...globalState.filters,
          [fieldFaixaFreqFilter]: faixaFreqFilter,
        },
      });
    }

    resetLocalStoragePage();
  }, [
    dispatchGlobalState,
    globalState.filters,
    faixaFreqFilter,
    resetLocalStoragePage,
    formCoords,
    faixaIncludeFaixaFreq,
    faixaIncludeLatLon,
  ]);

  const handleShowLatLongFilter = () => {
    if (!globalState.showLatLangFilter) {
      dispatchGlobalState({ type: "OPEN_LANG_FILTER" });
    } else {
      closeLatLangClearFilter();
    }
  };

  useEffect(() => {
    if (faixaFreqLatLangSuccess) {
      handleFreqLatLngFilter();
      dispatch(stationReset());
    }
  }, [faixaFreqLatLangSuccess, handleFreqLatLngFilter, dispatch]);

  const closeLatLangClearFilter = () => {
    resetLocalStoragePage();
    setFormCoords({ ...formCoords, Latitude: null, Longitude: null, Raio: 50, error: "" });
    setLatLangErr("");
    setFreqFilter("");

    const existingFilters = { ...globalState.filters }; // Copia os filtros existentes

    existingFilters.coordsFilters = { Latitude: "", Longitude: "", Raio: 50 }; // Altera apenas a chave coordsFilters

    delete existingFilters[fieldFaixaFreqFilter];

    dispatchGlobalState({
      type: "SET_FILTERS",
      filters: existingFilters,
    });

    dispatchGlobalState({ type: "CLOSE_LANG_FILTER" });
    dispatch(stationResetFilters());
  };

  const validateLatLongFreq = (e: FormEvent<HTMLFormElement>) => {
    /* ÁREA DESTINADA A TRABALHAR COM OS FILTROS DE COORDENADAS */

    e.preventDefault();
    setLatLangErr("");
    let faixaLatitudeFilter = formCoords.Latitude ? formCoords.Latitude : null;
    let faixaLongitudeFilter = formCoords.Longitude ? formCoords.Longitude : null;
    let faixaRaioFilter = formCoords.Raio ? formCoords.Raio : 50; // Valor padrão 50

    const isValidLatitude = (): boolean => {
      return typeof faixaLatitudeFilter === "number" && !isNaN(faixaLatitudeFilter);
    };

    const isValidLongitude = (): boolean => {
      return typeof faixaLongitudeFilter === "number" && !isNaN(faixaLongitudeFilter);
    };

    const isEmptyLatLang = () => {
      return faixaLatitudeFilter === null && faixaLongitudeFilter === null;
    };

    const isValidRaio = (): boolean => {
      return !isNaN(faixaRaioFilter) && faixaRaioFilter > 0;
    };

    const isValidFreqFilter = (): boolean => {
      // Verifique se freqFilter está definido e não é vazio (ou aplique a lógica de validação adequada)
      return freqFilter !== ""; // Por exemplo, verifique se não está vazio
    };

    const validateFields = (): boolean => {
      if (
        (faixaLatitudeFilter || faixaLongitudeFilter) &&
        (!isValidLatitude() || !isValidLongitude() || !isValidRaio())
      ) {
        setLatLangErr("Favor preencher latitude e longitude válidos.");
        return false;
      }

      if (isEmptyLatLang() && !isValidFreqFilter()) {
        setLatLangErr("Favor preencher um filtro.");
        return false;
      }

      return true; // Sem erros
    };

    // Chamada da função de validação
    if (validateFields()) {
      // As coordenadas estão válidas ou freqFilter não está vazio
      // Faça o que precisa ser feito se as coordenadas forem válidas ou freqFilter não estiver vazio
      const obj = {
        freqFilter,
        faixaLatitudeFilter,
        faixaLongitudeFilter,
        faixaRaioFilter,
      };
      dispatch(checkFaixaFreqLatLanFilter(obj));
    }
  };

  return (
    <div className="flex flex-col bg-top-digital-op-25 justify-between mt-2 mb-2 rounded-md">
      <form onSubmit={validateLatLongFreq}>
        <div
          className="flex items-center justify-between ml-2 my-2 cursor-pointer"
          onClick={() => handleShowLatLongFilter()}
        >
          <div className="flex">
            <button type="button" className="flex gap-1 items-center justify-center">
              <Filter className="cursor-pointer w-4 h-4" />
              <span className="text-smp">Filtros Adicionais</span>
            </button>
          </div>
          <div>
            {globalState.showLatLangFilter ? (
              <ChevronDown className="w-4 h-4 mr-2" />
            ) : (
              <ChevronUp className="w-4 h-4 mr-2" />
            )}
          </div>
        </div>

        {(globalState.showLatLangFilter ||
          (formCoords.Latitude && formCoords.Longitude) ||
          globalState.filters[fieldFaixaFreqFilter] !== undefined) && (
          <>
            <div className="flex">
              <div className="flex flex-col md:flex-row justify-between">
                <div className="ml-2 mb-2 md:mb-0 flex items-start gap-1 md:items-center flex-col md:flex-row">
                  <div className="flex items-center mr-1">
                    <span className="text-smp whitespace-nowrap">Latitude (DD)</span>
                  </div>
                  <div className="flex items-center justify-center h-full">
                    <input
                      name="filter_lat"
                      value={formCoords.Latitude ? formCoords.Latitude : ""}
                      onChange={(e) => setFormCoords({ ...formCoords, Latitude: parseFloat(e.target.value) })}
                      type="number"
                      step="any"
                      className="customInputNumber w-full"
                    />
                  </div>
                </div>

                <div className="ml-2 mb-2 md:mb-0 flex items-start gap-1 md:items-center flex-col md:flex-row">
                  <div className="flex items-center mr-1">
                    <span className="text-smp whitespace-nowrap">Longitude (DD)</span>
                  </div>
                  <div className="flex items-center justify-center h-full">
                    <input
                      name="filter_lng"
                      value={formCoords.Longitude ? formCoords.Longitude : ""}
                      onChange={(e) => setFormCoords({ ...formCoords, Longitude: parseFloat(e.target.value) })}
                      type="number"
                      step="any"
                      className="customInputNumber w-full"
                    />
                  </div>
                </div>

                <div className="ml-2 mb-2 md:mb-0 flex items-start gap-1 md:items-center flex-col md:flex-row">
                  <div className="flex items-center mr-1">
                    <span className="text-smp whitespace-nowrap">Raio(km)</span>
                  </div>
                  <div className="flex items-center justify-center h-full">
                    <input
                      name="filter_lng"
                      value={formCoords.Raio ? formCoords.Raio : ""}
                      onChange={(e) => setFormCoords({ ...formCoords, Raio: parseFloat(e.target.value) })}
                      type="number"
                      step="any"
                      className="customInputNumber w-full"
                    />
                  </div>
                </div>

                {labels.map((label, index) => (
                  <>
                    {label && label.campo === "FaixasFrequencia" ? (
                      <div className="ml-2 flex items-start gap-1 md:items-center flex-col md:flex-row">
                        <div className="flex items-center mr-1">
                          <span className="text-smp whitespace-nowrap">Faixas de Frequência</span>
                        </div>
                        <div className="flex items-center justify-center h-full">
                          <select
                            key={`${index}${label.campo}`}
                            className="customSelect w-full"
                            value={freqFilter ? freqFilter : ""}
                            onChange={(e) => {
                              setFreqFilter(e.target.value);
                            }}
                          >
                            <option key="filtrar" value="">
                              {globalState.filters[label.campo] !== undefined ? "Limpar" : "Filtrar"}
                            </option>
                            {label.distinctValues.map((value, index) => (
                              <option key={`${index}${value}`} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}

                {/* 
              <div className="whitespace-nowrap text-smp">
                <label className="md:hidden">Apagar Filtro de Coordenadas: </label>
                <Eraser onClick={handleEraseCoordFilter} className="w-5 h-5 cursor-pointer inline ml-2" />
              </div> 
              */}
              </div>
            </div>
            <div>
              <div className="ml-2 mt-2 flex md:justify-start gap-2 mb-4 md:mb-2">
                <button type="submit" className="agiliza-button-selected-2 text-smp w-20">
                  Aplicar
                </button>
                <button
                  type="button"
                  className="agiliza-button-unselected-2 text-smp w-20"
                  onClick={() => closeLatLangClearFilter()}
                >
                  Cancelar
                </button>
              </div>
            </div>
            <div className="ml-2">
              <div className="text-left">
                {faixaFreqLatLangError && <p className="text-red-500 text-smp ">{faixaFreqLatLangMessage}</p>}
              </div>
              <div className="text-left">{latLangErr && <p className="text-red-500 text-smp ">{latLangErr}</p>}</div>

              {formCoords.error !== "" && (
                <div className="mt-4 flex items-start gap-3">
                  <p className="text-red-500 text-smp">{formCoords.error}</p>
                </div>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default FaixaFreqLatLanFilter;
