import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import CNPJService from "../services/CNPJService";

import { RootState } from "../store";
import { ICNPJ } from "../Interfaces/ICNPJ";

const initialState: ICNPJ = {
  cnpjError: false,
  cnpjSuccess: false,
  cnpjAddSuccess: false,
  cnpjLoading: false,
  cnpjMessage: "",
  cnpjToCheck: "",
  cnpjList: [],
  cnpjConfigList: [],
};

//post
export const checkCNPJ = createAsyncThunk("cnpj/checkCNPJ", async (user: any, thunkAPI) => {
  const res = await CNPJService.checkCNPJ(user);

  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors);
  }
  return res;
});

//get
export const getUserCNPJ = createAsyncThunk("cnpj/getUserCNPJ", async (_, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await CNPJService.getUserCNPJ(token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

//post
export const addCNPJ = createAsyncThunk("cnpj/addCNPJ", async (user: any, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await CNPJService.addCNPJ(user, token);

  //check for errors
  if (res.error) {
    return thunkAPI.rejectWithValue(res.error);
  }
  return res;
});

//post
export const hiddenCNPJ = createAsyncThunk("cnpj/hiddenCNPJ", async (user: any, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await CNPJService.hiddenCNPJ(user, token);

  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors);
  }
  return res;
});

export const CNPJSlice = createSlice({
  name: "cnpj",
  initialState,
  reducers: {
    reset: (state) => {
      state.cnpjError = false;
      state.cnpjLoading = false;
      state.cnpjSuccess = false;
      state.cnpjAddSuccess = false;
      state.cnpjMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // checkCNPJ
      .addCase(checkCNPJ.pending, (state) => {
        state.cnpjError = false;
        state.cnpjLoading = true;
        state.cnpjSuccess = false;
      })
      .addCase(checkCNPJ.fulfilled, (state, action: PayloadAction<any>) => {
        state.cnpjError = false;
        state.cnpjLoading = false;
        state.cnpjSuccess = true;
        state.cnpjToCheck = action.payload;
        state.cnpjList = [...state.cnpjList, action.payload];
      })
      .addCase(checkCNPJ.rejected, (state, action: PayloadAction<any>) => {
        state.cnpjError = true;
        state.cnpjLoading = false;
        state.cnpjSuccess = false;
        state.cnpjMessage = action.payload[0];
        state.cnpjToCheck = action.payload[1];
      })
      // getUserCNPJ
      .addCase(getUserCNPJ.pending, (state) => {
        state.cnpjError = false;
        state.cnpjLoading = true;
        state.cnpjSuccess = false;
      })
      .addCase(getUserCNPJ.fulfilled, (state, action: PayloadAction<any>) => {
        state.cnpjError = false;
        state.cnpjLoading = false;
        state.cnpjSuccess = true;
        state.cnpjConfigList = action.payload;
      })
      .addCase(getUserCNPJ.rejected, (state, action: PayloadAction<any>) => {
        state.cnpjError = true;
        state.cnpjLoading = false;
        state.cnpjSuccess = false;
        state.cnpjMessage = action.payload;
      })
      // addCNPJ
      .addCase(addCNPJ.pending, (state) => {
        state.cnpjError = false;
        state.cnpjLoading = true;
        state.cnpjSuccess = false;
      })
      .addCase(addCNPJ.fulfilled, (state, action: PayloadAction<any>) => {
        state.cnpjError = false;
        state.cnpjLoading = false;
        state.cnpjAddSuccess = true;
        state.cnpjConfigList = action.payload[0];
        state.cnpjMessage = action.payload[1];
      })
      .addCase(addCNPJ.rejected, (state, action: PayloadAction<any>) => {
        state.cnpjLoading = false;
        state.cnpjAddSuccess = false;
        state.cnpjError = true;
        state.cnpjMessage = action.payload?.message || "Erro desconhecido";
      })
      // hiddenCNPJ
      .addCase(hiddenCNPJ.pending, (state) => {
        state.cnpjError = false;
        state.cnpjLoading = true;
        state.cnpjSuccess = false;
      })
      .addCase(hiddenCNPJ.fulfilled, (state, action: PayloadAction<any>) => {
        state.cnpjError = false;
        state.cnpjLoading = false;
        state.cnpjSuccess = true;
        state.cnpjConfigList = action.payload;
      })
      .addCase(hiddenCNPJ.rejected, (state, action: PayloadAction<any>) => {
        state.cnpjError = true;
        state.cnpjLoading = false;
        state.cnpjSuccess = false;
        state.cnpjMessage = action.payload;
      });
  },
});

export const { reset } = CNPJSlice.actions;
export const cnpjSelector = (state: RootState) => state.CNPJReducer;
export default CNPJSlice.reducer;
