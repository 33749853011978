import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import LocationService from "../services/LocationService";
import { RootState } from "../store";
import { ILocationStates, Station } from "../Interfaces/ILocation";

const initialState: ILocationStates = {
  error: false,
  success: false,
  loading: false,
  message: "",
  locations: [],
  networks: [],
  setstate: false,
  page: 10,
  filters: 0,
  showpoints: false,
};

//get all locations
export const getLocations = createAsyncThunk("locations/getLocations", async (data: any, thunkAPI): Promise<any> => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await LocationService.getLocations(data, token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const LocationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    reset: (state) => {
      state.error = false;
      state.loading = false;
      state.success = false;
      state.message = "";
      state.filters = 0;
    },
    resetLoading: (state) => {
      state.loading = false;
    },
    updateStationName: (state, action: PayloadAction<{ id: string; field: keyof Station; value: string }>) => {
      const { id, field, value } = action.payload;
      state.locations.forEach((location) => {
        const station = location.stations.find((station) => station._id === id);
        if (station) {
          (station[field] as string) = value; // Atualiza o campo específico da estação
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.fulfilled, (state, action) => {
        state.error = false;
        state.success = true;
        state.loading = false;
        state.locations = action.payload[0];
        state.filters = action.payload[1];
        state.page = !action.payload[2] ? 1 : action.payload[2];
        state.networks = action.payload[3] ? action.payload[3] : [];
      })
      .addCase(getLocations.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.message = action.payload as string;
      });
  },
});

export const { reset, resetLoading, updateStationName } = LocationSlice.actions;
export const locationSelector = (state: RootState) => state.LocationReducer;
export default LocationSlice.reducer;
