import { useEffect, useState } from "react";
import { Menu } from "lucide-react";
import useAppDispatch from "../../hooks/useAppDispatch";
import { logout } from "../../slices/LoginSlice";
import { useAuth } from "../../hooks/useAuth";
import Logo from "../../presentational/logo/Logo";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import classNames from "../../utils/classNames";
import { useLocation } from "react-router-dom";

export default function NavBar() {
  const location = useLocation();
  const { auth } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState("");

  /**
   * Usei esse artifício para fechar esse menu quando eu mudo de página
   * carece de testes. useLocation é interessante pois quando mudo de URL
   * ele me retorna a nova URL, aí uso useEffect para chamar a função para fechar o menu
   */
  useEffect(() => {
    // Função para fechar o menu
    const fecharMenu = () => {
      setOpenSubMenu("");
    };

    // Chama a função para fechar o menu sempre que a localização muda
    fecharMenu();
  }, [location]);

  const dispatch = useAppDispatch();
  const handleLogout = () => dispatch(logout());

  return (
    <>
      <div
        className="bg-top-digital-op-40 flex justify-between py-2"
        tabIndex={0}
        onBlur={(e) => (e.relatedTarget === null ? setOpenSubMenu("") : null)}
      >
        <Logo auth={auth} />
        {auth && (
          <>
            <div className={classNames("mx-4 lg:mx-12 mt-3", auth ? "flex justify-between" : "")}>
              <div className="lg:hidden">
                <button
                  type="button"
                  className="block text-gray-500 focus:outline-none"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <span className="sr-only">Abrir o menu</span>
                  <Menu className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <DesktopMenu openSubMenu={openSubMenu} setOpenSubMenu={setOpenSubMenu} handleLogout={handleLogout} />
          </>
        )}
      </div>
      <MobileMenu
        openSubMenu={openSubMenu}
        setOpenSubMenu={setOpenSubMenu}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        handleLogout={handleLogout}
      />
    </>
  );
}
