/**
 * Converte uma data no formato ISO (como armazenada no MongoDB) para o formato de data brasileiro.
 * Esta função é útil para a apresentação de datas no formato local em interfaces de usuário ou relatórios.
 *
 * @param {string|Date} isoDate - A data no formato ISO a ser convertida.
 * @param {string} [format] - Opcional. Se fornecido e igual a "DT", a função também incluirá a hora no resultado.
 * @returns {string} A data convertida no formato brasileiro, ou um traço ("-") se a entrada for inválida ou a data não puder ser parseada.
 */
const convertMongoDateToBRFormat = (isoDate: any, format?: string): string => {
  /** Verifica se a entrada é nula ou não é uma string, retornando um traço como placeholder para valor inválido. */
  if (!isoDate || typeof isoDate !== "string") {
    return "-";
  }

  /** Cria um objeto Date da string ISO fornecida. */
  const date = new Date(isoDate);

  /** Se o objeto Date não é válido (não representa uma data), retorna um traço. */
  if (isNaN(date.getTime())) {
    return "-";
  }

  /** Extrai e formata os componentes da data usando métodos UTC para evitar problemas de fuso horário. */
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear().toString();

  /** Extrai e formata os componentes de hora, minuto e segundo, também usando métodos UTC. */
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  /** Verifica se o formato com hora foi solicitado e retorna a data e hora formatada conforme especificado. */
  if (format === "DT") {
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  /** Retorna apenas a data formatada se o formato de hora não foi solicitado. */
  return `${day}/${month}/${year}`;
};

/** Exporta a função para uso em outras partes do aplicativo. */
export default convertMongoDateToBRFormat;
