import { useState, useEffect, FormEvent } from "react";
import { useParams } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

import { urlapp } from "../../utils/config";

import { checkToken, setNewPassword, forgotPasswordSelector } from "../../slices/ForgotPasswordSlice";
import RecoveryError from "../../components/forgot-password/RecoveryError";
import RecoverySucess from "../../components/forgot-password/RecoverySucess";
import ImageOffLine from "../../presentational/image-main/ImageMain";

const RecoveryPassword = () => {
  const { tokenRecoveryConfirm } = useParams();
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkToken(tokenRecoveryConfirm));
  }, [dispatch, tokenRecoveryConfirm]);

  const { isCheckingToken, isTokenValid, isNewPasswordSet, hasError, errorMessage } =
    useAppSelector(forgotPasswordSelector);

  if (hasError && errorMessage) {
    setPasswordError(errorMessage);
  }

  if (isCheckingToken) return <p>Validando seu token...</p>;

  const validatePassword = (password: string, confirmPassword: string): boolean => {
    if (password.length < 8) {
      setPasswordError("A senha precisa ter pelo menos 8 caracteres");
      return false;
    }

    if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)) {
      setPasswordError("A senha precisa conter ao menos 8 caractéres com pelo menos uma letra maiúscula e um número");
      return false;
    }

    if (password !== confirmPassword) {
      setPasswordError("As senhas não conferem");
      return false;
    }

    setPasswordError("");
    return true;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validatePassword(password, confirmPassword)) {
      dispatch(setNewPassword(password, tokenRecoveryConfirm));
    }
  };

  return (
    <div className="flex w-full">
      <div className="flex min-h-full flex-1 flex-col justify-center md:justify-start px-6 py-2 md:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-16 w-auto"
            src={`${urlapp}/img/logo-antena-top-digital-agi.png`}
            alt="Your Company"
          />
        </div>
        {isNewPasswordSet ? (
          <RecoverySucess />
        ) : (
          <>
            {hasError || !isTokenValid ? (
              <RecoveryError />
            ) : (
              <>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-top-digital-content-color">
                  Recuperação de senha
                </h2>
                <p className="text-left text-smp mt-4 text-top-digital-content-color">
                  Seu token foi validado. Agora você pode cadastrar uma nova senha.
                </p>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                  <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-smp font-medium leading-6 top-digital-content-color"
                      >
                        Nova senha
                      </label>
                      <div className="mt-2">
                        <input
                          id="password"
                          name="password"
                          autoComplete="password"
                          required
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          className="customInputText"
                        />
                      </div>
                      {passwordError && (
                        <p className="mt-2 text-smp text-red-600" id="email-error">
                          {passwordError}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className="block text-smp font-medium leading-6 top-digital-content-color"
                      >
                        Repita a nova senha
                      </label>
                      <div className="mt-2">
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          autoComplete="confirmPassword"
                          required
                          type="password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                          className="customInputText"
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-smp font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Cadastrar nova senha
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <ImageOffLine />
    </div>
  );
};

export default RecoveryPassword;
