import { api, requestConfig } from "../utils/config";

//get All Equipamentos

const createProtocol = async (data: any, token: string | undefined) => {
  const config = requestConfig("POST", data, token);
  try {
    const res = await fetch(api + "/licensing", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

const getProtocol = async (token: string | undefined) => {
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(api + "/licensing", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.error(error);
  }
};

const updateProtocol = async (data: any, token: string | undefined) => {
  const config = requestConfig("PUT", data, token);
  try {
    const res = await fetch(api + "/licensing", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

const deleteProtocol = async (data: any, token: string | undefined) => {
  const config = requestConfig("DELETE", data, token);
  try {
    const res = await fetch(api + "/licensing", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

const getProtocolUrl = async (protocol: string, token: string | undefined) => {
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(api + "/licensing/protocolurl/" + protocol, config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.error(error);
  }
};

const getUsersMailing = async (protocol: string, token: string | undefined) => {
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(api + "/licensing/getusersmailing/" + protocol, config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.error(error);
  }
};

const setUserToMailing = async (data: any, token: string | undefined) => {
  const config = requestConfig("PUT", data, token);
  try {
    const res = await fetch(api + "/licensing/setemail", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

const setNewProtocolName = async (data: any, token: string | undefined) => {
  const config = requestConfig("PUT", data, token);
  try {
    const res = await fetch(api + "/licensing/setprotocolname", config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

//setNewProtocolName

const LicensingService = {
  createProtocol,
  getProtocol,
  updateProtocol,
  deleteProtocol,
  getProtocolUrl,
  getUsersMailing,
  setUserToMailing,
  setNewProtocolName,
};

export default LicensingService;
