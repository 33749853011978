import { useEffect, Fragment, useState, FormEvent } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

import { licensingSelector, setNewProtocolName, resetSuccessSetNameProtocol } from "../../slices/LicensingSlice";

type Props = {
  protocolProcessId: any;
  protocolName: string;
};

const LicensingSetNewName = ({ protocolProcessId, protocolName }: Props) => {
  const dispatch = useAppDispatch();
  const { setNameProtocolSuccess } = useAppSelector(licensingSelector);
  const [open, setOpen] = useState(false);
  const [currentProtocolName, setCurrentProtocolName] = useState(protocolName ? protocolName : "");
  useEffect(() => {
    if (setNameProtocolSuccess) {
      setCurrentProtocolName("");
      setOpen(false);
      dispatch(resetSuccessSetNameProtocol());
    }
  }, [dispatch, setNameProtocolSuccess]);

  const handleSetNewProtocolName = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setNewProtocolName({ protocolProcessId, protocolName: currentProtocolName }));
  };

  return (
    <>
      <div>
        <button onClick={() => setOpen(true)}>
          <label
            className="underline cursor-pointer"
            title="Crie ou Altere um nome para o protocolo"
            htmlFor="criar ou alterar um nome para o protocolo"
          >
            {protocolName ? protocolName : "Nomeie o protocolo"}
          </label>
        </button>

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                    <form onSubmit={(e) => handleSetNewProtocolName(e)}>
                      <div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            Insira aqui um nome para o protocolo
                          </Dialog.Title>
                          <div className="mt-2">
                            <input
                              type="text"
                              placeholder="Insira aqui um nome para o protocolo"
                              value={currentProtocolName}
                              onChange={(e) => setCurrentProtocolName(e.target.value)}
                              name="protocolName"
                              id="protocolName"
                              autoComplete="protocol"
                              className="customInputText"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-2 justify-end mt-5 sm:mt-6">
                        <button type="submit" className="customButton">
                          Cadastrar
                        </button>
                        <button type="button" className="customButton" onClick={() => setOpen(false)}>
                          Cancelar
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

export default LicensingSetNewName;
