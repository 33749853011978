import { ChevronDownIcon } from "lucide-react";
import { NavLink } from "react-router-dom";
import { links } from "./Mylinks";
import Logout from "../../presentational/logout/Logout";
import { useAuth } from "../../hooks/useAuth";
import classNames from "../../utils/classNames";

interface MobileMenuProps {
  openSubMenu: string;
  mobileMenuOpen: boolean;
  setOpenSubMenu: (name: string) => void;
  setMobileMenuOpen: (name: boolean) => void;
  handleLogout: (e: React.MouseEvent<HTMLElement>) => void;
}

const MobileMenu = ({
  openSubMenu,
  setOpenSubMenu,
  mobileMenuOpen,
  setMobileMenuOpen,
  handleLogout,
}: MobileMenuProps) => {
  const { userData } = useAuth();

  return (
    <div className={classNames("bg-top-digital bg-opacity-25 lg:hidden", mobileMenuOpen ? "block" : "hidden")}>
      <nav className="px-2 pb-4 flex flex-col">
        <div className="ml-2 my-2">
          <NavLink key="desktop-home" to="/" className="flex text-sm">
            Home
          </NavLink>
        </div>
        {links.map((category) => {
          const { name, allowedRoles, sublinks } = category;
          return (
            userData &&
            allowedRoles.includes(userData.role) && (
              <div key={`mobile-${name}`}>
                <button
                  className="flex p-2 rounded text-sm"
                  onClick={() => {
                    if (openSubMenu === name) setOpenSubMenu("");
                    else setOpenSubMenu(name);
                  }}
                >
                  {name} <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
                <div
                  key={`mobile1-${name}`}
                  className={classNames("rounded-xl ml-4 lg:ml-0", openSubMenu === name ? "block" : "hidden")}
                >
                  <hr />
                  {sublinks.map((sublink) => {
                    return (
                      <NavLink
                        key={`mobile-${sublink.name}`}
                        to={sublink.link}
                        onClick={() => setMobileMenuOpen(false)}
                        className="flex p-2 rounded text-sm"
                      >
                        {sublink.name}
                      </NavLink>
                    );
                  })}
                  <hr />
                </div>
              </div>
            )
          );
        })}
        <div className="px-2 py-2">
          <Logout handleLogout={handleLogout} />
        </div>
      </nav>
    </div>
  );
};

export default MobileMenu;
