import { useEffect, useState } from "react";

//context
import { useGlobalContext } from "../../hooks/useGlobalContext";

//redux
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { stationSelector, getStationById } from "../../slices/StationSlice";
import { docTypeSelector } from "../../slices/DocumentTypeSlice";
import { insertDoc, documentSelector } from "../../slices/DocumentSlice";

//components
import DatePicker from "react-datepicker";

type Props = {
  stationId?: string;
  systemId?: string;
  docTypeId?: string;
  rowId?: string;
  uploadDocumentModal?: boolean;
  hasExpirationDate?: boolean;
};

const DocumentUpload = ({
  stationId,
  systemId,
  docTypeId: docTypeIdReport,
  rowId,
  uploadDocumentModal,
  hasExpirationDate = false,
}: Props) => {
  //context
  const { globalState, dispatchGlobalState } = useGlobalContext();

  //usestate
  const [formdocument, setFormDocument] = useState<File>();
  const [title, setTitle] = useState<string>("");
  const [docTypeId, setDocTypeId] = useState<string>("0");
  const [docError, setDocError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  //redux
  const dispatch = useAppDispatch();
  const { station, stationSuccess } = useAppSelector(stationSelector);
  const { docTypes } = useAppSelector(docTypeSelector);
  const { error, deleteSuccess } = useAppSelector(documentSelector);

  /**
   * Se station tive no campo CodTiposClasseEstacao que é um o Array o Valor ML
   * Então DocTypes não pode conter campos cujo o valor docTyes.mlStations seja true
   * Ou seja docTypes precisa de um filter ances de gerar o select box
   */

  const shouldExcludeMLDocs = station?.CodTiposClasseEstacao.includes("ML");
  const filteredDocTypes = docTypes.filter((docType) => !shouldExcludeMLDocs || !docType.mlStations);

  const stationDocId = stationId !== undefined && stationId !== null && stationId !== "" ? stationId : station!._id;

  const submitHandle = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Verifique se o formulário é válido antes de enviar
    if (!formdocument || title === "" || docTypeId === "0") {
      if (title === "") setDocError("Favor inserir um título para o documento");
      else if (docTypeId === "0") setDocError("Favor selecionar um tipo de documento");
      else if (!formdocument) setDocError("Favor selecionar um arquivo para upload");
      return;
    }

    setIsSubmitting(true); // Defina o estado de envio como verdadeiro

    //build form data
    const formData = new FormData();
    formData.append("stationId", stationDocId);
    formData.append("title", title);
    formData.append("typeId", docTypeId);
    formData.append("document", formdocument, formdocument.name);
    if (uploadDocumentModal && systemId !== undefined && systemId !== null && systemId !== "") {
      formData.append("systemId", systemId);
      formData.append("uploadDocumentModal", uploadDocumentModal.toString());
    }
    if (expirationDate instanceof Date) {
      formData.append("expirationDate", expirationDate.toISOString());
    }

    await dispatch(insertDoc(formData));

    setIsSubmitting(false); // Defina o estado de envio como falso
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const doc = e?.target?.files?.[0];
    if (!doc) return;
    setFormDocument(doc);
  };

  useEffect(() => {
    dispatch(getStationById(stationId));
  }, [stationId, dispatch, stationSuccess, globalState.openedLabelStationForm, deleteSuccess]);

  return (
    <>
      {globalState.openedUploadForm && (
        <div className="bg-white border border-top-digital-primary-light rounded-lg overflow-auto p-2">
          {docError && <div className="text-red-600 text-smp">{docError}</div>}
          {error && (
            <div className="text-red-600 text-smp">
              Erro ao inserir arquivo. Se o problema persistir, entre em contato com o suporte Agiliza.
            </div>
          )}
          <h2 className="md:text-base text-smp text-top-digital-content-color font-semibold mb-2 font-top-digital-content">
            Upload de documentação
          </h2>
          <h3 className="md:text-sm text-smp text-top-digital-content-color font-normal mb-2 font-top-digital-content">
            {station?.Label && station.Label !== "" ? station.Label : station?.EnderecoEstacao}
          </h3>

          <div className="mt-6">
            <form encType="multipart/form-data" onSubmit={submitHandle}>
              <div className="mb-5">
                <label htmlFor="title" className="mt-3 block text-smp font-medium text-top-digital-content-color mb-2">
                  Título do documento
                </label>
                <input
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  name="title"
                  id="title"
                  min="0"
                  className="customInputText w-full"
                  disabled={isSubmitting}
                />
              </div>
              <div className="mb-5">
                <label htmlFor="typeDoc" className="block text-smp font-medium text-top-digital-content-color mb-2">
                  Tipo do documento
                </label>
                <select
                  name="typeDoc"
                  value={docTypeId}
                  onChange={(e) => setDocTypeId(e.target.value)}
                  id="typeDoc"
                  className="customInputText w-full"
                  disabled={isSubmitting}
                >
                  <option key="0" value="0">
                    Selecione um Tipo
                  </option>
                  {filteredDocTypes &&
                    filteredDocTypes.length > 0 &&
                    filteredDocTypes.map((docType) => (
                      <option key={docType._id} value={docType._id}>
                        {docType.typeName}
                      </option>
                    ))}
                </select>
              </div>

              {hasExpirationDate && (
                <div className="mb-5">
                  <label className="block text-smp font-medium text-top-digital-content-color mb-2">
                    Prazo de Validade
                  </label>
                  <DatePicker
                    locale="pt-BR"
                    selected={expirationDate}
                    onChange={setExpirationDate}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    showMonthDropdown={false}
                    name="expirationDate"
                    id="expirationDate"
                    className="customInputText cursor-pointer"
                    placeholderText="Selecionar Data"
                    readOnly={false}
                  />
                </div>
              )}

              <div className="mb-5 bg-top-digital-transparent-gray-500-8 flex flex-col rounded-md">
                <div className="flex justify-start items-center mb-2 px-4 pt-2">
                  <label htmlFor="document" className="inline-flex text-sm font-normal text-top-digital-content-color">
                    Selecione o arquivo para upload (PDF, Word, Excel)
                  </label>
                </div>
                <div>
                  <input
                    type="file"
                    onChange={handleFile}
                    name="document"
                    id="document"
                    min="0"
                    className="customInputFile border-none text-smp"
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div>
                <div className="flex gap-1">
                  <button
                    className="agiliza-button-unselected-2"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatchGlobalState({ type: "CLOSE_UPLOAD_DOC_FORM" });
                      setDocError("");
                    }}
                    disabled={isSubmitting}
                  >
                    Cancelar
                  </button>
                  <button type="submit" className="agiliza-button-selected-2 w-20 h-8" disabled={isSubmitting}>
                    {isSubmitting ? "Enviando..." : "Enviar"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentUpload;
