import { api, requestConfig } from "../utils/config";

//get All Equipamentos

const getReportDocument = async (objFilter: any, token: string | undefined) => {
  const config = requestConfig("GET", null, token);
  const queryParams = new URLSearchParams(objFilter).toString();
  try {
    //const res = await fetch(api + "/stations/" + id, config)
    const url = `${api}/documents/reportdocuments/?${queryParams}`;

    const res = await fetch(url, config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.error(error);
  }
};

//setNewProtocolName

const ReportDocumentService = {
  getReportDocument,
};

export default ReportDocumentService;
