import { NavLink } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const NavProfile = () => {
  const { userData } = useAuth();

  return (
    <div className="mb-1 w-7 h-7 ml-2 rounded-full bg-white flex items-center justify-center overflow-hidden">
      <NavLink to="/profile" className="text-sm font-semibold text-gray-600">
        {userData && userData.name.charAt(0).toUpperCase()}
      </NavLink>
    </div>
  );
};

export default NavProfile;
