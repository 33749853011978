//Hooks
import { useEffect, useState, useMemo } from "react";
import type { ColumnDef } from "@tanstack/react-table";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import LicensingTable from "./LicensingTable";
import LicensingURL from "./LicensingURL";

import { licensingSelector, getProtocol } from "../../slices/LicensingSlice";
import LicensingModal from "./LicensingModal";
import { TLicensing } from "../../Interfaces/ILicensing";
import convertMongoDateToBRFormat from "../../utils/dateBR";
import LicensingUserMailModal from "./LicensingUserMailModal";
import LicensingSetNewName from "./LicensingSetNewName";
import LicensingFontSemiBold from "./LicensingFontSemiBold";

const Licensing = () => {
  const dispatch = useAppDispatch();
  const {
    success,
    createProtocolSuccess,
    protocol,
    dateLastProtocolUpdate,
    loading,
    setNameProtocolSuccess,
    successSetEmail,
  } = useAppSelector(licensingSelector);
  const [newProtocolId, setnewProtocolId] = useState(false);

  useEffect(() => {
    dispatch(getProtocol());
  }, [dispatch, createProtocolSuccess, successSetEmail, setNameProtocolSuccess, success]);

  const cols = useMemo<ColumnDef<TLicensing>[]>(
    () => [
      {
        header: "Protocolo ID",
        cell: (row) => <LicensingURL protocolProcessId={row.renderValue()} urlProcess={row.row.original.urlProcess} />,
        accessorKey: "protocolProcessId",
      },
      {
        header: "Nome do Protocolo",
        cell: (row) => (
          <LicensingSetNewName
            protocolProcessId={row.row.original.protocolProcessId}
            protocolName={row.row.original.nameProtocol || ""}
          />
        ),
        accessorKey: "protocolProcessId_name",
      },
      {
        header: "Tipo de Processo",
        cell: (row) => row.renderValue(),
        accessorKey: "tipoProcesso",
      },
      {
        header: "Data da Geração",
        cell: (row) => convertMongoDateToBRFormat(row.renderValue()),
        accessorKey: "dataGeracao",
      },
      {
        header: "Empresa Solicitante",
        cell: (row) => <LicensingFontSemiBold text={row.renderValue()} />,
        accessorKey: "empresasInteressadas",
      },
      {
        header: "Email's Habilitados",
        cell: (row) => <LicensingUserMailModal protocolProcessId={row.row.original.protocolProcessId} />,
        accessorKey: "protocolProcessId_email",
      },
      {
        header: "Último Protocolo",
        cell: (row) => convertMongoDateToBRFormat(row.renderValue()),
        accessorKey: "dateLastProtocolUpdate",
      },
      {
        header: "Último Andamento",
        cell: (row) => convertMongoDateToBRFormat(row.renderValue(), "DT"),
        accessorKey: "dateLastProgressUpdate",
      },
      {
        header: "Último e-mail enviado",
        cell: (row) => convertMongoDateToBRFormat(row.renderValue(), "DT"),
        accessorKey: "dateLastNotificationUpdate",
      },
    ],
    []
  );

  const handleCreateProtocol = () => {
    setnewProtocolId(true);
  };

  if (loading) return <div>Carregando...</div>;

  return (
    <>
      <div className="flex-col items-start">
        <div className="font-semibold font-top-digital-content text-xl md:text-lg text-top-digital-content-color mt-6 md:leading-none">
          <h2>Gestão de protocolos de licenciamento</h2>
        </div>
        <div className="font-top-digital-content text-smp md:text-lg text-top-digital-content-color mb-0 md:leading-none">
          {dateLastProtocolUpdate && (
            <span className="text-smp">Data da última consulta: {dateLastProtocolUpdate} </span>
          )}
        </div>
      </div>
      <div className="mt-2 flex flex-col items-center">
        <div className="mx-auto w-full">
          <div className="flex w-full justify-end">
            <button onClick={handleCreateProtocol} className="customButton">
              Cadastrar Protocolo
            </button>
          </div>
        </div>
        {newProtocolId && <LicensingModal setnewProtocolId={setnewProtocolId} />}
        {protocol && protocol.length === 0 ? (
          <>
            <div className="mt-10 mx-auto w-full max-w-sm text-sm text-center">
              Você ainda não possui nenhum protocolo cadastrado.
            </div>
            <div className="mt-10 mx-auto w-full max-w-sm text-sm text-center">
              <button onClick={handleCreateProtocol} className="customButton">
                Cadastrar Primeiro Protocolo
              </button>
            </div>
          </>
        ) : (
          <LicensingTable data={protocol} columns={cols} />
        )}
      </div>
    </>
  );
};

export default Licensing;
