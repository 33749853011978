import { useEffect } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

import {
  linkSystemDocSelector,
  setDocToSystem,
  removeDocFromSystem,
  resetLinkSystemDoc,
} from "../../slices/LinkSystemDocSlice";
import Enabled from "../../components/Utils/Enabled";

type Props = {
  document: string;
  system: string;
  linked: boolean;
  rowId?: string;
};

const SystemActionLinkDoc = ({ document, system, linked, rowId }: Props) => {
  const dispatch = useAppDispatch();
  const { successLinkSystem, loading } = useAppSelector(linkSystemDocSelector);

  const handleIsLinked = (systemId: string) => {
    const obj = {
      system: systemId,
      document: document,
    };
    if (linked) {
      dispatch(removeDocFromSystem(obj));
    } else {
      dispatch(setDocToSystem(obj));
    }
  };

  useEffect(() => {
    dispatch(resetLinkSystemDoc());
  }, [dispatch, successLinkSystem]);

  return (
    <span
      title="Avançar"
      className={`${loading ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
      onClick={() => {
        if (!loading) {
          handleIsLinked(system);
        }
      }}
    >
      <Enabled key={`enable-${rowId && rowId + "-"}${system}-${document}`} enabled={linked} />
    </span>
  );
};

export default SystemActionLinkDoc;
