import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ProfileService from "../services/ProfileService";
import { IProfileStates } from "../Interfaces/IProfileStates";
import { RootState } from "../store";
import { withAuth } from "../utils/withAuth";
import { UpdatePayload, UpdatedByAdminPayload, UpdatedByNotAdminPayload } from "../Interfaces/IRegisterStates";

const initialState: IProfileStates = {
  profileError: false,
  profileSuccess: false,
  profileLoading: false,
  profileFormSuccess: false,
  profileUser: null,
  profileDaysUntilTrialEnds: null,
  profileMessage: "",
};

//get user detalis
export const profile = createAsyncThunk("profile/profile", async (_, thunkAPI): Promise<any> => {
  return await withAuth(ProfileService.profile);
});

export const getDaysUntilTrialEnds = createAsyncThunk("profile/getDaysUntilTrialEnds", async (_, thunkAPI) => {
  const appState = thunkAPI.getState() as RootState;
  const token = appState.LoginReducer.user!;
  const res = await ProfileService.getDaysUntilTrialEnds(token);
  //check for errors
  if (res.errors) {
    return thunkAPI.rejectWithValue(res.errors[0]);
  }
  return res;
});

export const update = createAsyncThunk(
  "profile/update",
  async ({ userUpdate, roleUpdate }: UpdatePayload, thunkAPI): Promise<any> => {
    const res = await withAuth(ProfileService.update, { userUpdate, roleUpdate });
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  }
);

export const userUpdatedByAdmin = createAsyncThunk(
  "profile/userUpdatedByAdmin",
  async ({ userUpdate, roleUpdate }: UpdatedByAdminPayload, thunkAPI): Promise<any> => {
    const res = await withAuth(ProfileService.userUpdatedByAdmin, { userUpdate, roleUpdate });
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  }
);

export const userUpdatedByNotAdmin = createAsyncThunk(
  "profile/userUpdatedByNotAdmin",
  async ({ userUpdate, roleUpdate }: UpdatedByNotAdminPayload, thunkAPI): Promise<any> => {
    const res = await withAuth(ProfileService.userUpdatedByNotAdmin, { userUpdate, roleUpdate });
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  }
);

//dataUpdatedByNotAdmin

export const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfile: (state) => {
      state.profileError = false;
      state.profileLoading = false;
      state.profileSuccess = false;
      state.profileFormSuccess = false;
      state.profileMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(update.pending, (state) => {
        state.profileError = false;
        state.profileLoading = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.profileError = false;
        state.profileLoading = false;
        state.profileFormSuccess = true;
        state.profileMessage = action.payload.message || "";
        state.profileUser = action.payload.user;
      })
      .addCase(update.rejected, (state, action) => {
        state.profileError = false;
        state.profileLoading = false;
        state.profileMessage = typeof action.payload === "string" ? action.payload : "";
        state.profileUser = null;
      })
      .addCase(userUpdatedByAdmin.pending, (state) => {
        state.profileError = false;
        state.profileLoading = true;
      })
      .addCase(userUpdatedByAdmin.fulfilled, (state, action) => {
        state.profileError = false;
        state.profileLoading = false;
        state.profileFormSuccess = true;
        state.profileMessage = action.payload.message || "";
        state.profileUser = action.payload.user;
      })
      .addCase(userUpdatedByAdmin.rejected, (state, action) => {
        state.profileError = false;
        state.profileLoading = false;
        state.profileMessage = "";
        state.profileUser = null;
      })
      .addCase(userUpdatedByNotAdmin.pending, (state) => {
        state.profileError = false;
        state.profileLoading = true;
        state.profileSuccess = false;
      })
      .addCase(userUpdatedByNotAdmin.fulfilled, (state, action) => {
        state.profileError = false;
        state.profileLoading = false;
        state.profileFormSuccess = true;
        state.profileMessage = action.payload.message || "";
        state.profileUser = action.payload.user;
      })
      .addCase(userUpdatedByNotAdmin.rejected, (state, action) => {
        state.profileError = true;
        state.profileLoading = false;
        state.profileSuccess = false;
        state.profileMessage = "";
        state.profileUser = null;
      })
      .addCase(profile.pending, (state) => {
        state.profileError = false;
        state.profileLoading = true;
      })
      .addCase(profile.fulfilled, (state, action) => {
        state.profileError = false;
        state.profileLoading = false;
        state.profileSuccess = true;
        state.profileMessage = action.payload.message || "";
        state.profileUser = action.payload.user;
      })
      .addCase(profile.rejected, (state, action) => {
        state.profileError = false;
        state.profileLoading = false;
        state.profileUser = null;
        state.profileMessage = typeof action.payload === "string" ? action.payload : "";
      })
      .addCase(getDaysUntilTrialEnds.pending, (state) => {
        state.profileError = false;
        state.profileLoading = true;
      })
      .addCase(getDaysUntilTrialEnds.fulfilled, (state, action) => {
        state.profileError = false;
        state.profileLoading = false;
        state.profileSuccess = true;
        state.profileMessage = action.payload.message || "";
        state.profileDaysUntilTrialEnds = action.payload.daysToExpiration || null;
        //state.profileDaysUntilTrialEnds = -1;
      })
      .addCase(getDaysUntilTrialEnds.rejected, (state, action) => {
        state.profileError = false;
        state.profileLoading = false;
        state.profileDaysUntilTrialEnds = null;
        state.profileMessage = typeof action.payload === "string" ? action.payload : "";
      });
  },
});

export const { resetProfile } = ProfileSlice.actions;
export const profileSelector = (state: RootState) => state.ProfileReducer;
export default ProfileSlice.reducer;
