import { useEffect, useMemo, useState, useCallback } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { TReportDocument } from "../../Interfaces/IReportDocument";
import { useLocation, useNavigate } from "react-router-dom";
import FrequencyFormatter from "../../components/Utils/FrequencyFormatter";
import PowerFormatter from "../../components/Utils/PowerFormatter";
import DocumentTypeFormater from "../../components/Utils/DocumentTypeFormater";

//import { useGlobalContext } from "../../hooks/useGlobalContext";

import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";

import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";

//redux
import { reportDocumentSelector, getReportDocument } from "../../slices/ReportDocumentSlice";
import { getVisibleFields } from "../../slices/ConfigStationSlice";

import AttachDocument from "../../components/Utils/AttachDocument";
import LinkToStation from "../../components/Utils/LinkToStation";
import { ChevronDown, ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { ChevronUp } from "lucide-react";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import DocumentRequirement from "../../components/Document/DocumentRequirement";
import DocumentSituationColor from "../../components/Utils/DocumentSituationColor";
import DocumentExpirationDate from "../../components/Document/DocumentExpirationDate";

declare module "@tanstack/table-core" {
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const ReportDocuments = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [updateTable, setUpdateTable] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const cnpj = queryParams.get("cnpj") || "";
  const fistel = queryParams.get("fistel") || "";
  const uf = queryParams.get("uf") || "";
  const municipio = queryParams.get("municipio") || "";
  const user = queryParams.get("user") || "";
  const requisito = queryParams.get("requisito") || "";
  const tipodocumento = queryParams.get("tipodocumento") || "";
  const situacaodocumento = queryParams.get("situacaodocumento") || "";
  const stationid = queryParams.get("stationid") || "";
  const stationorsystem = queryParams.get("stationorsystem") || "";
  const status = queryParams.get("status") || "";

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [cnpjFilter, setCnpjFilter] = useState(cnpj);
  const [fistelFilter, setFistelFilter] = useState(fistel);
  const [ufFilter, setUfFilter] = useState(uf);
  const [municipioFilter, setMunicipioFilter] = useState(municipio);
  const [userFilter, setUserFilter] = useState(user);
  const [tipodocumentoFilter, setTipodocumentoFilter] = useState(tipodocumento);
  const [requisitoFilter, setRequisitoFilter] = useState(requisito);
  const [situacaodocumentoFilter, setSituacaodocumentoFilter] = useState(situacaodocumento);
  const [stationIdFilter] = useState(stationid);
  const [stationOrSystemFilter, setStationOrSystemFilter] = useState(stationorsystem);
  const [statusFilter, setStatusFilter] = useState(status);

  const dispatch = useAppDispatch();

  const { reportDocument } = useAppSelector(reportDocumentSelector);

  //chamando o reducer para config
  useEffect(() => {
    dispatch(getVisibleFields());
  }, [dispatch]);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  //const [globalFilter, setGlobalFilter] = useState("");

  const updateUrlWithFilters = useCallback(() => {
    const queryParams = new URLSearchParams();
    if (cnpjFilter) queryParams.set("cnpj", cnpjFilter || "");
    if (fistelFilter) queryParams.set("fistel", fistelFilter || "");
    if (ufFilter) queryParams.set("uf", ufFilter || "");
    if (municipioFilter) queryParams.set("municipio", municipioFilter || "");
    if (userFilter) queryParams.set("user", userFilter || "");
    if (requisitoFilter) queryParams.set("requisito", requisitoFilter || "");
    if (tipodocumentoFilter) queryParams.set("tipodocumento", tipodocumentoFilter || "");
    if (situacaodocumentoFilter) queryParams.set("situacaodocumento", situacaodocumentoFilter || "");
    if (stationIdFilter) queryParams.set("stationid", stationIdFilter || "");
    if (stationOrSystemFilter) queryParams.set("stationorsystem", stationOrSystemFilter || "");
    if (statusFilter) queryParams.set("status", statusFilter || "");

    const newUrl = `/reportdocuments/?${queryParams.toString()}`;

    navigate(newUrl);
  }, [
    cnpjFilter,
    fistelFilter,
    ufFilter,
    municipioFilter,
    userFilter,
    tipodocumentoFilter,
    situacaodocumentoFilter,
    stationIdFilter,
    stationOrSystemFilter,
    requisitoFilter,
    statusFilter,
    navigate,
  ]);

  const resetFilters = useCallback(() => {
    setCnpjFilter("");
    setFistelFilter("");
    setUfFilter("");
    setMunicipioFilter("");
    setUserFilter("");
    setTipodocumentoFilter("");
    setRequisitoFilter("");
    setSituacaodocumentoFilter("");
    setStationOrSystemFilter("");
    setStatusFilter("");
    setIsFilterActive(false);
    setIsLoading(false);
    navigate("/reportdocuments/");
  }, [navigate]);

  useEffect(() => {
    updateUrlWithFilters();
  }, [updateUrlWithFilters]);

  useEffect(() => {
    const filterActive =
      cnpjFilter !== "" ||
      fistelFilter !== "" ||
      ufFilter !== "" ||
      municipioFilter !== "" ||
      userFilter !== "" ||
      requisitoFilter !== "" ||
      tipodocumentoFilter !== "" ||
      situacaodocumentoFilter !== "" ||
      stationIdFilter !== "" ||
      stationOrSystemFilter !== "" ||
      statusFilter !== "";
    // Atualiza o estado `isFilterActive`
    setIsFilterActive(filterActive);

    const filterObj = {
      cnpj: cnpjFilter,
      fistel: fistelFilter,
      uf: ufFilter,
      municipio: municipioFilter,
      user: userFilter,
      requisito: requisitoFilter,
      tipodocumento: tipodocumentoFilter,
      filterActive: filterActive,
      situacaodocumento: situacaodocumentoFilter,
      stationid: stationIdFilter,
      stationorsystem: stationOrSystemFilter,
      status: statusFilter,
    };

    setIsLoading(true);

    dispatch(getReportDocument(filterObj))
      .then((res) => {
        if (res.payload && res.payload.result.length === 0 && queryParams.size > 0) {
          resetFilters();
          dispatch(
            getReportDocument({
              cnpj: "",
              fistel: "",
              uf: "",
              municipio: "",
              user: "",
              requisito: "",
              tipodocumento: "",
              filterActive: false,
              situacaodocumento: "",
              stationid: "",
              stationorsystem: "",
              status: "",
            })
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    dispatch,
    cnpjFilter,
    fistelFilter,
    ufFilter,
    municipioFilter,
    userFilter,
    tipodocumentoFilter,
    requisitoFilter,
    isFilterActive,
    situacaodocumentoFilter,
    stationIdFilter,
    stationOrSystemFilter,
    statusFilter,
    updateTable,
    queryParams.size,
    resetFilters,
  ]);

  const systemFreqRX: string = "systemFreqRX";
  const systemFreqTX: string = "systemFreqTX";
  const systemPower: string = "systemPower";
  const docTypeName: string = "docTypeName";
  const docTypeId: string = "docTypeId";
  const isRequired: string = "isRequired";
  const docFileName: string = "docFileName";
  const numEstacao: string = "numEstacao";
  const stationId: string = "stationId";
  const systemId: string = "systemId";
  const documentStatus: string = "documentStatus";
  const hasExpirationDate: string = "hasExpirationDate";
  const expirationDate: string = "expirationDate";
  const documentId: string = "documentId";
  const docExtension: string = "docExtension";
  const azimute: string = "azimute";
  const id: string = "_id";

  const columns = useMemo<ColumnDef<TReportDocument, any>[]>(
    () => [
      {
        accessorKey: "documentStatus",
        header: "Situação",
        cell: (row) => {
          return <DocumentSituationColor documentStatus={row.row.getValue(documentStatus)} />;
        },
      },
      {
        accessorKey: "numEstacao",
        header: "Numero da Estação",
        cell: (row) => {
          return <LinkToStation numStation={row.row.getValue(numEstacao)} stationId={row.row.getValue(stationId)} />;
        },
        filterFn: "equals",
      },
      {
        accessorKey: "stationName",
        header: "Nome da Estação",
        filterFn: "equals",
      },
      {
        accessorKey: "systemName",
        header: "Nome do Sistema",
        filterFn: "equals",
      },
      {
        accessorKey: "docTypeId",
        header: "Tipo de Documento",
        filterFn: "equals",
      },
      {
        accessorKey: "docTypeName",
        header: "Tipo de Documento",
        cell: (row) => {
          return (
            <DocumentTypeFormater
              typeName={row.row.getValue(docTypeName)}
              isRequired={row.row.getValue(isRequired)}
              showRequirement={false}
            />
          );
        },
        filterFn: "equals",
      },
      {
        accessorKey: "_id",
        header: "id",
        filterFn: "equals",
      },
      {
        accessorKey: "hasExpirationDate",
        header: "hasExpirationDate",
        filterFn: "equals",
      },
      {
        accessorKey: "documentId",
        header: "docId",
        filterFn: "equals",
      },
      {
        accessorKey: "docExtension",
        header: "extensao",
        filterFn: "equals",
      },
      {
        accessorKey: "docFileName",
        header: "Documento",
        cell: (row) => {
          return (
            <>
              <AttachDocument
                key={`${row.row.getValue(id)}`}
                fileName={row.row.getValue(docFileName)}
                stationId={row.row.getValue(stationId)}
                systemId={row.row.getValue(systemId)}
                docTypeId={row.row.getValue(docTypeId)}
                hasExpirationDate={row.row.getValue(hasExpirationDate)}
                updateTable={updateTable}
                setUpdateTable={setUpdateTable}
                rowId={row.row.getValue(id)}
                documentId={row.row.getValue(documentId)}
                docExtension={row.row.getValue(docExtension)}
              />
            </>
          );
        },
        filterFn: "equals",
      },
      {
        accessorKey: "userId",
        header: "Usuário",
        filterFn: "equals",
      },
      {
        accessorKey: "isRequired",
        header: "requerido",
        filterFn: "equals",
      },
      {
        accessorKey: "stationId",
        header: "Estação",
        filterFn: "equals",
      },

      {
        accessorKey: "systemId",
        header: "Sistema",
        filterFn: "equals",
      },
      {
        accessorKey: "docRequirement",
        header: "Requisito",
        cell: (row) => {
          return <DocumentRequirement isRequired={row.row.getValue(isRequired)} />;
        },
        filterFn: "equals",
      },
      {
        accessorKey: "expirationDate",
        header: "Prazo de Validade",
        cell: (row) => {
          return (
            <DocumentExpirationDate
              expirationDate={row.row.getValue(expirationDate)}
              hasExpirationDate={row.row.getValue(hasExpirationDate)}
            />
          );
        },
        filterFn: "equals",
      },
      {
        accessorKey: "stationOrSystem",
        header: "Estação | Sistema",
        filterFn: "equals",
      },
      {
        header: "Frequencia RX",
        accessorKey: "systemFreqRX",
        cell: (row) => {
          return <FrequencyFormatter frequency={row.row.getValue(systemFreqRX)} />;
        },
        filterFn: "equals",
      },
      {
        accessorKey: "systemFreqTX",
        header: "Frequencia TX",
        cell: (row) => {
          return <FrequencyFormatter frequency={row.row.getValue(systemFreqTX)} />;
        },
        filterFn: "equals",
      },
      {
        accessorKey: "systemPower",
        header: "Potência",
        cell: (row) => {
          return <PowerFormatter power={row.row.getValue(systemPower)} />;
        },
        filterFn: "equals",
      },
      {
        accessorKey: "azimute",
        header: "Azimute",
        cell: (row) => {
          return <>{parseInt(row.row.getValue(azimute)) > 0 ? row.row.getValue(azimute) : "-"}</>;
        },
        filterFn: "equals",
      },
    ],
    [updateTable]
  );

  const table = useReactTable({
    data: reportDocument.result,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    initialState: {
      sorting: [
        { id: "stationId", desc: true },
        { id: "systemId", desc: false },
      ],
    },
    state: {
      columnFilters,
      columnVisibility: {
        isRequired: false,
        hasExpirationDate: false,
        docExtension: false,
        documentId: false,
        stationId: false,
        systemId: false,
        userId: false,
        _id: false,
        docTypeId: false,
        updateTable: false,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
  });

  return (
    <div className="flex-col md:flex-row md:justify-start p-2 gap-5">
      <div>
        <div className="mt-2">
          <div className="flex text-lg items-center my-4 justify-between w-full font-semibold text-top-digital-black-light">
            Documentos
          </div>
          <div className="flex text-sm items-center my-4 justify-between w-full font-normal text-top-digital-black-light">
            Consulta de Documentos
          </div>

          <div className="text-smp grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-9 gap-1">
            <div className="flex-col items-center mt-2 gap-2">
              <div>Situação</div>
              <div>
                <select
                  name="status"
                  id="status"
                  value={status}
                  onChange={(e) => {
                    setStatusFilter(e.target.value);
                    updateUrlWithFilters();
                  }}
                  className="customSelect w-full"
                  disabled={isLoading}
                >
                  <option value="">Todos</option>
                  <option key="valido" value="valido">
                    Documento válido
                  </option>
                  <option key="opcional-invalido" value="opcional-invalido">
                    Sem documento opcional válido
                  </option>
                  <option key="obrigatorio-invalido" value="obrigatorio-invalido">
                    Sem documento obrigatório válido
                  </option>
                </select>
              </div>
            </div>
            <div className="flex-col items-center mt-2 gap-2">
              <div>CNPJ</div>
              <div>
                <select
                  name="cnpj"
                  id="cnpj"
                  value={cnpjFilter}
                  onChange={(e) => {
                    setCnpjFilter(e.target.value);
                    updateUrlWithFilters();
                  }}
                  className="customSelect w-full"
                  disabled={isLoading}
                >
                  <option value="">Todos</option>
                  {reportDocument.CNPJ.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex-col items-center mt-2 gap-2">
              <div>Fistel</div>
              <div>
                <select
                  name="fistel"
                  id="fislte"
                  value={fistelFilter}
                  onChange={(e) => {
                    setFistelFilter(e.target.value);
                    updateUrlWithFilters();
                  }}
                  className="customSelect w-full"
                  disabled={isLoading}
                >
                  <option value="">Todos</option>
                  {reportDocument.Fistel.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex-col items-center mt-2 gap-2">
              <div>Unidade Federativa</div>
              <div>
                <select
                  name="uf"
                  id="uf"
                  value={ufFilter}
                  onChange={(e) => {
                    setUfFilter(e.target.value);
                    updateUrlWithFilters();
                  }}
                  className="customSelect w-full"
                  disabled={isLoading}
                >
                  <option value="">Todos</option>
                  {reportDocument.Estado.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex-col items-center mt-2 gap-2">
              <div>Município</div>
              <div>
                <select
                  name="municipio"
                  id="municipio"
                  value={municipioFilter}
                  onChange={(e) => {
                    setMunicipioFilter(e.target.value);
                    updateUrlWithFilters();
                  }}
                  className="customSelect w-full"
                  disabled={isLoading}
                >
                  <option value="">Todos</option>
                  {reportDocument.Cidade.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex-col items-center mt-2 gap-2" hidden={true}>
              <div>Usuário</div>
              <div>
                <select
                  name="user"
                  id="user"
                  value={userFilter}
                  onChange={(e) => {
                    setUserFilter(e.target.value);
                    updateUrlWithFilters();
                  }}
                  className="customSelect w-full"
                  disabled={isLoading}
                >
                  <option value="">Todos</option>
                  {reportDocument.Usuario.map((item) => {
                    return (
                      <option key={item._id} value={item._id}>
                        {item.name} {item.lastname}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex-col items-center mt-2 gap-2">
              <div>Requisito</div>
              <div>
                <select
                  name="requisito"
                  id="requisito"
                  value={requisitoFilter}
                  onChange={(e) => {
                    setRequisitoFilter(e.target.value);
                    updateUrlWithFilters();
                  }}
                  className="customSelect w-full"
                  disabled={isLoading}
                >
                  <option value="">Todos</option>
                  <option key="obrigatorio" value="true">
                    Obrigatório
                  </option>
                  <option key="naoobrigatorio" value="false">
                    Não obrigatório
                  </option>
                </select>
              </div>
            </div>
            <div className="flex-col items-center mt-2 gap-2">
              <div>Tipo de Documento</div>
              <div>
                <select
                  name="tipodocumento"
                  id="tipodocumento"
                  value={tipodocumentoFilter}
                  onChange={(e) => {
                    setTipodocumentoFilter(e.target.value);
                    updateUrlWithFilters();
                  }}
                  className="customSelect w-full"
                  disabled={isLoading}
                >
                  <option value="">Todos</option>
                  {reportDocument.TipoDocumento.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="flex-col items-center mt-2 gap-2">
              <div>Com | Sem Documento</div>
              <div>
                <select
                  name="situacaodocumento"
                  id="situacaodocumento"
                  value={situacaodocumentoFilter}
                  onChange={(e) => {
                    setSituacaodocumentoFilter(e.target.value);
                    updateUrlWithFilters();
                  }}
                  className="customSelect w-full"
                  disabled={isLoading}
                >
                  <option value="">Todos</option>
                  <option key="com" value="com">
                    Com documento
                  </option>
                  <option key="sem" value="sem">
                    Sem documento
                  </option>
                </select>
              </div>
            </div>
            <div className="flex-col items-center mt-2 gap-2">
              <div>Estação | Sistema</div>
              <div>
                <select
                  name="stationorsystem"
                  id="stationorsystem"
                  value={stationOrSystemFilter}
                  onChange={(e) => {
                    setStationOrSystemFilter(e.target.value);
                    updateUrlWithFilters();
                  }}
                  className="customSelect w-full"
                  disabled={isLoading}
                >
                  <option value="">Todos</option>
                  <option key="Estação" value="station">
                    Estação
                  </option>
                  <option key="sem" value="system">
                    Sistema
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto">
        <div className="h-2" />
        <div className="mt-2 h-full w-full">
          <table className="text-left font-light w-full">
            <thead className="border-b bg-top-digital-op-40 font-medium dark:border-neutral-500">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="px-4 py-4 whitespace-nowrap font-top-digital-content font-semibold text-smp"
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                              className="flex"
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: <ChevronUp className="h-4 w-4" />,
                                desc: <ChevronDown className="h-4 w-4" />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody hidden={isLoading}>
              {table.getRowModel().rows.map((row, index) => {
                return (
                  <tr
                    key={row.id}
                    className={`${
                      !(index % 2) ? "bg-white" : "bg-top-digital-op-25"
                    } text-top-digital-content-color p-1`}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <td
                          key={cell.id}
                          className="px-4 py-4 whitespace-nowrap font-top-digital-content font-normal text-smp"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="flex justify-center w-100 mt-4 mb-4" hidden={!isLoading}>
            <div className="loader" hidden={!isLoading}></div>
          </div>

          <div
            className="font-normal text-sm text-center text-top-digital-black-light mt-4 mb-4"
            hidden={queryParams.size > 0}
          >
            Por favor, escolha pelo menos uma opção de filtro
          </div>

          <div className="h-2" />
          <nav
            className="items-center justify-between isolate text-smp inline-flex -space-x-px rounded-none"
            aria-label="Pagination"
          >
            <button
              className="relative inline-flex items-center h-8 rounded-l-md px-2 py-0 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Primeira</span>
              <ChevronDoubleLeftIcon className="h-3 w-4" aria-hidden="true" />
            </button>
            <button
              className="relative inline-flex items-center px-2 py-2 h-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Anterior</span>
              <ChevronLeftIcon className="h-3 w-4" aria-hidden="true" />
            </button>
            <button
              className="relative inline-flex items-center  px-2 h-8 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Próxima</span>
              <ChevronRightIcon className="h-3 w-4" aria-hidden="true" />
            </button>
            <button
              className="relative inline-flex items-center rounded-r-md px-2 h-8 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Última</span>
              <ChevronDoubleRightIcon className="h-3 w-4" aria-hidden="true" />
            </button>
            <div className="pl-2 flex flex-1 items-center justify-between">
              <p className="text-smp text-gray-700">
                <span className="font-medium">Página {table.getState().pagination.pageIndex + 1} </span> de{" "}
                {table.getPageCount()}
              </p>
            </div>
            <div className="flex justify-center items-center pl-2">
              <select
                className="customSelect mt-0 border-gray-300 focus:border-gray-300"
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="h-4" />
          </nav>
          <div className="text-smp mt-1 mb-2">
            <span className="font-semibold">{table.getPrePaginationRowModel().rows.length}</span> Registros
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDocuments;
