import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import useAppSelector from "./useAppSelector";
import { loginSelector } from "../slices/LoginSlice";

export const useAuth = () => {
  const { user } = useAppSelector(loginSelector);
  const userCookie = Cookies.get("user");
  let userData: any;
  if (userCookie) {
    userData = jwt_decode(userCookie);
  }

  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setAuth(true);
    } else {
      setAuth(false);
    }
    setLoading(false);
  }, [user]);

  return { auth, loading, userData };
};
