import { parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js";

const formatBRPhone = (phoneNumber: string): string => {
  const parsed: PhoneNumber | undefined = parsePhoneNumberFromString(phoneNumber, "BR");
  if (parsed) {
    return parsed.formatNational(); // ou parsed.formatInternational() se você preferir o formato internacional
  }
  return "";
};

export default formatBRPhone;
