import { Gender } from "../../Interfaces/IRegisterStates";

type ConfirmationProps = {
  email: string;
  gender: Gender;
  name: string;
  isChildRegister: boolean;
  setRegisterChildUser: React.Dispatch<React.SetStateAction<boolean>> | null;
};

const Confirmation: React.FC<ConfirmationProps> = ({ email, gender, name, isChildRegister, setRegisterChildUser }) => {
  let greeting;
  if (gender === "male") {
    greeting = "Prezado";
  } else if (gender === "female") {
    greeting = "Prezada";
  } else {
    greeting = "Olá";
  }

  return (
    <div className="flex justify-center items-start h-screen mt-10">
      <div className="w-4/5">
        {isChildRegister ? (
          <>
            <p className="text-sm text-justify">
              O usuário adicional foi adicionado com sucesso! Um email será enviado para <strong>{email}</strong>, onde
              o novo usuário poderá confirmar o cadastro e criar suas próprias credenciais. É crucial pedir para ele
              checar a caixa de spam caso não encontre o email de confirmação. Como administrador e responsável pelo
              cadastro, é sua tarefa definir o escopo de atuação para usuários Regulares e Visualizadores. Sem isso,
              eles não conseguirão acessar as estações que deverão gerir. Se tiver alguma dúvida, fique à vontade para
              entrar em contato com nosso suporte pelo email{" "}
              <a
                href="mailto:agiliza@topdigital.com.br"
                className="font-semibold hover:text-top-digital-link-hover hover:underline"
              >
                agiliza@topdigital.com.br
              </a>
              . No assunto, inclua o email de cadastro e, no corpo do email, informe que o email de confirmação não foi
              recebido. Tomaremos as medidas necessárias para confirmar o cadastro de outra forma.
            </p>
            <p>
              <button
                onClick={() => setRegisterChildUser && setRegisterChildUser(false)}
                type="submit"
                className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-smp font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                Retornar à Gestão de Usuários
              </button>
            </p>
          </>
        ) : (
          <>
            <h1 className="text-center text-lg font-semibold mb-4">Falta pouco</h1>
            <h2 className="text-center text-md mb-4 font-semibold">Verifique seu email {email}</h2>
            <p className="text-sm text-justify">
              {greeting} {name},
              <br />
              Estamos felizes em informar que seus dados foram cadastrados com sucesso. Você tem 24 horas para confirmar
              o cadastro <strong>clicando no link</strong> enviado para o seu email. É interessante checar a caixa de
              spam caso o email não tenha chegado. Qualquer dúvida, é só entrar em contato com nosso suporte através do
              email{" "}
              <label>
                <a
                  href="mailto:agiliza@topdigital.com.br"
                  className="font-semibold hover:text-top-digital-link-hover hover:underline"
                >
                  {" "}
                  agiliza@topdigital.com.br
                </a>
              </label>
              , informando no assunto o email de cadastro e no corpo do email apenas informe que o email de confirmação
              não chegou, pois iremos tomar as providências necessárias para confirmar o cadastro de outra forma.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Confirmation;
