import React, { useEffect } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

//redux
//primeira parte do componente: config e station
import { configSelector, getConfig } from "../../slices/ConfigSystemSlice";
import { systemSelector, getSystemById } from "../../slices/SystemSlice";
import { TSystem } from "../../Interfaces/ISystem";
import { X } from "lucide-react";

//hooks
import { useGlobalContext } from "../../hooks/useGlobalContext";

//components
import DocumentsSystem from "../Document/DocumentsSystem";
import SystemField from "./SystemField";

type Props = {
  systemId: string;
  reportDocuments?: boolean;
};

const SystemDetails = ({ systemId, reportDocuments = false }: Props) => {
  const { dispatchGlobalState } = useGlobalContext();
  const dispatch = useAppDispatch();
  const { system } = useAppSelector(systemSelector);
  const { config } = useAppSelector(configSelector);

  let absolutePosition: string = "absolute top-0 right-0";
  if (reportDocuments) {
    absolutePosition = "";
  }

  const handleCloseLabelSystemForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatchGlobalState({ type: "CLOSE_SYSTEM_DETAILS_AND_LABEL_SYSTEM_FORM" });
  };

  useEffect(() => {
    const obj = {
      systemId,
    };
    dispatch(getSystemById(obj));
    dispatch(getConfig());
  }, [dispatch, systemId]);

  return (
    <div className={`${absolutePosition} bg-white  w-full h-full`}>
      <div>
        <div className="flex justify-between items-center">
          <h1 className="md:text-base text-smp text-top-digital-content-color font-semibold mb-2 font-top-digital-content">
            Dados do Sistema{" "}
            {system?.Label !== "" ? (
              system?.Label
            ) : (
              <span className="text-smp font-light">(esse sistema não possui apelido)</span>
            )}
          </h1>
          {!reportDocuments && (
            <button onClick={(e) => handleCloseLabelSystemForm(e)}>
              <X className="cursor-pointer w-4 h-4 mb-2" />
            </button>
          )}
        </div>
        <DocumentsSystem systemId={systemId} />
        <div className="grid grid-cols-1 text-sm mt-4 mb-1 text-top-digital-content-color font-semibold font-top-digital-content text-left">
          Detalhes do sistema
        </div>
        {config &&
          system &&
          config.map(
            (conf, index) =>
              conf.visible && (
                <div key={`${conf._id}`} className="grid grid-cols-2">
                  <SystemField
                    key={`${conf._id}`}
                    index={index}
                    label={conf.label}
                    campo={conf.campo}
                    system={system as TSystem}
                    reportDocuments={reportDocuments}
                  />
                </div>
              )
          )}
      </div>{" "}
      {/* Esta é a tag </div> que estava faltando */}
    </div>
  );
};

export default SystemDetails;
