import { useState, useEffect } from "react";
import DocIcon from "../../components/Utils/DocIcon";

type Props = {
  exportloading: boolean;
  exporterror: boolean;
};
const ExportStates = ({ exportloading, exporterror }: Props) => {
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prevDotCount) => (prevDotCount === 3 ? 1 : prevDotCount + 1));
    }, 500); // Atualiza a cada 500ms

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {exportloading ? (
        <div className="flex items-end justify-start">
          <div>
            <DocIcon extension=".pdf" width={30} height={30} />
          </div>
          <div className="text-smp">exportando para PDF{Array(dotCount + 1).join(".")}</div>
        </div>
      ) : (
        <div className="flex items-end justify-start">
          <div>
            <DocIcon extension=".pdf" width={30} height={30} />
          </div>{" "}
          <div className="text-smp">exportar para PDF</div>
          {exporterror && <div className="ml-1 cursor-none text-smp text-red-600">Erro ao exportar para PDF</div>}
        </div>
      )}
    </>
  );
};

export default ExportStates;
