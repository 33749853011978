//hooks
import { useEffect, useState } from "react";

import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

//redux

import {
  docTypeSelector,
  deleteDocType,
  insertDocType,
  getDocTypes,
  updateDocType,
} from "../../slices/DocumentTypeSlice";

//icons
import { Edit, X } from "lucide-react";
import { TDocType } from "../../Interfaces/IDocType";

type Props = {};

const DocType = (props: Props) => {
  const dispatch = useAppDispatch();
  const [docLoading] = useState<boolean>(false);
  const [openedForm, setOpenedForm] = useState<boolean>(false);
  const [idLabel, setIdLabel] = useState<string>("");
  const [typeName, setTypeName] = useState<string>("");
  const [toStation, setToStation] = useState<boolean>(false);
  const [toSystem, setToSystem] = useState<boolean>(false);
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [hasExpirationDate, setHasExpirationDate] = useState<boolean>(false);
  const [docTypeError, setDocTypeError] = useState<string>("");
  const [hiddeSelector, setHiddeSelector] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<boolean>(false);

  function toggle(value: boolean): boolean {
    return !value;
  }

  const { success, docTypes } = useAppSelector(docTypeSelector);

  useEffect(() => {
    const stationObj = {
      stationId: "",
      systemId: "",
      uploadDocumentModal: false,
    };
    dispatch(getDocTypes(stationObj));

    if(refreshList) {
      setRefreshList(false);
    }
  }, [dispatch, success, refreshList]);

  const handleFormEdit = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, doc: TDocType) => {
    window.scrollTo(0, 0);
    setHiddeSelector(true);
    setOpenedForm(true);
    setIdLabel(doc._id);
    setTypeName(doc.typeName);
    setToStation(doc.toStation);
    setToSystem(doc.toSystem);
    setIsRequired(doc.isRequired);
    setHasExpirationDate(doc.hasExpirationDate);
  };

  const handleDelete = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, doc: TDocType) => {
    e.preventDefault();
    dispatch(deleteDocType(doc._id));
    setRefreshList(true);
  };

  const handleSelectRelation = (e: string) => {
    if (e === "estacao") {
      setToStation(true);
      setToSystem(false);
    }
    else if (e === "sistema") {
      setToStation(false);
      setToSystem(true);
    }
  };

  const submitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (typeName === "") {
      setDocTypeError("Favor inserir um título para o documento");
      return;
    }

    if (!toStation && !toSystem) {
      setDocTypeError("Favor selecionar um tipo de documento");
      return;
    }

    if (idLabel === "") {
      const data = {
        typeName,
        toStation,
        toSystem,
        isRequired,
        hasExpirationDate
      };
      dispatch(insertDocType(data));
    } else {
      const data = {
        id: idLabel,
        typeName,
        toStation,
        toSystem,
        isRequired,
        hasExpirationDate
      };
      dispatch(updateDocType(data));
    }

    setOpenedForm(false);
    setIdLabel("");
    setTypeName("");
    setToStation(false);
    setToSystem(false);
    setIsRequired(false);
    setHasExpirationDate(false);
    setHiddeSelector(false);
    setRefreshList(true);
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 w-2/3 mt-2 mx-auto">
        <div className="sm:flex sm:items-center mx-auto">
          <div className="sm:flex-auto">
            <h1 className="text-smp font-semibold leading-6 top-digital-content-color">Tipos de Documento</h1>
            <p className="mt-2 text-smp text-gray-700">
              Crie o tipo de documento que será utilizado no sistema, podendo ser um documento de estação ou de sistema.
            </p>
            {docTypeError && <div className="mt-2 text-red-600 text-smp">{docTypeError}</div>}
          </div>
          {!openedForm && (
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpenedForm(true);
                }}
                type="button"
                className="block rounded-md bg-top-digital  hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Criar
              </button>
            </div>
          )}
        </div>

        {docLoading ? (
          <p>Aguarde....</p>
        ) : (
          <>
            {openedForm && (
              <div className="mt-4">
                <form onSubmit={submitHandle} className="w-[80%] md:w-[50%]">
                  <label htmlFor="typeName" className="block text-smp font-medium leading-6 top-digital-content-color">
                    Tipo de Documento
                  </label>
                  <div className="mt-2 mb-4">
                    <input
                      type="text"
                      value={typeName}
                      onChange={(e) => setTypeName(e.target.value)}
                      name="typeName"
                      id="typeName"
                      className="customInputText"
                      placeholder="Exemplo: Laudo Radiométrico"
                    />
                  </div>

                  <div className="mb-4" hidden={hiddeSelector}>
                    <label htmlFor="typeName" className="block text-smp font-medium leading-6 top-digital-content-color mb-2">
                      Relação do Tipo de Documento
                    </label>
                    
                    <select className="customInputText" onChange={(e) => handleSelectRelation(e.target.value)}>
                      <option value={''} hidden></option>
                      <option value={'estacao'}>Estação</option>
                      <option value={'sistema'}>Sistema</option>
                    </select>
                    <p className="mt-2 text-smp text-gray-700">
                      Selecione a que entidade este documento deve ser relacionado.
                    </p>
                  </div>
                  
                  <fieldset>
                    <legend className="sr-only">Cadastro de tipos de documentos</legend>

                    <div className="space-y-5">
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            aria-describedby="offers-description"
                            type="checkbox"
                            checked={isRequired}
                            name="isRequired"
                            id="isRequired"
                            onChange={() => setIsRequired(toggle)}
                            className="customInputRadio"
                          />
                        </div>
                        <div className="ml-3 text-smp leading-6">
                          <label htmlFor="offers" className="font-medium top-digital-content-color">
                            Documento Obrigatório
                          </label>
                          <p id="offers-isRequired" className="text-gray-500">
                            Marque essa opção se o documento for obrigatório.
                          </p>
                        </div>
                      </div>

                      <div className="relative flex items-start" style={{display: hiddeSelector ? 'none' : 'flex'}}>
                        <div className="flex h-6 items-center">
                          <input
                            aria-describedby="offers-description"
                            type="checkbox"
                            checked={hasExpirationDate}
                            name="hasExpirationDate"
                            id="hasExpirationDate"
                            onChange={() => setHasExpirationDate(toggle)}
                            className="customInputRadio"
                          />
                        </div>
                        <div className="ml-3 text-smp leading-6">
                          <label htmlFor="offers" className="font-medium top-digital-content-color">
                            Documento com Prazo de Validade
                          </label>
                          <p id="offers-isRequired" className="text-gray-500">
                            Marque essa opção se o documento possuir prazo de validade.
                          </p>
                        </div>
                      </div>
                    </div>

                  </fieldset>

                  <div className="mt-4">
                    <div className="flex gap-1">
                      <button
                        type="submit"
                        className="block rounded-md bg-top-digital  hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                      >
                        Enviar
                      </button>
                      <button
                        className="block rounded-md bg-top-digital  hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenedForm(false);
                          setIdLabel("");
                          setTypeName("");
                          setToStation(false);
                          setToSystem(false);
                          setIsRequired(false);
                          setDocTypeError("");
                          setHiddeSelector(false);
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {docTypes && docTypes.length !== 0 && (
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 border">
                      <thead>
                        <tr className="bg-top-digital-op-40">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-smp font-semibold top-digital-content-color sm:pl-0"
                          >
                            <span className="md:block hidden">Tipo do Documento</span>
                            <span className="md:hidden">Tipo</span>
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                          >
                            Estação
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                          >
                            Sistema
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                          >
                            Obrigatório
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                          >
                            Prazo de Validade
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                          >
                            Editar
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                          >
                            Remover
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        <>
                          {docTypes &&
                            docTypes.map((doc, index) => (
                              <tr
                                key={index}
                                className={`${
                                  !(index % 2) ? "bg-white" : "bg-top-digital-op-25"
                                } text-top-digital-content-color p-1`}
                              >
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-smp font-medium top-digital-content-color sm:pl-0">
                                  {doc.typeName}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                                  {doc.toStation ? "Sim" : "Não"}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                                  {doc.toSystem ? "Sim" : "Não"}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                                  {doc.isRequired ? "Sim" : "Não"}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                                  {doc.hasExpirationDate ? "Sim" : "Não"}
                                </td>
                                {doc.isEditable ? (
                                  <>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-smp font-medium sm:pr-0">
                                      <div
                                        className={`${
                                          !(index % 2) ? "bg-white" : "bg-top-digital-op-25"
                                        } text-top-digital-content-color p-1`}
                                      >
                                        <Edit
                                          className="cursor-pointer w-4 h-4"
                                          onClick={(e) => handleFormEdit(e, doc)}
                                        />
                                      </div>{" "}
                                    </td>

                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-smp font-medium sm:pr-0">
                                      <div
                                        className={`${
                                          !(index % 2) ? "bg-white" : "bg-top-digital-op-25"
                                        } text-top-digital-content-color p-1`}
                                      >
                                        <X className="cursor-pointer w-4 h-4" onClick={(e) => handleDelete(e, doc)} />
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">Não Autorizado</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">Não Autorizado</td>
                                  </>
                                )}
                              </tr>
                            ))}
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DocType;
