import React from "react";

type Props = {
  extension: string;
  width?: number;
  height?: number;
};

const DocIcon = ({ extension, width = 20, height = 20 }: Props) => {
  return (
    <>
      {extension && extension === ".pdf" && (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 256 256">
          <g fill="none" strokeMiterlimit="10" strokeWidth="1">
            <path
              fill="#B83535"
              d="M11.58 54.882v31.965A3.153 3.153 0 0014.733 90h60.534a3.153 3.153 0 003.153-3.153V54.882c-22.347-7.001-44.628-7.044-66.84 0z"
              transform="matrix(2.81 0 0 2.81 1.407 1.407)"
            ></path>
            <path
              fill="#E9E9E0"
              d="M78.42 54.882V18.345C69.386 13.658 63.133 7.61 60.075 0H14.733a3.153 3.153 0 00-3.153 3.153v51.729h66.84z"
              transform="matrix(2.81 0 0 2.81 1.407 1.407)"
            ></path>
            <path
              fill="#D9D7CA"
              d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z"
              transform="matrix(2.81 0 0 2.81 1.407 1.407)"
            ></path>
            <path
              fill="#F9F9F9"
              d="M32.116 62.679h-5.944a1.5 1.5 0 00-1.5 1.5v16.602a1.5 1.5 0 103 0v-5.248h4.444a4.343 4.343 0 004.338-4.338v-4.177a4.343 4.343 0 00-4.338-4.339zm1.338 8.515c0 .737-.6 1.338-1.338 1.338h-4.444v-6.854h4.444a1.34 1.34 0 011.338 1.339v4.177zM46.109 82.28h-5.652a1.5 1.5 0 01-1.5-1.5V64.179a1.5 1.5 0 011.5-1.5h5.652a4.635 4.635 0 014.63 4.63V77.65a4.635 4.635 0 01-4.63 4.63zm-4.152-3h4.152c.898 0 1.63-.731 1.63-1.63V67.309c0-.898-.731-1.63-1.63-1.63h-4.152V79.28zM63.828 62.679h-8.782a1.5 1.5 0 00-1.5 1.5V80.78a1.5 1.5 0 003 0v-6.801h4.251a1.5 1.5 0 000-3h-4.251v-5.301h7.282a1.5 1.5 0 000-2.999z"
              transform="matrix(2.81 0 0 2.81 1.407 1.407)"
            ></path>
          </g>
        </svg>
      )}
      {extension && (extension === ".docx" || extension === ".doc") && (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
          <path fill="#2196F3" d="M41 10H25v28h16a1 1 0 001-1V11a1 1 0 00-1-1z"></path>
          <path
            fill="#FFF"
            d="M25 15.001h14V17H25zM25 19h14v2H25zm0 4.001h14v2H25zm0 4h14V29H25zM25 31h14v2.001H25z"
          ></path>
          <path fill="#0D47A1" d="M27 42L6 38V10l21-4z"></path>
          <path
            fill="#FFF"
            d="M21.167 31.012H18.45l-1.802-8.988a9.328 9.328 0 01-.174-1.576h-.032c-.043.637-.11 1.162-.197 1.576l-1.85 8.988h-2.827l-2.86-14.014h2.675l1.536 9.328c.062.404.111.938.143 1.607h.042a9.962 9.962 0 01.223-1.645l1.97-9.291h2.622l1.785 9.404c.062.348.119.846.17 1.511h.031c.02-.515.073-1.035.16-1.563l1.503-9.352h2.468l-2.869 14.015z"
          ></path>
        </svg>
      )}
      {extension && (extension === ".xls" || extension === ".xlsx") && (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 256 256">
          <g fill="none" strokeMiterlimit="10" strokeWidth="1" transform="matrix(2.81 0 0 2.81 1.407 1.407)">
            <path
              fill="#FFF"
              d="M80.959 78.79H19.13a2.876 2.876 0 01-2.876-2.876V14.085a2.876 2.876 0 012.876-2.876h61.829a2.876 2.876 0 012.876 2.876v61.829a2.876 2.876 0 01-2.876 2.876z"
            ></path>
            <path
              fill="#19754C"
              d="M80.959 80.79H19.13a4.881 4.881 0 01-4.876-4.875v-61.83a4.882 4.882 0 014.876-4.876h61.829a4.882 4.882 0 014.876 4.876v61.83a4.88 4.88 0 01-4.876 4.875zM19.13 13.209a.877.877 0 00-.876.876v61.83c0 .482.393.875.876.875h61.829a.877.877 0 00.876-.875v-61.83a.877.877 0 00-.876-.876H19.13z"
            ></path>
            <rect width="15.93" height="4" x="61.05" y="20.47" fill="#19754C" rx="0" ry="0"></rect>
            <rect width="15.93" height="4" x="61.05" y="31.74" fill="#19754C" rx="0" ry="0"></rect>
            <rect width="15.93" height="4" x="61.05" y="43" fill="#19754C" rx="0" ry="0"></rect>
            <rect width="15.93" height="4" x="61.05" y="54.26" fill="#19754C" rx="0" ry="0"></rect>
            <rect width="15.93" height="4" x="61.05" y="65.53" fill="#19754C" rx="0" ry="0"></rect>
            <rect width="15.93" height="4" x="39.76" y="20.47" fill="#19754C" rx="0" ry="0"></rect>
            <rect width="15.93" height="4" x="39.76" y="31.74" fill="#19754C" rx="0" ry="0"></rect>
            <rect width="15.93" height="4" x="39.76" y="43" fill="#19754C" rx="0" ry="0"></rect>
            <rect width="15.93" height="4" x="39.76" y="54.26" fill="#19754C" rx="0" ry="0"></rect>
            <rect width="15.93" height="4" x="39.76" y="65.53" fill="#19754C" rx="0" ry="0"></rect>
            <path fill="#19754C" d="M51.33 90L6.17 78.79 6.17 11.21 51.33 0z"></path>
            <path
              fill="#FFF"
              d="M38.15 28.21L31.01 28.62 26.67 37.72 22.56 29.1 15.8 29.48 23.2 45 15.8 60.52 22.56 60.9 26.67 52.28 31.01 61.38 38.15 61.79 30.14 45z"
            ></path>
          </g>
        </svg>
      )}
    </>
  );
};

export default DocIcon;
