import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StationService from "../services/StationService";
import { RootState } from "../store";
import { IEditableFieldsStates } from "../Interfaces/IEditableFieldsStates";
import { updateStationName } from "./LocationSlice"; // Importe a ação do LocationSlice

const initialState: IEditableFieldsStates = {
  editableFieldStationError: false,
  editableFieldStationLoading: false,
  editableFieldStationSuccess: false,
  editableFieldStationMessage: "",
};

//set Label for Station
export const setEditableFieldStationValue = createAsyncThunk(
  "labelstation/setLabelStation",
  async (data: any, thunkAPI): Promise<any> => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await StationService.updateStationField(data, token);
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }

    // Despacha a ação para atualizar o estado de locations
    thunkAPI.dispatch(updateStationName({ id: data.idStation, field: data.field, value: data.value }));

    return res;
  }
);

export const EditableFieldStationSlice = createSlice({
  name: "labelstation",
  initialState,
  reducers: {
    resetEditableField: (state) => {
      state.editableFieldStationError = false;
      state.editableFieldStationLoading = false;
      state.editableFieldStationSuccess = false;
      state.editableFieldStationMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setEditableFieldStationValue.fulfilled, (state, action) => {
        state.editableFieldStationError = false;
        state.editableFieldStationLoading = false;
        state.editableFieldStationSuccess = true;
        if (action.payload.field !== undefined) {
          state.editableFieldStationMessage = action.payload.field;
        }
      })
      .addCase(setEditableFieldStationValue.pending, (state) => {
        state.editableFieldStationError = false;
        state.editableFieldStationLoading = true;
      })
      .addCase(setEditableFieldStationValue.rejected, (state, action) => {
        state.editableFieldStationError = false;
        state.editableFieldStationLoading = false;
        state.editableFieldStationLoading = false;
        state.editableFieldStationMessage = typeof action.payload === "string" ? action.payload : "";
      });
  },
});

export const { resetEditableField } = EditableFieldStationSlice.actions;
export const editableFieldStationSelector = (state: RootState) => state.EditableFieldStationReducer;
export default EditableFieldStationSlice.reducer;
