import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IChangePassword } from "../Interfaces/IChangePassword";
import ChangePasswordService from "../services/ChangePasswordService";
import { withAuth } from "../utils/withAuth";

const initialState: IChangePassword = {
  changePasswordRequested: false,
  changePasswordSucceeded: false,
  changePasswordFailed: false,
};

export const requestChangePassword = ({ actualPassword, newPassword }: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changePasswordRequested());

      const res = await withAuth(ChangePasswordService.changePassword, actualPassword, newPassword);
      if (res.isPasswordChanged) return dispatch(changePasswordSucceeded());

      return dispatch(changePasswordFailed());
    } catch (err) {
      return dispatch(changePasswordFailed());
    }
  };
};

export const ChangePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    changePasswordRequested: (state) => {
      state.changePasswordRequested = true;
      state.changePasswordFailed = false;
      state.changePasswordSucceeded = false;
    },
    changePasswordSucceeded: (state) => {
      state.changePasswordRequested = false;
      state.changePasswordSucceeded = true;
    },
    changePasswordFailed: (state) => {
      state.changePasswordRequested = false;
      state.changePasswordFailed = true;
    },
  },
});

export const { reset, changePasswordRequested, changePasswordSucceeded, changePasswordFailed } =
  ChangePasswordSlice.actions;
export const changePasswordSelector = (state: RootState) => state.ChangePasswordReducer;
export default ChangePasswordSlice.reducer;
