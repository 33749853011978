import { rankItem, rankings } from "@tanstack/match-sorter-utils";
import type { RankingInfo } from "@tanstack/match-sorter-utils";
import type { Row } from "@tanstack/react-table";

// Função fuzzy adaptada para verificar se o valor é nulo ou indefinido
const fuzzy = <TData extends Record<string, any> = {}>(
  row: Row<TData>,
  columnId: string,
  filterValue: string | number,
  addMeta: (item: RankingInfo) => void
) => {
  const rawValue = row.getValue(columnId);
  const value = rawValue != null ? rawValue.toString() : "";
  const itemRank = rankItem(value, filterValue as string, {
    threshold: rankings.MATCHES,
  });
  addMeta(itemRank);
  return itemRank.passed;
};

fuzzy.autoRemove = (val: any) => !val;

// Função contains adaptada para verificar se o valor é nulo ou indefinido
const contains = <TData extends Record<string, any> = {}>(
  row: Row<TData>,
  id: string,
  filterValue: string | number
) => {
  const rawValue = row.getValue(id);
  const value = rawValue != null ? rawValue.toString() : "";
  return value.toLowerCase().includes(filterValue.toString().toLowerCase());
};

contains.autoRemove = (val: any) => !val;

// Função startsWith adaptada para verificar se o valor é nulo ou indefinido
const startsWith = <TData extends Record<string, any> = {}>(
  row: Row<TData>,
  id: string,
  filterValue: string | number
) => {
  const rawValue = row.getValue(id);
  const value = rawValue != null ? rawValue.toString() : "";
  return value.toLowerCase().startsWith(filterValue.toString().toLowerCase());
};

startsWith.autoRemove = (val: any) => !val;

export const filterFns = {
  fuzzy,
  contains,
  startsWith,
};
