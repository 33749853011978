import React from "react";

type Props = {
  frequency: string; // Frequência em MHz como string
};

const FrequencyFormatter = ({ frequency }: Props) => {
  if (frequency === "-") {
    return <>-</>;
  }

  // Converte a string para um número
  const frequencyNum = parseFloat(frequency);

  // Verifica se a conversão foi bem-sucedida antes de prosseguir
  if (isNaN(frequencyNum)) {
    return <span>Valor inválido</span>;
  }

  // Converte a frequência para GHz se for maior ou igual a 1000, caso contrário, mantém em MHz
  const frequencyInGHz = frequencyNum / 1000;
  const formattedFrequency =
    frequencyNum >= 1000
      ? frequencyInGHz.toLocaleString("pt-BR", { minimumFractionDigits: 4, maximumFractionDigits: 4 }) + " GHz"
      : frequencyNum.toLocaleString("pt-BR", { minimumFractionDigits: 4, maximumFractionDigits: 4 }) + " MHz";

  return <span>{formattedFrequency}</span>;
};

export default FrequencyFormatter;
